import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import React from 'react';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const { t } = useTranslation();

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}reset-password`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: e.target.email.value
        })
      }
    );
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col xl={6}>
            <Flex
              justifyContent="center"
              direction="column"
              className="min-vh-100"
            >
              <Logo at={'login-page'} />
              <Form onSubmit={handleSubmit}>
                <Form.Label htmlFor="email">{t('Email')}</Form.Label>
                <Form.Control id="email" name="email" required={true} />

                <Form.Group>
                  <Button type="submit" color="primary" className="mt-3 w-100">
                    {t('Submit')}
                  </Button>
                </Form.Group>
              </Form>
            </Flex>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
