import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import { PropTypes } from 'prop-types';
import React from 'react';

const BacklinkDetailsAnchorText = ({ data }) => {
  const anchortextColumns = [
    {
      accessor: 'anchor',
      Header: 'Ancre'
    },
    {
      accessor: 'linkQuantity',
      Header: 'Nombre de Liens'
    }
  ];
  const anchortextData = [];
  data?.anchors?.map(anchorText =>
    anchortextData.push({
      anchor: anchorText?.content,
      linkQuantity: anchorText?.linksCount
    })
  );

  return (
    <>
      <div className="mt-3">
        <CustomAdvanceTable columns={anchortextColumns} data={anchortextData} />
      </div>
    </>
  );
};

BacklinkDetailsAnchorText.propTypes = {
  data: PropTypes.object
};

export default BacklinkDetailsAnchorText;
