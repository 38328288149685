import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import { PropTypes } from 'prop-types';
import React from 'react';

const BacklinkDetailsBacklink = ({ data }) => {
  const backlinkColumns = [
    {
      accessor: 'backlink',
      Header: 'Backlink'
    },
    {
      accessor: 'spam',
      Header: 'Évaluation du Spam'
    },
    {
      accessor: 'pi',
      Header: 'PI'
    },
    {
      accessor: 'di',
      Header: 'DI'
    },
    {
      accessor: 'target',
      Header: 'Cible'
    }
  ];
  const backlinkData = [];
  data?.details?.map(backlink =>
    backlinkData.push({
      backlink: backlink?.urlFrom,
      spam: backlink?.spamScore,
      pi: backlink?.pageInfluence,
      di: backlink?.domainInfluence,
      target: backlink?.urlTo
    })
  );

  return (
    <>
      <div className="mt-3">
        <CustomAdvanceTable columns={backlinkColumns} data={backlinkData} />
      </div>
    </>
  );
};

BacklinkDetailsBacklink.propTypes = {
  data: PropTypes.object
};

export default BacklinkDetailsBacklink;
