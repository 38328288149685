import { Card, Tab, Tabs } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import PageAnalysisDetailsAccessibility from './details/PageAnalysisDetailsAccessibility';
import PageAnalysisDetailsContent from './details/PageAnalysisDetailsContent';
import PageAnalysisDetailsOverview from './details/PageAnalysisDetailsOverview';
import PageAnalysisDetailsPerformance from './details/PageAnalysisDetailsPerformance';
import PageAnalysisDetailsSeo from './details/PageAnalysisDetailsSeo';
import PageAnalysisDetailsTechnique from './details/PageAnalysisDetailsTechnique';
import { pageAnalysisData } from 'data/anopseo/page-analysis';
import { useParams } from 'react-router-dom';

const PageAnalysisDetails = () => {
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    const pagesResponse = await fetch(
      `${process.env.REACT_APP_API_URL}analyzes/pages/${params.id}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await pagesResponse.json();
    setData(json);
  }, []);

  const overviewData = pageAnalysisData.overview;
  const seoData = pageAnalysisData.seo;
  const contentData = pageAnalysisData.content;
  const technicData = pageAnalysisData.technic;
  const performanceData = pageAnalysisData.performance;
  const accessibilityData = pageAnalysisData.accessibility;

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Card className="p-3">
        <Tabs variant="pills" defaultActiveKey="overview">
          <Tab eventKey="overview" title="VUE D'ENSEMBLE">
            <PageAnalysisDetailsOverview
              title={overviewData.title}
              description={overviewData.description}
              data={data?.analysisData?.data}
              technologies={data?.technologies}
            />
          </Tab>
          <Tab eventKey="seo" title="SEO">
            <PageAnalysisDetailsSeo
              title={seoData.title}
              description={seoData.description}
              data={data?.analysisData?.data}
            />
          </Tab>
          <Tab eventKey="content" title="CONTENU">
            <PageAnalysisDetailsContent
              title={contentData.title}
              description={contentData.description}
              data={data?.analysisData?.data}
            />
          </Tab>
          <Tab eventKey="technic" title="TECHNIQUE">
            <PageAnalysisDetailsTechnique
              title={technicData.title}
              description={technicData.description}
              data={data?.analysisData?.data}
            />
          </Tab>
          <Tab eventKey="performance" title="PERFORMANCE">
            <PageAnalysisDetailsPerformance
              title={performanceData.title}
              description={performanceData.description}
              data={data?.analysisData?.data}
            />
          </Tab>
          <Tab eventKey="accessibility" title="ACCESSIBILITÉ">
            <PageAnalysisDetailsAccessibility
              title={accessibilityData.title}
              description={accessibilityData.description}
              data={data?.analysisData?.data}
            />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};

export default PageAnalysisDetails;
