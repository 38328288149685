import * as echarts from 'echarts';

import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import { Card } from 'react-bootstrap';
import CustomAdvanceTable from '../common/CustomAdvanceTable';
import EChartsReactCore from 'echarts-for-react/lib/core';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import { positionTrackingData } from 'data/anopseo/tracking';
import { useParams } from 'react-router-dom';

const TrackingDetails = () => {
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    const backlinkResponse = await fetch(
      `${process.env.REACT_APP_API_URL}trackings/${params.id}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await backlinkResponse.json();
    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const positionCompetitorProgressOptionDates = [];
  data?.competitorProgresses?.slice(-30)?.map(element => {
    positionCompetitorProgressOptionDates.push(element.date);
  });
  const positionCompetitorProgressOptionCompetitor = [];
  data?.competitorProgresses?.slice(-30)?.map(element => {
    positionCompetitorProgressOptionCompetitor.push(element.competitor);
  });
  const positionCompetitorProgressOptionPosition = [];
  data?.competitorProgresses?.slice(-30)?.map(element => {
    positionCompetitorProgressOptionPosition.push(element.position);
  });
  const positionCompetitorProgressOption = {
    series: [
      {
        name: 'Concurrent',
        type: 'line',
        data: positionCompetitorProgressOptionCompetitor,
        smooth: true,
        symbolSize: 10,
        color: getColor('warning')
      },
      {
        name: 'Position',
        type: 'line',
        data: positionCompetitorProgressOptionPosition,
        smooth: true,
        symbolSize: 10,
        color: getColor('success')
      }
    ],
    xAxis: {
      type: 'category',
      data: positionCompetitorProgressOptionDates
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {}
  };
  const keywordsTableColumns = [
    {
      accessor: 'keywords',
      Header: 'Mots-Clés'
    },
    {
      accessor: 'competitor',
      Header: 'Concurrent'
    },
    {
      accessor: 'position',
      Header: 'Position'
    },
    {
      accessor: 'url',
      Header: 'URL'
    }
  ];
  const keywordsTableData = [];
  data?.competitorKeywords?.map(item => {
    keywordsTableData.push({
      keywords: item.keywords,
      competitor: item.competitor,
      position: item.position,
      url: item.url
    });
  });

  return (
    <>
      <Card className="my-3 p-3">
        <Flex className="my-3 p-3 gap-2" direction={'column'}>
          <h2>{positionTrackingData.overview.title}</h2>
          <p>{positionTrackingData.overview.description}</p>
        </Flex>
        <div className="my-3 p-3">
          <h3 className="fs-1 text-center">Progression du concurrent</h3>
          <EChartsReactCore
            echarts={echarts}
            option={positionCompetitorProgressOption}
          />
        </div>
        <div className="my-3 p-3">
          <CustomAdvanceTable
            columns={keywordsTableColumns}
            data={keywordsTableData}
          />
        </div>
      </Card>
    </>
  );
};

export default TrackingDetails;
