export const crawlWebsiteData = {
  title: 'Analyse Crawl',
  description:
    "Le crawl vous permet de faire une analyse de votre site web depuis une URL. Le Crawl va alors analyser un groupe de pages, situer les problèmes pertinents afin de vous faciliter la tâche de les regrouper et donc de les corriger plus rapidement. A vous de simplement préciser le nombre de pages à « crawler », et l'analyse vous permettra d'examiner l'architecture de votre site de plus près, afin de situer les failles éventuellement liées à vos URLs.",
  overview: {
    title: "Vue d'ensemble",
    description:
      "Le Crawl parcours un groupe de pages et analyse situer les problèmes les plus pertinents afin de vous faciliter la tâche et donc de les corriger plus rapidement. Ci-dessous vous pouvez visualiser les erreurs prioritaires à traiter ainsi qu'un score définissant la qualité de votre site web."
  },
  exploration: {
    title: 'Exploration',
    description:
      "Ci-dessous vous pouvez visualiser l'architecture de votre site web. Vous trouverez aussi l'ensemble des URLs explorables ainsi que les pages inexplorables à corriger."
  },
  indexation: {
    title: 'Indexation',
    description:
      "Le rapport d'indexation vous permet de voir les plages de type HTML indexables et non-indexables ainsi que les fichiers non-HTML de type (PDF, image, etc...)"
  },
  duplicatedUrl: {
    title: 'URLs Dupliquées',
    description:
      "Le contenu de votre site web doit être unique pour chacune des pages. Si certaines pages ne peuvent pas être unique car le contenu sera fortement similaire à d'autres pages, alors vous devez utiliser les URLs canonique. Ci-dessous vous trouverez l'ensemble des pages dupliquées et non-dupliquées."
  },
  content: {
    title: 'Contenu',
    description:
      'Le contenu est essentiel pour le référencement de votre site internet. Un bon contenu SEO rendra votre site web visible dans les résultats des moteurs de recherche. Ci-dessous vous trouverez toutes les pages dont le contenu est suffisant ou insuffisant.'
  },
  performance: {
    title: 'Performances',
    description:
      'Un site web avec un chargement rapide est apprécié par les moteurs de recherche mais encore plus par les internautes. Ci-dessous vous trouverez toutes les pages conformes ou lentes.'
  },
  brokenUrl: {
    title: 'URLs Brisées',
    description:
      "Une page non accessible depuis votre site web est tout simplement catastrophique, car cela signifie que les internautes auront des liens qui redirigent vers des pages inaccessibles (40X, 50X, …). Ci-dessous vous trouverez l'ensemble des URLs Brisées et l'emplacement où vous devez les supprimer ou les modifier."
  },
  redirect: {
    title: 'Redirections',
    description:
      "Certaines pages subissent des redirections simples ou en boucle. L'objectif de ce rapport est de vous aider à cibler les pages redirigées afin de les corriger et les rendre accessibles sans aucune redirection."
  }
};
