import { Col, Row } from 'react-bootstrap';

import Backlink from './Backlink';
import Benchmark from './Benchmark';
import PageAnalysis from './PageAnalysis';
import React from 'react';
import SiteAnalysis from './SiteAnalysis';
import Social from './Social';
import Tracking from './Tracking';

const Anopseo = () => {
  return (
    <>
      <Row>
        <Col className="p-3" lg={6}>
          <PageAnalysis />
        </Col>
        <Col className="p-3" lg={6}>
          <SiteAnalysis />
        </Col>
      </Row>
      <Row>
        <Col className="p-3" lg={6}>
          <Backlink />
        </Col>
        <Col className="p-3" lg={6}>
          <Benchmark />
        </Col>
      </Row>
      <Row>
        <Col className="p-3" lg={6}>
          <Tracking />
        </Col>
        <Col className="p-3" lg={6}>
          <Social />
        </Col>
      </Row>
    </>
  );
};

export default Anopseo;
