import { Col, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import { PropTypes } from 'prop-types';
import React from 'react';
import SoftBadge from 'components/common/SoftBadge';
import { aiReportData } from 'data/anopseo/report';

const ReportDetailsBenchmark = ({ data }) => {
  const lastCrawl = data?.siteAnalyzes?.slice(-1)[0];

  const issuesColor =
    lastCrawl?.issuesCount < 10
      ? lastCrawl?.issuesCount < 5
        ? 'primary'
        : 'warning'
      : 'danger';

  const benchmarkColumns = [
    {
      accessor: 'date',
      Header: 'Date'
    },
    {
      accessor: 'keywordsResultsCount',
      Header: 'Nombre de résultats'
    },
    {
      accessor: 'numberOfVisits',
      Header: 'Nombre de visites'
    },
    {
      accessor: 'averagePageViews',
      Header: 'Moyenne de pages vues'
    },
    {
      accessor: 'bounceRate',
      Header: 'Taux de rebond'
    },
    {
      accessor: 'timeSpentOnSite',
      Header: 'Temps passé sur le site'
    },
    {
      accessor: 'actions',
      Header: 'Actions'
    }
  ];
  const benchmarkData = [];
  data?.benchmarks?.map(item => {
    benchmarkData.push({
      date: new Date(item.createdAt).toLocaleDateString(),
      keywordsResultsCount: item.keywordsResultsCount,
      numberOfVisits: item.numberOfVisits,
      averagePageViews: item.averagePageViews,
      bounceRate: item.bounceRate,
      timeSpentOnSite: item.timeSpentOnSite + ' secondes',
      actions: (
        <>
          <OverlayTrigger
            placement={'top'}
            overlay={<Tooltip>{"Voir l'analyse benchmark"}</Tooltip>}
          >
            <a href={`/benchmark/${item.id}/`} className="mx-1">
              <EyeLineIcon />
            </a>
          </OverlayTrigger>
        </>
      )
    });
  });

  return (
    <>
      <div className="my-3 p-3">
        <Row>
          <Col xl={3}>
            {data?.isSiteAnalyzesEnabled && (
              <Flex alignItems={'center'} direction={'column'}>
                <Image
                  className="img-fluid"
                  src={
                    lastCrawl?.analysisData[0]?.data?.overview?.screenshotUrl
                  }
                />
                <SoftBadge className="my-2" pill bg={issuesColor}>
                  {lastCrawl?.issuesCount}&nbsp;Problème
                  {lastCrawl?.issuesCount != 1 && 's'}
                </SoftBadge>
              </Flex>
            )}
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{aiReportData.benchmark.title}</h2>
              <p>{aiReportData.benchmark.description}</p>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="my-3 p-3">
        <CustomAdvanceTable columns={benchmarkColumns} data={benchmarkData} />
      </div>
    </>
  );
};

ReportDetailsBenchmark.propTypes = {
  data: PropTypes.object
};

export default ReportDetailsBenchmark;
