import * as echarts from 'echarts';

import { Accordion, Col, Image, Row } from 'react-bootstrap';
import {
  faExclamationCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

import EChartsReactCore from 'echarts-for-react/lib/core';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Technologies from 'components/anopseo/common/Technologies';
import { getColor } from 'helpers/utils';

const PageAnalysisDetailsOverview = ({
  title,
  description,
  data,
  technologies
}) => {
  const scoreColor =
    data?.overview?.score > 30
      ? data?.overview?.score > 70
        ? 'success'
        : 'warning'
      : 'danger';

  const scoreChartOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(scoreColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: data?.overview?.score,
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ],
        detail: {
          formatter: '{value}%'
        }
      }
    ]
  };

  const issuesChartOption = {
    series: [
      {
        name: 'Succès',
        type: 'bar',
        stack: 'total',
        data: [
          data?.overview?.seoSuccessesCount,
          data?.overview?.contentSuccessesCount,
          data?.overview?.techniqueSuccessesCount,
          data?.overview?.performanceSuccessesCount,
          data?.overview?.accessibilitySuccessesCount
        ]
      },
      {
        name: 'Avertissements',
        type: 'bar',
        stack: 'total',
        data: [
          data?.overview?.seoWarningsCount,
          data?.overview?.contentWarningsCount,
          data?.overview?.techniqueWarningsCount,
          data?.overview?.performanceWarningsCount,
          data?.overview?.accessibilityWarningsCount
        ]
      },
      {
        name: 'Erreurs',
        type: 'bar',
        stack: 'total',
        data: [
          data?.overview?.seoIssuesCount,
          data?.overview?.contentIssuesCount,
          data?.overview?.techniqueIssuesCount,
          data?.overview?.performanceIssuesCount,
          data?.overview?.accessibilityIssuesCount
        ]
      }
    ],
    color: [getColor('success'), getColor('warning'), getColor('danger')],
    xAxis: {
      type: 'category',
      data: ['SEO', 'Contenu', 'Technique', 'Performance', 'Accessibilité']
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };

  return (
    <>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <Flex alignItems={'center'} direction={'column'}>
              <Image
                className="img-fluid"
                src={data?.overview?.screenshotUrl}
              />
              <a
                href={data?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-center"
              >
                {data?.url}
              </a>
            </Flex>
            <Technologies technologies={technologies} />
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{title}</h2>
              <p>{description}</p>
              <Accordion flush>
                {data?.overview?.seoWarningMessages?.length > 0 && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>SEO</span>
                        <FontAwesomeIcon
                          color={getColor('warning')}
                          icon={faExclamationCircle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data?.overview?.seoWarningMessages?.map(
                        (message, index) => (
                          <p key={`seoWarningMessage${index}`}>{message}</p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {data?.overview?.seoIssueMessages?.length > 0 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>SEO</span>
                        <FontAwesomeIcon
                          color={getColor('danger')}
                          icon={faExclamationTriangle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data?.overview?.seoIssueMessages?.map(
                        (message, index) => (
                          <p key={`seoIssueMessage${index}`}>{message}</p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {data?.overview?.contentWarningMessages?.length > 0 && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Contenu</span>
                        <FontAwesomeIcon
                          color={getColor('warning')}
                          icon={faExclamationCircle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data?.overview?.contentWarningMessages?.map(
                        (message, index) => (
                          <p key={`contentWarningMessage${index}`}>{message}</p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {data?.overview?.contentIssueMessages?.length > 0 && (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Contenu</span>
                        <FontAwesomeIcon
                          color={getColor('danger')}
                          icon={faExclamationTriangle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data?.overview?.contentIssueMessages?.map(
                        (message, index) => (
                          <p key={`contentIssueMessage${index}`}>{message}</p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {data?.overview?.techniqueWarningMessages?.length > 0 && (
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Technique</span>
                        <FontAwesomeIcon
                          color={getColor('warning')}
                          icon={faExclamationCircle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data?.overview?.techniqueWarningMessages?.map(
                        (message, index) => (
                          <p key={`techniqueWarningMessage${index}`}>
                            {message}
                          </p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {data?.overview?.techniqueIssueMessages?.length > 0 && (
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Technique</span>
                        <FontAwesomeIcon
                          color={getColor('danger')}
                          icon={faExclamationTriangle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data?.overview?.techniqueIssueMessages?.map(
                        (message, index) => (
                          <p key={`techniqueIssueMessage${index}`}>{message}</p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {data?.overview?.performanceWarningMessages?.length > 0 && (
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Performance</span>
                        <FontAwesomeIcon
                          color={getColor('warning')}
                          icon={faExclamationCircle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data?.overview?.performanceWarningMessages?.map(
                        (message, index) => (
                          <p key={`performanceWarningMessage${index}`}>
                            {message}
                          </p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {data?.overview?.performanceIssueMessages?.length > 0 && (
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Performance</span>
                        <FontAwesomeIcon
                          color={getColor('danger')}
                          icon={faExclamationTriangle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data?.overview?.performanceIssueMessages?.map(
                        (message, index) => (
                          <p key={`performanceIssueMessage${index}`}>
                            {message}
                          </p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {data?.overview?.accessibilityWarningMessages?.length > 0 && (
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Accessibilité</span>
                        <FontAwesomeIcon
                          color={getColor('warning')}
                          icon={faExclamationCircle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data?.overview?.accessibilityWarningMessages.map(
                        (message, index) => (
                          <p key={`accessibilityWarningMessage${index}`}>
                            {message}
                          </p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {data?.overview?.accessibilityIssueMessages?.length > 0 && (
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Accessibilité</span>
                        <FontAwesomeIcon
                          color={getColor('danger')}
                          icon={faExclamationTriangle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data?.overview?.accessibilityIssueMessages.map(
                        (message, index) => (
                          <p key={`accessibilityIssueMessage${index}`}>
                            {message}
                          </p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <h3 className="fs-1 text-center">Score total</h3>
            <EChartsReactCore echarts={echarts} option={scoreChartOption} />
          </Col>
          <Col className="border-start" xl={9}>
            <h3 className="fs-1 text-center">Résumé des problèmes</h3>
            <EChartsReactCore echarts={echarts} option={issuesChartOption} />
          </Col>
        </Row>
      </div>
    </>
  );
};

PageAnalysisDetailsOverview.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object,
  technologies: PropTypes.object
};

export default PageAnalysisDetailsOverview;
