import * as echarts from 'echarts';

import { Col, Row } from 'react-bootstrap';
import {
  faCheckCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import EChartsReactCore from 'echarts-for-react/lib/core';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { getColor } from 'helpers/utils';
import { useParams } from 'react-router-dom';

const SiteAnalysisDetailsExploration = ({ title, description, data }) => {
  const params = useParams();

  let searchablesCount = 0;
  data?.analysisData?.map(page => {
    page?.data?.overview?.isSearchable && searchablesCount++;
  });
  const summaryOption = {
    series: [
      {
        type: 'pie',
        radius: ['40%', '80%'],
        label: {
          show: false,
          position: 'center'
        },
        data: [
          {
            value: searchablesCount,
            name: 'URLs explorables',
            itemStyle: {
              color: getColor('success')
            }
          },
          {
            value: data?.analysisData?.length - searchablesCount,
            name: 'URLs inexplorables',
            itemStyle: {
              color: getColor('danger')
            }
          }
        ]
      }
    ],
    legend: {
      bottom: 'bottom'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  let urlsOption = {};
  if (data?.analysisData != null) {
    let urlsData = [];
    let urlsLinks = [];
    data?.analysisData?.map((page, pageIndex) => {
      if (page?.data?.relative_url != null) {
        let isValid = true;
        urlsData.map(urlData => {
          if (urlData.name == page?.data?.relative_url) {
            isValid = false;
          }
        });
        if (isValid) {
          urlsData.push({
            id: page?.data?.relative_url,
            name: page?.data?.relative_url,
            category: page?.data?.relative_url,
            x:
              page?.data?.relative_url == '/'
                ? 0
                : Math.random() * 10 * pageIndex,
            y:
              page?.data?.relative_url == '/'
                ? 0
                : pageIndex % 2 == 0
                ? Math.random() * 10 * -pageIndex
                : Math.random() * 10 * pageIndex,
            value: Math.max(page?.data?.seo?.internal_links?.items?.length, 1),
            symbolSize: Math.max(
              page?.data?.seo?.internal_links?.items?.length,
              1
            ),
            label: {
              show: true
            }
          });
        }
        page?.data?.seo?.internal_links?.items?.map(link => {
          let linkWithoutParams = link.split('#')[0];
          linkWithoutParams = linkWithoutParams.split('?')[0];
          urlsLinks.push({
            source: page?.data?.relative_url,
            target: linkWithoutParams
          });
        });
      }
    });
    urlsOption = {
      tooltip: {},
      animationDuration: 1500,
      animationEasingUpdate: 'quinticInOut',
      series: [
        {
          name: data?.analysisData[0]?.data?.url,
          type: 'graph',
          layout: 'none',
          label: {
            position: 'right',
            formatter: '{b}'
          },
          draggable: true,
          data: urlsData,
          links: urlsLinks,
          lineStyle: {
            curveness: 0.3
          }
        }
      ]
    };
  }
  const urlsColumns = [
    {
      accessor: 'state',
      Header: 'Statut'
    },
    {
      accessor: 'url',
      Header: 'URL'
    },
    {
      accessor: 'detail',
      Header: 'Détail'
    }
  ];
  const urlsData = [];
  data?.analysisData?.map(page => {
    urlsData.push({
      state: page?.data?.overview?.isSearchable
        ? 'URL explorable'
        : 'URL inexplorable',
      url: page?.data?.url,
      detail: (
        <a
          href={`/site-analysis/${params.id}/page/${page?.id}/`}
          title="Voir l'analyse de page"
          className="mx-1"
        >
          <EyeLineIcon />
        </a>
      )
    });
  });

  return (
    <>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <EChartsReactCore
              echarts={echarts}
              option={summaryOption}
              style={{ minHeight: '500px' }}
            />
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{title}</h2>
              <p>{description}</p>
              <h3 className="fs-1 mt-3">Résumé</h3>
              <Flex className="fw-bold pb-2 border-bottom w-100">
                <Flex
                  className="w-100"
                  justifyContent={'between'}
                  alignItems={'center'}
                >
                  <span>Tout :</span>
                  <span>{data?.analysisData?.length}</span>
                </Flex>
              </Flex>
              <Flex className="fw-bold text-success pb-2 border-bottom w-100">
                <Flex
                  className="w-100"
                  justifyContent={'between'}
                  alignItems={'center'}
                >
                  <Flex className="gap-3" alignItems={'center'}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span>URLs explorables :</span>
                  </Flex>
                  <span>{searchablesCount}</span>
                </Flex>
              </Flex>
              <Flex className="fw-bold text-danger w-100">
                <Flex
                  className="w-100"
                  justifyContent={'between'}
                  alignItems={'center'}
                >
                  <Flex className="gap-3" alignItems={'center'}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span>URLs inexplorables :</span>
                  </Flex>
                  <span>{data?.analysisData?.length - searchablesCount}</span>
                </Flex>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="p-3 my-3">
        <EChartsReactCore
          echarts={echarts}
          option={urlsOption}
          style={{ height: '50vh' }}
        />
      </div>
      <div className="p-3 my-3">
        <CustomAdvanceTable columns={urlsColumns} data={urlsData} />
      </div>
    </>
  );
};

SiteAnalysisDetailsExploration.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default SiteAnalysisDetailsExploration;
