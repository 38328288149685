import { Col, Image, Row } from 'react-bootstrap';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import CustomInfoCard from 'components/anopseo/common/CustomInfoCard';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PageAnalysisDetailsPerformance = ({ title, description, data }) => {
  const { t } = useTranslation();

  const unusedJavascriptsColumns = [
    {
      accessor: 'wastedPercent',
      Header: 'Pourcentage perdu'
    },
    {
      accessor: 'wastedBytes',
      Header: 'Octets perdus'
    },
    {
      accessor: 'url',
      Header: 'URL'
    },
    {
      accessor: 'totalBytes',
      Header: 'Octets totaux'
    }
  ];
  const unusedJavascriptsData = [];
  data?.performance?.unused_javascript?.items.map(item =>
    unusedJavascriptsData.push({
      wastedPercent: item?.wastedPercent,
      wastedBytes: item?.wastedBytes,
      url: item?.url,
      totalBytes: item?.totalBytes
    })
  );
  const unminifiedJavascriptsColumns = [
    {
      accessor: 'wastedPercent',
      Header: 'Pourcentage perdu'
    },
    {
      accessor: 'wastedBytes',
      Header: 'Octets perdus'
    },
    {
      accessor: 'url',
      Header: 'URL'
    },
    {
      accessor: 'totalBytes',
      Header: 'Octets totaux'
    }
  ];
  const unminifiedJavascriptsData = [];
  data?.performance?.unminified_javascript?.items.map(item =>
    unminifiedJavascriptsData.push({
      wastedPercent: item?.wastedPercent,
      wastedBytes: item?.wastedBytes,
      url: item?.url,
      totalBytes: item?.totalBytes
    })
  );
  const duplicatedJavascriptsColumns = [
    {
      accessor: 'wastedPercent',
      Header: 'Pourcentage perdu'
    },
    {
      accessor: 'wastedBytes',
      Header: 'Octets perdus'
    },
    {
      accessor: 'url',
      Header: 'URL'
    },
    {
      accessor: 'totalBytes',
      Header: 'Octets totaux'
    }
  ];
  const duplicatedJavascriptsData = [];
  data?.performance?.duplicated_javascript?.items.map(item =>
    duplicatedJavascriptsData.push({
      wastedPercent: item?.wastedPercent,
      wastedBytes: item?.wastedBytes,
      url: item?.url,
      totalBytes: item?.totalBytes
    })
  );
  const legacyJavascriptsColumns = [
    {
      accessor: 'wastedPercent',
      Header: 'Pourcentage perdu'
    },
    {
      accessor: 'wastedBytes',
      Header: 'Octets perdus'
    },
    {
      accessor: 'url',
      Header: 'URL'
    },
    {
      accessor: 'totalBytes',
      Header: 'Octets totaux'
    }
  ];
  const legacyJavascriptsData = [];
  data?.performance?.legacy_javascript?.items.map(item =>
    legacyJavascriptsData.push({
      wastedPercent: item?.wastedPercent,
      wastedBytes: item?.wastedBytes,
      url: item?.url,
      totalBytes: item?.totalBytes
    })
  );
  const scriptInHeadColumns = [
    {
      accessor: 'element',
      Header: 'Élément'
    }
  ];
  const scriptInHeadData = [];
  data?.performance?.script_in_head?.items?.map(item =>
    scriptInHeadData.push({ element: item })
  );

  return (
    <>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <Flex alignItems={'center'} direction={'column'}>
              <Image
                className="img-fluid"
                src={data?.overview?.screenshotUrl}
              />
              <a
                href={data?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-center"
              >
                {data?.url}
              </a>
            </Flex>
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{title}</h2>
              <p>{description}</p>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Optimisation du Téléchargement</h3>
        <CustomInfoCard
          content={data?.performance?.total_byte_weight?.title}
          state={data?.performance?.total_byte_weight?.status}
          description={data?.performance?.total_byte_weight?.message}
        >
          <div className="mt-3">
            <p className="mb-0">
              <strong>Valeur&nbsp;: </strong>
              {data?.performance?.total_byte_weight?.total_byte_weight != null
                ? parseInt(
                    data?.performance?.total_byte_weight?.total_byte_weight /
                      10000
                  ) /
                    100 +
                  ' Mo'
                : t('No result found')}
            </p>
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.performance?.execution_time?.title}
          state={data?.performance?.execution_time?.status}
          description={data?.performance?.execution_time?.message}
        >
          <div className="mt-3">
            <p className="mb-0">
              <strong>Valeur&nbsp;: </strong>
              {data?.performance?.execution_time?.execution_time_in_ms != null
                ? data?.performance?.execution_time?.execution_time_in_ms +
                  ' ms'
                : t('No result found')}
            </p>
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.performance?.compression?.title}
          state={data?.performance?.compression?.status}
          description={data?.performance?.compression?.message}
        >
          <div className="mt-3">
            <p>
              <strong>Activée&nbsp;: </strong>
              {data?.performance?.compression?.items?.activated ? 'Oui' : 'Non'}
            </p>
            <p>
              <strong>Type de compression&nbsp;: </strong>
              {data?.performance?.compression?.items?.compress_type ??
                t('No result found')}
            </p>
            <p className="mb-0">
              <strong>Taille en octets&nbsp;: </strong>
              {data?.performance?.compression?.items?.size_in_bytes != null
                ? data?.performance?.compression?.items?.size_in_bytes +
                  ' octets'
                : t('No result found')}
            </p>
          </div>
        </CustomInfoCard>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Optimisation du Chargement</h3>
        <CustomInfoCard
          content={
            data?.performance?.unused_javascript?.title ||
            'Javascript non utilisé'
          }
          state={data?.performance?.unused_javascript?.status}
          description={data?.performance?.unused_javascript?.message}
        >
          <div className="mt-3">
            {unusedJavascriptsData.length > 0 ? (
              <CustomAdvanceTable
                columns={unusedJavascriptsColumns}
                data={unusedJavascriptsData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={
            data?.performance?.unminified_javascript?.title ||
            'Javascript non minifié'
          }
          state={data?.performance?.unminified_javascript?.status}
          description={data?.performance?.unminified_javascript?.message}
        >
          <div className="mt-3">
            {unminifiedJavascriptsData.length > 0 ? (
              <CustomAdvanceTable
                columns={unminifiedJavascriptsColumns}
                data={unminifiedJavascriptsData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={
            data?.performance?.duplicated_javascript?.title ||
            'Javascript dupliqué'
          }
          state={data?.performance?.duplicated_javascript?.status}
          description={data?.performance?.duplicated_javascript?.message}
        >
          <div className="mt-3">
            {duplicatedJavascriptsData.length > 0 ? (
              <CustomAdvanceTable
                columns={duplicatedJavascriptsColumns}
                data={duplicatedJavascriptsData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={
            data?.performance?.legacy_javascript?.title || 'Javascript hérité'
          }
          state={data?.performance?.legacy_javascript?.status}
          description={data?.performance?.legacy_javascript?.message}
        >
          <div className="mt-3">
            {legacyJavascriptsData.length > 0 ? (
              <CustomAdvanceTable
                columns={legacyJavascriptsColumns}
                data={legacyJavascriptsData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.performance?.script_in_head?.title}
          state={data?.performance?.script_in_head?.status}
          description={data?.performance?.script_in_head?.message}
        >
          <div className="mt-3">
            {scriptInHeadData?.length > 0 ? (
              <CustomAdvanceTable
                columns={scriptInHeadColumns}
                data={scriptInHeadData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.performance?.inline_javascript?.title}
          state={data?.performance?.inline_javascript?.status}
          description={data?.performance?.inline_javascript?.message}
        >
          <div className="mt-3">
            <p className="mb-0">
              <strong>Valeur&nbsp;: </strong>
              {data?.performance?.inline_javascript?.items?.size_in_bytes ??
                0}{' '}
              octets
            </p>
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.performance?.inline_styles?.title}
          state={data?.performance?.inline_styles?.status}
          description={data?.performance?.inline_styles?.message}
        >
          <div className="mt-3">
            <p className="mb-0">
              <strong>Valeur&nbsp;: </strong>
              {data?.performance?.inline_styles?.items?.count ?? 0} balises
            </p>
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.performance?.style_tags?.title}
          state={data?.performance?.style_tags?.status}
          description={data?.performance?.style_tags?.message}
        >
          <div className="mt-3">
            <p className="mb-0">
              <strong>Valeur&nbsp;: </strong>
              {data?.performance?.style_tags?.items?.count ?? 0} balises
            </p>
          </div>
        </CustomInfoCard>
      </div>
    </>
  );
};

PageAnalysisDetailsPerformance.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default PageAnalysisDetailsPerformance;
