import { Col, Image, Row } from 'react-bootstrap';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import CustomInfoCard from 'components/anopseo/common/CustomInfoCard';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PageAnalysisDetailsAccessibility = ({ title, description, data }) => {
  const { t } = useTranslation();

  const bgSoundTagColumns = [
    {
      accessor: 'element',
      Header: 'Élément'
    }
  ];
  const bgSoundTagData = [];
  data?.accessibility?.bg_sound_tag?.items?.map(item =>
    bgSoundTagData.push({ element: item })
  );
  const inputNoLabelColumns = [
    {
      accessor: 'element',
      Header: 'Élément'
    }
  ];
  const inputNoLabelData = [];
  data?.accessibility?.input_no_label?.items?.map(item =>
    inputNoLabelData.push({ element: item })
  );

  return (
    <>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <Flex alignItems={'center'} direction={'column'}>
              <Image
                className="img-fluid"
                src={data?.overview?.screenshotUrl}
              />
              <a
                href={data?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-center"
              >
                {data?.url}
              </a>
            </Flex>
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{title}</h2>
              <p>{description}</p>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Accessibilité</h3>
        {/* TODO : Avoir une réponse plus précise */}
        {/* <CustomInfoCard
          content={data?.accessibility?.url_rewrite?.title}
          state={data?.accessibility?.url_rewrite?.status}
          description={data?.accessibility?.url_rewrite?.message}
        /> */}
        <CustomInfoCard
          content={data?.accessibility?.domain_length?.title}
          state={data?.accessibility?.domain_length?.status}
          description={data?.accessibility?.domain_length?.message}
        />
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Visibilité</h3>
        <CustomInfoCard
          content={data?.accessibility?.bg_sound_tag?.title}
          state={data?.accessibility?.bg_sound_tag?.status}
          description={data?.accessibility?.bg_sound_tag?.message}
        >
          <div className="mt-3">
            {bgSoundTagData.length > 0 ? (
              <CustomAdvanceTable
                columns={bgSoundTagColumns}
                data={bgSoundTagData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.accessibility?.input_no_label?.title}
          state={data?.accessibility?.input_no_label?.status}
          description={data?.accessibility?.input_no_label?.message}
        >
          <div className="mt-3">
            {inputNoLabelData.length > 0 ? (
              <CustomAdvanceTable
                columns={inputNoLabelColumns}
                data={inputNoLabelData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.accessibility?.favicon?.title}
          state={data?.accessibility?.favicon?.status}
          description={data?.accessibility?.favicon?.message}
        />
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Internationalisation</h3>
        <CustomInfoCard
          content={data?.accessibility?.lang?.title}
          state={data?.accessibility?.lang?.status}
          description={data?.accessibility?.lang?.message}
        >
          <div className="mt-3">
            <p className="mb-0">
              <strong>
                {data?.accessibility?.lang?.items != null
                  ? data?.accessibility?.lang?.items[0].lang
                  : t('No result found')}
              </strong>
            </p>
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.accessibility?.opengraphs?.title}
          state={data?.accessibility?.opengraphs?.status}
          description={data?.accessibility?.opengraphs?.message}
        />
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Compatibilité Mobile</h3>
        <CustomInfoCard
          content={data?.accessibility?.meta_viewport?.title}
          state={data?.accessibility?.meta_viewport?.status}
          description={data?.accessibility?.meta_viewport?.message}
        />
      </div>
    </>
  );
};

PageAnalysisDetailsAccessibility.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default PageAnalysisDetailsAccessibility;
