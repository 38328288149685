import * as echarts from 'echarts';

import { Card, Col, Image, Row } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import CustomAdvanceTable from '../common/CustomAdvanceTable';
import EChartsReactCore from 'echarts-for-react/lib/core';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { getColor } from 'helpers/utils';
import { monitorListingData } from 'data/anopseo/monitor';
import { useParams } from 'react-router-dom';

const MonitorDetails = () => {
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    const monitorResponse = await fetch(
      `${process.env.REACT_APP_API_URL}monitors/${params.id}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await monitorResponse.json();
    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const monitorOptionDates = [];
  data?.durations?.slice(-30)?.map(element => {
    monitorOptionDates.push(element.date);
  });
  const monitorOptionDurations = [];
  data?.durations?.slice(-30)?.map(element => {
    monitorOptionDurations.push(element.duration);
  });
  const monitorOption = {
    series: [
      {
        type: 'line',
        data: monitorOptionDurations,
        symbolSize: 10
      }
    ],
    color: [getColor('primary')],
    xAxis: {
      type: 'category',
      data: monitorOptionDates
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const callsTableColumns = [
    {
      accessor: 'date',
      Header: 'Date'
    },
    {
      accessor: 'status',
      Header: 'État'
    }
  ];
  const callsTableData = [];
  data?.pings?.map(item => {
    callsTableData.push({
      date: item.date,
      status: (
        <>
          <SoftBadge
            pill
            bg={
              item.status.slice(-4, -1).startsWith('2') ? 'success' : 'danger'
            }
          >
            {item.status}
          </SoftBadge>
        </>
      )
    });
  });

  return (
    <>
      <Card className="my-3 p-3">
        <Row>
          <Col xl={3}>
            <Flex alignItems={'center'} direction={'column'}>
              <Image className="img-fluid" src={data?.screenshotUrl} />
              <a
                href={data?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-center"
              >
                {data?.url}
              </a>
            </Flex>
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{monitorListingData.overview.title}</h2>
              <p>{monitorListingData.overview.description}</p>
            </Flex>
          </Col>
        </Row>
        <div className="my-3 p-3">
          <h3 className="fs-1">Temps de réponse en millisecondes</h3>
          <EChartsReactCore echarts={echarts} option={monitorOption} />
        </div>
        <div className="my-3 p-3">
          <h3 className="fs-1">État de la page</h3>
          <CustomAdvanceTable
            columns={callsTableColumns}
            data={callsTableData}
          />
        </div>
      </Card>
    </>
  );
};

export default MonitorDetails;
