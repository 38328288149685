import { Button, Form, ProgressBar, Spinner, Table } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddLineIcon from 'remixicon-react/AddLineIcon';
import AppContext from 'context/Context';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import CustomModal from '../common/CustomModal';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

const Crawl = ({ crawl, isSummary = false }) => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [restartModalShow, setRestartModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const {
    id,
    createdAt,
    isOnError,
    errorMessages,
    isFinished,
    url,
    pagesCount,
    score,
    issuesCount
  } = crawl;

  const createdAtDate = new Date(createdAt).toLocaleString();
  const createdAtSummaryDate = new Date(createdAt).toLocaleDateString();

  const scoreColor =
    score > 30 ? (score > 70 ? 'success' : 'warning') : 'danger';
  const issuesColor =
    issuesCount < 10 ? (issuesCount < 1 ? 'success' : 'warning') : 'danger';

  const restartAnalysis = async () => {
    setRestartModalShow(false);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}analyzes/site-analyzer`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ url: url })
      }
    );
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  const deleteAnalysis = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}analyzes/sites/${id}`,
      {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
    setDeleteModalShow(false);
  };

  return (
    <>
      <tr
        className={
          isOnError
            ? 'app-in-error-analysis'
            : !isFinished
            ? 'app-not-finished-analysis'
            : ''
        }
      >
        <td className="align-middle">
          <Flex
            style={{ whiteSpace: 'nowrap' }}
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {isSummary ? createdAtSummaryDate : createdAtDate}
          </Flex>
        </td>
        <td className="align-middle">
          <Flex
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {url}
          </Flex>
        </td>
        {isOnError ? (
          <td className="align-middle" colSpan="4">
            <Flex
              className="text-center h-100"
              direction={'column'}
              justifyContent={'start'}
              alignItems={'center'}
            >
              {errorMessages?.map((errorMessage, index) => (
                <p key={`analysis${id}errorMessage${index}`} className="mb-0">
                  {t(errorMessage)}
                </p>
              ))}
            </Flex>
          </td>
        ) : !isFinished ? (
          <>
            <td></td>
            <td className="align-middle">
              <Flex
                className="h-100"
                justifyContent={'center'}
                alignItems={'start'}
                direction={'column'}
              >
                <SoftBadge pill bg="info">
                  {t('Ongoing')}
                </SoftBadge>
              </Flex>
            </td>
            <td></td>
            <td></td>
          </>
        ) : (
          <>
            <td className="align-middle">
              <Flex
                className="h-100"
                justifyContent={'center'}
                alignItems={'start'}
                direction={'column'}
              >
                {pagesCount}
              </Flex>
            </td>
            <td className="align-middle">
              <Flex
                className="h-100"
                justifyContent={'center'}
                alignItems={'start'}
                direction={'column'}
              >
                <SoftBadge pill bg="success">
                  {t('Finished')}
                </SoftBadge>
              </Flex>
            </td>
            <td className="align-middle">
              <Flex
                className="h-100 gap-2"
                justifyContent={'start'}
                alignItems={'center'}
              >
                <ProgressBar
                  now={score}
                  style={{ width: '80px', height: '8px' }}
                  variant={scoreColor}
                />
                <p className="mb-0">{score}&nbsp;%</p>
              </Flex>
            </td>
            <td className="align-middle">
              <Flex
                className="h-100"
                justifyContent={'center'}
                alignItems={'start'}
                direction={'column'}
              >
                <SoftBadge pill bg={issuesColor}>
                  {issuesCount}&nbsp;{t(`Issue${issuesCount != 1 && 's'}`)}
                </SoftBadge>
              </Flex>
            </td>
          </>
        )}
        {!isSummary && (
          <td className="align-middle">
            <Flex
              className="h-100"
              justifyContent={'end'}
              alignItems={'center'}
              direction={'row'}
            >
              {isFinished && (
                <>
                  {!isOnError ? (
                    <a
                      href={`/site-analysis/${id}/`}
                      title="Voir l'analyse crawl"
                      className="mx-1"
                    >
                      <EyeLineIcon />
                    </a>
                  ) : null}
                  <a
                    onClick={() => setRestartModalShow(true)}
                    href={'#'}
                    title="Recommencer l'analyse crawl"
                    className="mx-1"
                  >
                    <RefreshLineIcon />
                  </a>
                </>
              )}
              <a
                onClick={() => setDeleteModalShow(true)}
                href={'#'}
                title="Supprimer l'analyse crawl"
                className="mx-1"
              >
                <CloseCircleLineIcon />
              </a>
            </Flex>
          </td>
        )}
      </tr>
      {!isSummary && (
        <>
          <CustomModal
            isShown={restartModalShow}
            setIsShown={setRestartModalShow}
            title="Recommencer l'analyse crawl"
            description={
              <p>
                Êtes-vous sûr de vouloir recommencer l'analyse crawl
                séléctionnée&nbsp;?
                <br />
                {t('Date')}&nbsp;: <strong>{createdAtDate}</strong>
                <br />
                {t('URL')}&nbsp;: <strong>{url}</strong>
              </p>
            }
            content={
              <Button
                className="app-modal-submit-button"
                onClick={() => restartAnalysis()}
              >
                {t('Restart')}
              </Button>
            }
          />
          <CustomModal
            isShown={deleteModalShow}
            setIsShown={setDeleteModalShow}
            title="Supprimer l'analyse crawl"
            description={
              <p>
                Êtes-vous sûr de vouloir supprimer l'analyse crawl
                séléctionnée&nbsp;?
                <br />
                {t('Date')}&nbsp;: <strong>{createdAtDate}</strong>
                <br />
                {t('URL')}&nbsp;: <strong>{url}</strong>
              </p>
            }
            content={
              <Button
                className="app-modal-submit-button"
                variant="danger"
                onClick={() => deleteAnalysis()}
              >
                {t('Delete')}
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

Crawl.propTypes = {
  crawl: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    isOnError: PropTypes.bool,
    errorMessages: PropTypes.arrayOf(PropTypes.string),
    isFinished: PropTypes.bool,
    url: PropTypes.string,
    pagesCount: PropTypes.number,
    score: PropTypes.number,
    issuesCount: PropTypes.number
  }),
  isSummary: PropTypes.bool
};

const Header = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);

  const handleFormSubmit = async e => {
    e.preventDefault();
    setModalShow(false);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}analyzes/site-analyzer`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: e.target.url.value
        })
      }
    );
    const json = await response.json();
    e.target.reset();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  return (
    <>
      <div className="app-list-header">
        <h2 className="app-list-title">Analyse Crawl</h2>
        <Button className="app-list-button" onClick={() => setModalShow(true)}>
          <Flex className="gap-1" alignItems={'center'}>
            <AddLineIcon className="app-list-button-icon" />
            <span>Ajouter un nouveau crawl</span>
          </Flex>
        </Button>
        <div className="app-list-description">
          <p>
            Le crawl vous permet de faire une analyse de votre site web depuis
            une URL. Le Crawl va alors analyser un groupe de pages, situer les
            problèmes pertinents afin de vous faciliter la tâche de les
            regrouper et donc de les corriger plus rapidement. A vous de
            simplement préciser le nombre de pages à « crawler », et l'analyse
            vous permettra d'examiner l'architecture de votre site de plus près,
            afin de situer les failles éventuellement liées à vos URLs.
          </p>
        </div>
      </div>
      <CustomModal
        isShown={modalShow}
        setIsShown={setModalShow}
        title="Analyse Crawl"
        description="Ci-dessous vous pouvez entrer l'URL de votre page à analyser. Vérifier que votre page est accessible avant de lancer l'analyse de votre page. L'URL est de type&nbsp;: http(s)://www.domaine.extension ou http(s)://domaine.extension"
        content={
          <Form className="app-modal-form" onSubmit={handleFormSubmit}>
            <Form.Label htmlFor="modalUrl">{t('URL')}</Form.Label>
            <Form.Control
              id="modalUrl"
              name="url"
              type="text"
              required={true}
            />
            <Button className="app-modal-submit-button" type="submit">
              {t('Submit')}
            </Button>
          </Form>
        }
      />
    </>
  );
};

export const List = ({ maxResults = null, isSummary = false }) => {
  const { t } = useTranslation();
  const { lastMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    shouldReconnect: () => true
  });
  const {
    config: { userId, token }
  } = useContext(AppContext);
  const [crawls, setCrawls] = useState([]);
  const [isFetchFinished, setIsFetchFinished] = useState(false);

  const fetchCrawls = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}analyzes/sites`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await response.json();
    if (maxResults != null) {
      setCrawls(json.sites.slice(0, maxResults));
    } else {
      setCrawls(json.sites);
    }
    setIsFetchFinished(true);
  }, []);

  useEffect(() => {
    fetchCrawls();
  }, [fetchCrawls]);

  useEffect(() => {
    if (lastMessage == null) {
      return;
    }
    const data = JSON.parse(lastMessage.data);
    if (data != null) {
      if (data.userId == userId) {
        if (data.from == 'sitesAnalyzes') {
          fetchCrawls();
        }
      }
    }
  }, [fetchCrawls, lastMessage]);

  return (
    <>
      {isFetchFinished ? (
        crawls?.length == 0 ? (
          <Flex justifyContent={'center'}>
            <p className="text-center fw-bold my-3">
              Aucune analyse de site trouvée
            </p>
          </Flex>
        ) : (
          <Table className="app-list-table" responsive>
            <thead>
              <tr>
                <th scope="col">{t('Date')}</th>
                <th scope="col">{t('URL')}</th>
                <th scope="col">{t('Pages')}</th>
                <th scope="col">{t('Progress')}</th>
                <th scope="col">{t('Score')}</th>
                <th scope="col">{t('Issues')}</th>
                {!isSummary && (
                  <th className="text-end" scope="col">
                    {t('Actions')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {crawls?.map(crawl => (
                <Crawl crawl={crawl} key={crawl.id} isSummary={isSummary} />
              ))}
            </tbody>
          </Table>
        )
      ) : (
        <Flex justifyContent={'center'}>
          <Spinner className="my-3" animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Flex>
      )}
    </>
  );
};

List.propTypes = {
  maxResults: PropTypes.number,
  isSummary: PropTypes.bool
};

const SiteAnalysisList = () => {
  return (
    <>
      <Header />
      <List />
    </>
  );
};

export default SiteAnalysisList;
