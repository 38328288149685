import { Dropdown, Form } from 'react-bootstrap';
import React, { useContext, useState } from 'react';

import AppContext from 'context/Context';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import { isIterableArray } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

const SearchBox = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [resultItem, setResultItem] = useState([]);

  const handleChange = async target => {
    setSearchInputValue(target.value);
    await fetchSearchResults(target.value);
  };

  const fetchSearchResults = async query => {
    if (query.length < 3) {
      setResultItem([]);
      return;
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}search`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: query
      })
    });
    const json = await response.json();
    setResultItem(json.searchResults ?? []);
  };

  const pagesAnalyzes = resultItem.filter(
    item => item.category === 'pageAnalysis'
  );
  const sitesAnalyzes = resultItem.filter(
    item => item.category === 'siteAnalysis'
  );
  const backlinks = resultItem.filter(item => item.category === 'backlink');
  const benchmarks = resultItem.filter(item => item.category === 'benchmark');
  const trackings = resultItem.filter(item => item.category === 'tracking');
  const socials = resultItem.filter(item => item.category === 'social');
  const monitors = resultItem.filter(item => item.category === 'monitor');
  const writers = resultItem.filter(item => item.category === 'writer');
  const reports = resultItem.filter(item => item.category === 'report');

  return (
    <Dropdown className="search-box">
      <Dropdown.Toggle as="div" data-toggle="dropdown" bsPrefix="toggle">
        <Form className="position-relative">
          <Form.Control
            type="search"
            placeholder={`${t('Search')} (${t('At least 3 characters')})`}
            aria-label={t('Search')}
            className="search-input ps-5"
            value={searchInputValue}
            onChange={({ target }) => handleChange(target)}
          />
          <SearchLineIcon
            icon="search"
            className="position-absolute text-400 search-box-icon"
          />
          {searchInputValue && (
            <div className="search-box-close-btn-container">
              <FalconCloseButton
                size="sm"
                noOutline
                onClick={() => {
                  setSearchInputValue('');
                  fetchSearchResults('');
                }}
              />
            </div>
          )}
        </Form>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
          {isIterableArray(pagesAnalyzes) && (
            <>
              <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                Analyse de Page
              </Dropdown.Header>
              {pagesAnalyzes.map(item => (
                <Dropdown.Item
                  className="p-0"
                  as={'div'}
                  key={item.id}
                  style={
                    item.isOnError || !item.isFinished
                      ? {
                          pointerEvents: 'none',
                          background: 'var(--falcon-danger)'
                        }
                      : {}
                  }
                >
                  <a
                    href={
                      item.isOnError || !item.isFinished
                        ? ''
                        : `/page-analysis/${item.id}`
                    }
                    className="fs--1 px-x1 py-2 hover-primary d-block"
                    style={
                      item.isOnError || !item.isFinished
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    <Flex className="gap-3" alignItems={'center'}>
                      <div className="app-font-regular">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                      <Flex className="app-font-medium" direction={'column'}>
                        {item.content.map((content, index) => (
                          <span key={index}>
                            {content.label}&nbsp;: {content.value}
                          </span>
                        ))}
                      </Flex>
                    </Flex>
                  </a>
                </Dropdown.Item>
              ))}
              {(isIterableArray(sitesAnalyzes) ||
                isIterableArray(backlinks) ||
                isIterableArray(benchmarks) ||
                isIterableArray(trackings) ||
                isIterableArray(socials) ||
                isIterableArray(monitors) ||
                isIterableArray(writers) ||
                isIterableArray(reports)) && (
                <hr className="text-200 dark__text-900" />
              )}
            </>
          )}

          {isIterableArray(sitesAnalyzes) && (
            <>
              <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                Crawl Website
              </Dropdown.Header>
              {sitesAnalyzes.map(item => (
                <Dropdown.Item
                  className="p-0"
                  as={'div'}
                  key={item.id}
                  style={
                    item.isOnError || !item.isFinished
                      ? {
                          pointerEvents: 'none',
                          background: 'var(--falcon-danger)'
                        }
                      : {}
                  }
                >
                  <a
                    href={
                      item.isOnError || !item.isFinished
                        ? ''
                        : `/site-analysis/${item.id}`
                    }
                    className="fs--1 px-x1 py-2 hover-primary d-block"
                    style={
                      item.isOnError || !item.isFinished
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    <Flex className="gap-3" alignItems={'center'}>
                      <div className="app-font-regular">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                      <Flex className="app-font-medium" direction={'column'}>
                        {item.content.map((content, index) => (
                          <span key={index}>
                            {content.label}&nbsp;: {content.value}
                          </span>
                        ))}
                      </Flex>
                    </Flex>
                  </a>
                </Dropdown.Item>
              ))}
              {(isIterableArray(backlinks) ||
                isIterableArray(benchmarks) ||
                isIterableArray(trackings) ||
                isIterableArray(socials) ||
                isIterableArray(monitors) ||
                isIterableArray(writers) ||
                isIterableArray(reports)) && (
                <hr className="text-200 dark__text-900" />
              )}
            </>
          )}

          {isIterableArray(backlinks) && (
            <>
              <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                Check du Backlink
              </Dropdown.Header>
              {backlinks.map(item => (
                <Dropdown.Item
                  className="p-0"
                  as={'div'}
                  key={item.id}
                  style={
                    item.isOnError || !item.isFinished
                      ? {
                          pointerEvents: 'none',
                          background: 'var(--falcon-danger)'
                        }
                      : {}
                  }
                >
                  <a
                    href={
                      item.isOnError || !item.isFinished
                        ? ''
                        : `/backlink/${item.id}`
                    }
                    className="fs--1 px-x1 py-2 hover-primary d-block"
                    style={
                      item.isOnError || !item.isFinished
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    <Flex className="gap-3" alignItems={'center'}>
                      <div className="app-font-regular">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                      <Flex className="app-font-medium" direction={'column'}>
                        {item.content.map((content, index) => (
                          <span key={index}>
                            {content.label}&nbsp;: {content.value}
                          </span>
                        ))}
                      </Flex>
                    </Flex>
                  </a>
                </Dropdown.Item>
              ))}
              {(isIterableArray(benchmarks) ||
                isIterableArray(trackings) ||
                isIterableArray(socials) ||
                isIterableArray(monitors) ||
                isIterableArray(writers) ||
                isIterableArray(reports)) && (
                <hr className="text-200 dark__text-900" />
              )}
            </>
          )}

          {isIterableArray(benchmarks) && (
            <>
              <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                Seo Benchmark
              </Dropdown.Header>
              {benchmarks.map(item => (
                <Dropdown.Item
                  className="p-0"
                  as={'div'}
                  key={item.id}
                  style={
                    item.isOnError || !item.isFinished
                      ? {
                          pointerEvents: 'none',
                          background: 'var(--falcon-danger)'
                        }
                      : {}
                  }
                >
                  <a
                    href={
                      item.isOnError || !item.isFinished
                        ? ''
                        : `/benchmark/${item.id}`
                    }
                    className="fs--1 px-x1 py-2 hover-primary d-block"
                    style={
                      item.isOnError || !item.isFinished
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    <Flex className="gap-3" alignItems={'center'}>
                      <div className="app-font-regular">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                      <Flex className="app-font-medium" direction={'column'}>
                        {item.content.map((content, index) => (
                          <span key={index}>
                            {content.label}&nbsp;: {content.value}
                          </span>
                        ))}
                      </Flex>
                    </Flex>
                  </a>
                </Dropdown.Item>
              ))}
              {(isIterableArray(trackings) ||
                isIterableArray(socials) ||
                isIterableArray(monitors) ||
                isIterableArray(writers) ||
                isIterableArray(reports)) && (
                <hr className="text-200 dark__text-900" />
              )}
            </>
          )}

          {isIterableArray(trackings) && (
            <>
              <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                Suivi de Position
              </Dropdown.Header>
              {trackings.map(item => (
                <Dropdown.Item
                  className="p-0"
                  as={'div'}
                  key={item.id}
                  style={
                    item.isOnError || !item.isFinished
                      ? {
                          pointerEvents: 'none',
                          background: 'var(--falcon-danger)'
                        }
                      : {}
                  }
                >
                  <a
                    href={
                      item.isOnError || !item.isFinished
                        ? ''
                        : `/tracking/${item.id}`
                    }
                    className="fs--1 px-x1 py-2 hover-primary d-block"
                    style={
                      item.isOnError || !item.isFinished
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    <Flex className="gap-3" alignItems={'center'}>
                      <div className="app-font-regular">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                      <Flex className="app-font-medium" direction={'column'}>
                        {item.content.map((content, index) => (
                          <span key={index}>
                            {content.label}&nbsp;: {content.value}
                          </span>
                        ))}
                      </Flex>
                    </Flex>
                  </a>
                </Dropdown.Item>
              ))}
              {(isIterableArray(socials) ||
                isIterableArray(monitors) ||
                isIterableArray(writers) ||
                isIterableArray(reports)) && (
                <hr className="text-200 dark__text-900" />
              )}
            </>
          )}

          {isIterableArray(socials) && (
            <>
              <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                Social Buzz
              </Dropdown.Header>
              {socials.map(item => (
                <Dropdown.Item
                  className="p-0"
                  as={'div'}
                  key={item.id}
                  style={
                    item.isOnError || !item.isFinished
                      ? {
                          pointerEvents: 'none',
                          background: 'var(--falcon-danger)'
                        }
                      : {}
                  }
                >
                  <a
                    href={
                      item.isOnError || !item.isFinished
                        ? ''
                        : `/social/${item.id}`
                    }
                    className="fs--1 px-x1 py-2 hover-primary d-block"
                    style={
                      item.isOnError || !item.isFinished
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    <Flex className="gap-3" alignItems={'center'}>
                      <div className="app-font-regular">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                      <Flex className="app-font-medium" direction={'column'}>
                        {item.content.map((content, index) => (
                          <span key={index}>
                            {content.label}&nbsp;: {content.value}
                          </span>
                        ))}
                      </Flex>
                    </Flex>
                  </a>
                </Dropdown.Item>
              ))}
              {(isIterableArray(monitors) ||
                isIterableArray(writers) ||
                isIterableArray(reports)) && (
                <hr className="text-200 dark__text-900" />
              )}
            </>
          )}

          {isIterableArray(monitors) && (
            <>
              <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                Moniteur
              </Dropdown.Header>
              {monitors.map(item => (
                <Dropdown.Item
                  className="p-0"
                  as={'div'}
                  key={item.id}
                  style={
                    item.isOnError || !item.isFinished
                      ? {
                          pointerEvents: 'none',
                          background: 'var(--falcon-danger)'
                        }
                      : {}
                  }
                >
                  <a
                    href={
                      item.isOnError || !item.isFinished
                        ? ''
                        : `/monitor/${item.id}`
                    }
                    className="fs--1 px-x1 py-2 hover-primary d-block"
                    style={
                      item.isOnError || !item.isFinished
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    <Flex className="gap-3" alignItems={'center'}>
                      <div className="app-font-regular">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                      <Flex className="app-font-medium" direction={'column'}>
                        {item.content.map((content, index) => (
                          <span key={index}>
                            {content.label}&nbsp;: {content.value}
                          </span>
                        ))}
                      </Flex>
                    </Flex>
                  </a>
                </Dropdown.Item>
              ))}
              {(isIterableArray(writers) || isIterableArray(reports)) && (
                <hr className="text-200 dark__text-900" />
              )}
            </>
          )}

          {isIterableArray(writers) && (
            <>
              <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                Writer
              </Dropdown.Header>
              {writers.map(item => (
                <Dropdown.Item
                  className="p-0"
                  as={'div'}
                  key={item.id}
                  style={
                    item.isOnError || !item.isFinished
                      ? {
                          pointerEvents: 'none',
                          background: 'var(--falcon-danger)'
                        }
                      : {}
                  }
                >
                  <a
                    href={
                      item.isOnError || !item.isFinished
                        ? ''
                        : `/ai-writer/${item.id}`
                    }
                    className="fs--1 px-x1 py-2 hover-primary d-block"
                    style={
                      item.isOnError || !item.isFinished
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    <Flex className="gap-3" alignItems={'center'}>
                      <div className="app-font-regular">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                      <Flex className="app-font-medium" direction={'column'}>
                        {item.content.map((content, index) => (
                          <span key={index}>
                            {content.label}&nbsp;: {content.value}
                          </span>
                        ))}
                      </Flex>
                    </Flex>
                  </a>
                </Dropdown.Item>
              ))}
              {isIterableArray(reports) && (
                <hr className="text-200 dark__text-900" />
              )}
            </>
          )}

          {isIterableArray(reports) && (
            <>
              <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                Rapport AI
              </Dropdown.Header>
              {reports.map(item => (
                <Dropdown.Item
                  className="p-0"
                  as={'div'}
                  key={item.id}
                  style={
                    item.isOnError || !item.isFinished
                      ? {
                          pointerEvents: 'none',
                          background: 'var(--falcon-danger)'
                        }
                      : {}
                  }
                >
                  <a
                    href={
                      item.isOnError || !item.isFinished
                        ? ''
                        : `/report/${item.id}`
                    }
                    className="fs--1 px-x1 py-2 hover-primary d-block"
                    style={
                      item.isOnError || !item.isFinished
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    <Flex className="gap-3" alignItems={'center'}>
                      <div className="app-font-regular">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                      <Flex className="app-font-medium" direction={'column'}>
                        {item.content.map((content, index) => (
                          <span key={index}>
                            {content.label}&nbsp;: {content.value}
                          </span>
                        ))}
                      </Flex>
                    </Flex>
                  </a>
                </Dropdown.Item>
              ))}
            </>
          )}

          {resultItem.length === 0 && (
            <p className="fs-1 fw-bold text-center mb-0">
              {t('No result found')}
            </p>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchBox;
