export const translations = {
  en: {
    translation: {
      'At least 3 characters': 'At least 3 characters',
      Actions: 'Actions',
      'An error occured while trying to access URL':
        'An error occured while trying to access URL',
      'An error occured while trying to get backlinks details':
        'An error occured while trying to get backlinks details',
      'An error occured while trying to get backlinks summary':
        'An error occured while trying to get backlinks summary',
      'An error occured while trying to get domain intersection':
        'An error occured while trying to get domain intersection',
      'An error occured while trying to get domain technologies':
        'An error occured while trying to get domain technologies',
      'An error occured while trying to get Google reviews':
        'An error occured while trying to get Google reviews',
      'An error occured while trying to get keywords':
        'An error occured while trying to get keywords',
      'An error occured while trying to get site traffic':
        'An error occured while trying to get site traffic',
      'An error occured while trying to get social medias result':
        'An error occured while trying to get social medias result',
      Average: 'Average',
      'Backlink analysis failed': 'Backlink analysis failed',
      'Backlink analysis finished': 'Backlink analysis finished',
      'Backlink analysis removed successfully':
        'Backlink analysis removed successfully',
      'Backlink analysis successfully scheduled':
        'Backlink analysis successfully scheduled',
      Backlinks: 'Backlinks',
      'Bad username or password': 'Bad username or password',
      'Benchmark analysis failed': 'Benchmark analysis failed',
      'Benchmark analysis finished': 'Benchmark analysis finished',
      'Benchmark analysis removed successfully':
        'Benchmark analysis removed successfully',
      'Benchmark analysis successfully scheduled':
        'Benchmark analysis successfully scheduled',
      Close: 'Close',
      Competitor: 'Concurrent',
      'Competitor URL': 'Competitor URL',
      'Content type': 'Content type',
      Daily: 'Daily',
      Date: 'Date',
      Delete: 'Delete',
      Domain: 'Domain',
      'Due to low traffic, results cannot be displayed':
        'Due to low traffic, results cannot be displayed',
      Email: 'Email',
      English: 'English',
      'Every 5 minutes': 'Every 5 minutes',
      'Every 10 minutes': 'Every 10 minutes',
      'Every hours': 'Every hours',
      'Every 6 hours': 'Every 6 hours',
      'Every 12 hours': 'Every 12 hours',
      'Every 24 hours': 'Every 24 hours',
      Finished: 'Finished',
      Followed: 'Followed',
      'Forgot password ?': 'Forgot password ?',
      Frequency: 'Frequency',
      French: 'French',
      'Invalid password reset token': 'Invalid password reset token',
      Issue: 'Issue',
      Issues: 'Issues',
      Keywords: 'Keywords',
      Language: 'Language',
      'Loading...': 'Loading...',
      Location: 'Location',
      'Logged in': 'Logged in',
      Login: 'Login',
      'Monitor failed': 'Monitor failed',
      'Monitor finished': 'Monitor finished',
      'Monitor removed successfully': 'Monitor removed successfully',
      'Monitor successfully scheduled': 'Monitor successfully scheduled',
      Monthly: 'Monthly',
      'No result found': 'No result found',
      'Not followed': 'Not followed',
      Negative: 'Negative',
      Network: 'Network',
      Neutral: 'Neutral',
      'Next report': 'Next report',
      Ongoing: 'Ongoing',
      Page: 'Page',
      'Page analysis failed': 'Page analysis failed',
      'Page analysis finished': 'Page analysis finished',
      'Page analysis removed successfully':
        'Page analysis removed successfully',
      'Page analysis successfully scheduled':
        'Page analysis successfully scheduled',
      Pages: 'Pages',
      Password: 'Password',
      'Password confirmation': 'Password confirmation',
      'Password reseted': 'Password reseted',
      'Password reset email sent': 'Password reset email sent',
      Positive: 'Positive',
      Progress: 'Progress',
      'Report failed': 'Report failed',
      'Report finished': 'Report finished',
      'Report removed successfully': 'Report removed successfully',
      'Report successfully scheduled': 'Report successfully scheduled',
      Restart: 'Restart',
      'Results count': 'Results count',
      Search: 'Search',
      Select: 'Select',
      Sentiment: 'Sentiment',
      'Separated by a comma': 'Separated by a comma',
      Score: 'Score',
      Site: 'Site',
      'Site analysis failed': 'Site analysis failed',
      'Site analysis finished': 'Site analysis finished',
      'Site analysis removed successfully':
        'Site analysis removed successfully',
      'Site analysis successfully scheduled':
        'Site analysis successfully scheduled',
      'Social analysis failed': 'Social analysis failed',
      'Social analysis finished': 'Social analysis finished',
      'Social analysis removed successfully':
        'Social analysis removed successfully',
      'Social analysis successfully scheduled':
        'Social analysis successfully scheduled',
      'Something went wrong': 'Something went wrong',
      Submit: 'Submit',
      Text: 'Text',
      'The password and its confirmation must be identical':
        'The password and its confirmation must be identical',
      'Tracking analysis failed': 'Tracking analysis failed',
      'Tracking analysis finished': 'Tracking analysis finished',
      'Tracking analysis removed successfully':
        'Tracking analysis removed successfully',
      'Tracking analysis successfully scheduled':
        'Tracking analysis successfully scheduled',
      Type: 'Type',
      URL: 'URL',
      'User not found': 'User not found',
      Users: 'Users',
      'Users mentions': 'Users mentions',
      Warnings: 'Warnings',
      Warning: 'Warning',
      Weekly: 'Weekly',
      'Writer failed': 'Writer failed',
      'Writer finished': 'Writer finished',
      'Writer removed successfully': 'Writer removed successfully',
      'Writer successfully scheduled': 'Writer successfully scheduled'
    }
  },
  fr: {
    translation: {
      'At least 3 characters': 'Au moins 3 caractères',
      Actions: 'Actions',
      'An error occured while trying to access URL':
        "Une erreur s'est produite lors de l'accès à l'URL",
      'An error occured while trying to get backlinks details':
        "Une erreur s'est produite lors de l'obtention des détails des backlinks",
      'An error occured while trying to get backlinks summary':
        "Une erreur s'est produite lors de l'obtention du résumé des backlinks",
      'An error occured while trying to get domain intersection':
        "Une erreur s'est produite lors de l'obtention de l'intersection du domaine",
      'An error occured while trying to get domain technologies':
        "Une erreur s'est produite lors de l'obtention des technologies du domaine",
      'An error occured while trying to get Google reviews':
        "Une erreur s'est produite lors de l'obtention des avis Google",
      'An error occured while trying to get keywords':
        "Une erreur s'est produite lors de l'obtention des mots-clés",
      'An error occured while trying to get site traffic':
        "Une erreur s'est produite lors de l'obtention du trafic du site",
      'An error occured while trying to get social medias result':
        "Une erreur s'est produite lors de l'obtention des résultats des médias sociaux",
      Average: 'Moyenne',
      'Backlink analysis failed': "L'analyse backlink a échoué",
      'Backlink analysis finished': "L'analyse backlink est terminée",
      'Backlink analysis removed successfully':
        "L'analyse backlink a été supprimée avec succès",
      'Backlink analysis successfully scheduled':
        "L'analyse backlink a été programmée avec succès",
      Backlinks: 'Backlinks',
      'Bad username or password': "Mauvais nom d'utilisateur ou mot de passe",
      'Benchmark analysis failed': "L'analyse benchmark a échoué",
      'Benchmark analysis finished': "L'analyse benchmark est terminée",
      'Benchmark analysis removed successfully':
        "L'analyse benchmark a été supprimée avec succès",
      'Benchmark analysis successfully scheduled':
        "L'analyse benchmark a été programmée avec succès",
      Close: 'Fermer',
      Competitor: 'Concurrent',
      'Competitor URL': 'URL du concurrent',
      'Content type': 'Type de contenu',
      Daily: 'Journalier',
      Date: 'Date',
      Delete: 'Supprimer',
      Domain: 'Domaine',
      'Due to low traffic, results cannot be displayed':
        "En raison d'un faible trafic, les résultats ne peuvent pas être affichés",
      Email: 'Email',
      English: 'Anglais',
      'Every 5 minutes': 'Toutes les 5 minutes',
      'Every 10 minutes': 'Toutes les 10 minutes',
      'Every hours': 'Toutes les heures',
      'Every 6 hours': 'Toutes les 6 heures',
      'Every 12 hours': 'Toutes les 12 heures',
      'Every 24 hours': 'Toutes les 24 heures',
      Finished: 'Terminé',
      Followed: 'Suivi',
      'Forgot password ?': 'Mot de passe oublié ?',
      Frequency: 'Fréquence',
      French: 'Français',
      'Invalid password reset token':
        'Jeton de réinitialisation du mot de passe invalide',
      Issue: 'Problème',
      Issues: 'Problèmes',
      Keywords: 'Mots-clés',
      'Loading...': 'Chargement...',
      Language: 'Langue',
      Location: 'Localisation',
      'Logged in': 'Connecté',
      Login: 'Connexion',
      'Monitor failed': 'Le moniteur a échouée',
      'Monitor finished': 'Le moniteur est terminée',
      'Monitor removed successfully': 'Le moniteur a été supprimée avec succès',
      'Monitor successfully scheduled':
        'Le moniteur a été programmée avec succès',
      Monthly: 'Mensuel',
      'No result found': 'Aucun résultat trouvé',
      'Not followed': 'Non suivi',
      Negative: 'Négatif',
      Network: 'Réseau',
      Neutral: 'Neutre',
      'Next report': 'Prochain rapport',
      Ongoing: 'En cours',
      Page: 'Page',
      'Page analysis failed': "L'analyse de page a échouée",
      'Page analysis finished': "L'analyse de page est terminée",
      'Page analysis removed successfully':
        "L'analyse de page a été supprimée avec succès",
      'Page analysis successfully scheduled':
        "L'analyse de page a été programmée avec succès",
      Pages: 'Pages',
      Password: 'Mot de passe',
      'Password confirmation': 'Confirmation du mot de passe',
      'Password reseted': 'Mot de passe réinitialisé',
      'Password reset email sent':
        "L'email de réinitialisation du mot de passe a été envoyé",
      Positive: 'Positif',
      Progress: 'Progression',
      'Report failed': 'Le rapport a échouée',
      'Report finished': 'Le rapport est terminée',
      'Report removed successfully': 'Le rapport a été supprimée avec succès',
      'Report successfully scheduled':
        'Le rapport a été programmée avec succès',
      Restart: 'Recommencer',
      'Results count': 'Nombre de résultats',
      Search: 'Rechercher',
      Select: 'Sélectionnez',
      Sentiment: 'Sentiment',
      'Separated by a comma': 'Séparés par une virgule',
      Score: 'Score',
      Site: 'Site',
      'Site analysis failed': "L'analyse de site a échouée",
      'Site analysis finished': "L'analyse de site est terminée",
      'Site analysis removed successfully':
        "L'analyse de site a été supprimée avec succès",
      'Site analysis successfully scheduled':
        "L'analyse de site a été programmée avec succès",
      'Social analysis failed': "L'analyse du social a échouée",
      'Social analysis finished': "L'analyse du social est terminée",
      'Social analysis removed successfully':
        "L'analyse du social a été supprimée avec succès",
      'Social analysis successfully scheduled':
        "L'analyse du social a été programmée avec succès",
      'Something went wrong': "Quelque chose n'a pas fonctionné",
      Submit: 'Soumettre',
      Text: 'Texte',
      'The password and its confirmation must be identical':
        'Le mot de passe et sa confirmation doivent être identiques',
      'Tracking analysis failed': "L'analyse de suivi a échouée",
      'Tracking analysis finished': "L'analyse de suivi est terminée",
      'Tracking analysis removed successfully':
        "L'analyse de suivi a été supprimée avec succès",
      'Tracking analysis successfully scheduled':
        "L'analyse de suivi a été programmée avec succès",
      Type: 'Type',
      URL: 'URL',
      'User not found': 'Utilisateur non trouvé',
      Users: 'Utilisateurs',
      'Users mentions': 'Mentions utilisateurs',
      Warning: 'Avertissement',
      Warnings: 'Avertissements',
      Weekly: 'Hebdomadaire',
      'Writer failed': 'Le writer a échouée',
      'Writer finished': 'Le writer est terminée',
      'Writer removed successfully': 'Le writer a été supprimée avec succès',
      'Writer successfully scheduled': 'Le writer a été programmée avec succès'
    }
  }
};
