import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';

const AccountDetails = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [userData, setUserData] = useState({});

  const fetchUser = useCallback(async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}users/me`, {
      headers: { Authorization: 'Bearer ' + token }
    });
    const json = await response.json();
    console.log(json);
    setUserData(json.user);
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();

    if (
      event.target.password.value != event.target.passwordConfirmation.value
    ) {
      toast.error(
        'Le mot de passe et sa confirmation doivent être identiques',
        toastOptions
      );
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}users`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        firstname: event.target.firstname.value,
        lastname: event.target.lastname.value,
        email: event.target.email.value,
        phoneNumber: event.target.phoneNumber.value,
        password: event.target.password.value,
        country: event.target.country.value,
        city: event.target.city.value,
        postcode: event.target.postcode.value,
        address: event.target.address.value
      })
    });
    const json = await response.json();
    event.target.reset();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
    fetchUser();
  };

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="app-font-bold app-card-title fs-1 mt-3">
          Informations générales
        </h2>
        <Row>
          <Col className="my-3" xl={6}>
            <Form.Label htmlFor="editUserFirstname">Prénom</Form.Label>
            <Form.Control
              id="editUserFirstname"
              name="firstname"
              className="p-3"
              defaultValue={userData.firstname}
              type="text"
            />
          </Col>
          <Col className="my-3" xl={6}>
            <Form.Label htmlFor="editUserLastname">Nom</Form.Label>
            <Form.Control
              id="editUserLastname"
              name="lastname"
              className="p-3"
              defaultValue={userData.lastname}
              type="text"
            />
          </Col>
        </Row>
        <h2 className="app-font-bold app-card-title fs-1 mt-3">
          Informations de contact
        </h2>
        <Row>
          <Col className="my-3" xl={6}>
            <Form.Label htmlFor="editUserEmail">Email</Form.Label>
            <Form.Control
              id="editUserEmail"
              name="email"
              className="p-3"
              defaultValue={userData.email}
              type="email"
            />
          </Col>
          <Col className="my-3" xl={6}>
            <Form.Label htmlFor="editUserPhoneNumber">Téléphone</Form.Label>
            <Form.Control
              id="editUserPhoneNumber"
              name="phoneNumber"
              className="p-3"
              defaultValue={userData.phoneNumber}
              type="text"
            />
          </Col>
          <Col className="my-3" xl={6}>
            <Form.Label htmlFor="editUserPassword">
              Nouveau mot de passe
            </Form.Label>
            <Form.Control
              id="editUserPassword"
              name="password"
              className="p-3"
              type="password"
            />
          </Col>
          <Col className="my-3" xl={6}>
            <Form.Label htmlFor="editUserPasswordConfirmation">
              Confirmer mot de passe
            </Form.Label>
            <Form.Control
              id="editUserPasswordConfirmation"
              name="passwordConfirmation"
              className="p-3"
              type="password"
            />
          </Col>
        </Row>
        <h2 className="app-font-bold app-card-title fs-1 mt-3">
          Adresse de facturation
        </h2>
        <Row>
          <Col className="my-3" xl={6}>
            <Form.Label htmlFor="editUserCountry">Pays</Form.Label>
            <Form.Control
              id="editUserCountry"
              name="country"
              className="p-3"
              defaultValue={userData.organization?.country}
              type="text"
            />
          </Col>
          <Col className="my-3" xl={6}>
            <Form.Label htmlFor="editUserCity">Ville</Form.Label>
            <Form.Control
              id="editUserCity"
              name="city"
              className="p-3"
              defaultValue={userData.organization?.city}
              type="text"
            />
          </Col>
          <Col className="my-3" xl={6}>
            <Form.Label htmlFor="editUserPostcode">Code postal</Form.Label>
            <Form.Control
              id="editUserPostcode"
              name="postcode"
              className="p-3"
              defaultValue={userData.organization?.postcode}
              type="text"
            />
          </Col>
          <Col className="my-3" xl={6}>
            <Form.Label htmlFor="editUserAddress">Adresse</Form.Label>
            <Form.Control
              id="editUserAddress"
              name="address"
              className="p-3"
              defaultValue={userData.organization?.address}
              type="text"
            />
          </Col>
        </Row>
        <Flex justifyContent={'end'}>
          <Button color="primary" type="submit">
            Sauvegarder les changements
          </Button>
        </Flex>
      </Form>
    </>
  );
};

export default AccountDetails;
