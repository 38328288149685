export const pageAnalysisData = {
  title: 'Analyse de Page',
  description:
    "L'analyse de page vous permet d'obtenir une analyse de votre page web en termes de qualité SEO, contenu et technique. Vous trouverez l'ensemble des problèmes prépondérants et les avertissements de votre page web.",
  overview: {
    title: "Vue d'ensemble",
    description:
      "L'analyse de page vous permet d'obtenir une analyse de votre page web en termes de qualité SEO, contenu et technique. Ci-dessous vous pouvez visualiser les erreurs prioritaires à traiter ainsi qu'un score définissant la qualité de votre page."
  },
  seo: {
    title: 'SEO',
    description:
      "Vous trouverez ci-dessous les informations liée à l'optimisation SEO de votre page web. Ces erreurs peuvent bloquer le robot d'indexation des moteurs de recherche à vous référencer de façon optimale."
  },
  content: {
    title: 'Contenu',
    description:
      'Le contenu est essentiel pour le référencement de votre site internet. Un bon contenu SEO rendra votre site visible dans les résultats des moteurs de recherche. Ci-dessous vous trouverez toutes les informations concernant le contenu de votre page web.'
  },
  technic: {
    title: 'Technique',
    description:
      'La conception de votre site doit respecter des standards web. Ci-dessous vous trouverez toutes les informations concernant les règles à respecter pour améliorer techniquement votre site web.'
  },
  performance: {
    title: 'Performance',
    description:
      'Un site web avec un chargement rapide est apprécié par les moteurs de recherche mais encore plus par les internautes. Ci-dessous vous trouverez les erreurs liées à la performance de votre page web.'
  },
  accessibility: {
    title: 'Accessibilité',
    description:
      "Un site accessible pour les robots est un site qui comporte le moins d'erreurs de codage et de structure possible afin de favoriser son parcours par les moteurs de recherche."
  }
};
