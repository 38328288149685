import * as echarts from 'echarts';

import { Col, Row } from 'react-bootstrap';
import {
  faCheckCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import EChartsReactCore from 'echarts-for-react/lib/core';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { getColor } from 'helpers/utils';
import { useParams } from 'react-router-dom';

const SiteAnalysisDetailsIndexation = ({ title, description, data }) => {
  const params = useParams();

  let indexablesCount = 0;
  data?.analysisData?.map(page => {
    page?.data?.overview?.isIndexable && indexablesCount++;
  });
  const summaryOption = {
    series: [
      {
        type: 'pie',
        radius: ['40%', '80%'],
        label: {
          show: false,
          position: 'center'
        },
        data: [
          {
            value: indexablesCount,
            name: 'Pages indexables',
            itemStyle: {
              color: getColor('success')
            }
          },
          {
            value: data?.analysisData?.length - indexablesCount,
            name: 'Pages non indexables',
            itemStyle: {
              color: getColor('danger')
            }
          }
        ]
      }
    ],
    legend: {
      bottom: 'bottom'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const urlsColumns = [
    {
      accessor: 'state',
      Header: 'Statut'
    },
    {
      accessor: 'url',
      Header: 'URL'
    },
    {
      accessor: 'detail',
      Header: 'Détail'
    }
  ];
  const urlsData = [];
  data?.analysisData?.map(page => {
    urlsData?.push({
      state: page?.data?.overview?.isIndexable
        ? 'Page indexable'
        : 'Page non indexable',
      url: page?.data?.url,
      detail: (
        <a
          href={`/site-analysis/${params.id}/page/${page?.id}/`}
          title="Voir l'analyse de page"
          className="mx-1"
        >
          <EyeLineIcon />
        </a>
      )
    });
  });

  return (
    <>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <EChartsReactCore
              echarts={echarts}
              option={summaryOption}
              style={{ minHeight: '500px' }}
            />
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{title}</h2>
              <p>{description}</p>
              <h3 className="fs-1 mt-3">Résumé</h3>
              <Flex className="fw-bold pb-2 border-bottom w-100">
                <Flex
                  className="w-100"
                  justifyContent={'between'}
                  alignItems={'center'}
                >
                  <span>Tout :</span>
                  <span>{data?.analysisData?.length}</span>
                </Flex>
              </Flex>
              <Flex className="fw-bold text-success pb-2 border-bottom w-100">
                <Flex
                  className="w-100"
                  justifyContent={'between'}
                  alignItems={'center'}
                >
                  <Flex className="gap-3" alignItems={'center'}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span>Pages indexables :</span>
                  </Flex>
                  <span>{indexablesCount}</span>
                </Flex>
              </Flex>
              <Flex className="fw-bold text-danger w-100">
                <Flex
                  className="w-100"
                  justifyContent={'between'}
                  alignItems={'center'}
                >
                  <Flex className="gap-3" alignItems={'center'}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span>Pages non indexables</span>
                  </Flex>
                  <span>{data?.analysisData?.length - indexablesCount}</span>
                </Flex>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="p-3 my-3">
        <CustomAdvanceTable columns={urlsColumns} data={urlsData} />
      </div>
    </>
  );
};

SiteAnalysisDetailsIndexation.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default SiteAnalysisDetailsIndexation;
