export const aiReportData = {
  title: 'Rapport AI',
  description:
    "Le rapport AI vous permet d'analyser de manière pertinente l'ensemble des information SEO, social et de e-réputation. Vous pourrez par la même occasion définir une planification pour le lancement automatique des analyses. Dans ce rapport vous recevez l'ensemble des conseils nécessaires pour accroître ou maitriser sa visibilité sur internet.",
  overview: {
    title: "Vue d'ensemble",
    description:
      "Le rapport AI vous permet d'analyser de manière pertinente l'ensemble des information SEO, social et de e-réputation. Ci-dessous vous pouvez visualiser rapidement l'impact de votre référencement."
  },
  crawl: {
    title: 'Crawl',
    description:
      "Le crawl vous permet de faire une analyse de votre site web depuis une URL. Le Crawl va alors analyser un groupe de pages, situer les problèmes pertinents afin de vous faciliter la tâche de les regrouper et donc de les corriger plus rapidement. A vous de simplement préciser le nombre de pages à « crawler », et l'analyse vous permettra d'examiner l'architecture de votre site de plus près, afin de situer les failles éventuellement liées à vos URLs. Vous pourrez visualiser ci-dessous l'historique de l'ensemble des analyses de crawls réalisées."
  },
  backlink: {
    title: 'Backlinks',
    description:
      "L'analyse du backlink vous permet de connaitre les liens externes vers votre site web ou celui de votre concurrent ainsi que le type de lien suivis ou non-suivis. En analysant uniquement les liens à forte valeur ajoutée, vous allez gagner du temps et concentrer vos efforts d'amélioration sur les liens qui ont le plus d'impact sur le référencement. Vous pourrez visualiser ci-dessous l'historique de l'ensemble des analyses de backlinks réalisées."
  },
  benchmark: {
    title: 'SEO Benchmark',
    description:
      "Le Benchmark SEO vous permet de découvrir les mots clés les plus prometteurs afin de vous positionner efficacement par rapport à vos concurrents. De plus, vous pourrez découvrir sur quels mots clés vos concurrents ont choisi d'être positionnés, et donc choisir des mots clés de longue traîne plus efficacement pour votre propre stratégie. Vous pourrez visualiser ci-dessous l'historique de l'ensemble des analyses de Benchmark réalisées."
  },
  social: {
    title: 'Social Buzz',
    description:
      "Le Social Buzz vous permet d'avoir une analyse de votre impact sur le web de façon globale. Ci-dessous vous pouvez visualiser le sentiment et les mentions contenant votre nom ou votre URL. Vous pouvez visualiser en détail chaque mention ainsi que les avis Google ou encore les réseaux sociaux prédéfinis. Vous pourrez visualiser ci-dessous l'historique de l'ensemble des analyses de Social Buzz réalisées."
  },
  data: [
    {
      id: 1,
      site: 'https://www.google.com',
      recurrence: 'Hebdomadaire',
      progress: 'Terminé',
      score: 64,
      nextReport: '2020-05-15'
    }
  ]
};
