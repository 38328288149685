import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';

import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import React, { useContext } from 'react';

import AppContext from 'context/Context';
import { CloseButton } from 'components/common/Toast';
import ForgotPassword from 'ForgotPassword';
import Layout from './layouts/Layout';
import Login from 'Login';
import ResetPassword from 'ResetPassword';
import { ToastContainer } from 'react-toastify';

const App = () => {
  const {
    config: { userId, userRole, token }
  } = useContext(AppContext);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      {userId == '' ||
      userId == null ||
      userRole == '' ||
      userRole == null ||
      token == '' ||
      token == null ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <Layout />
      )}
      <ToastContainer closeButton={CloseButton} icon={false} />
    </Router>
  );
};

export default App;
