import { Col, Image, Row } from 'react-bootstrap';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import CustomInfoCard from 'components/anopseo/common/CustomInfoCard';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PageAnalysisDetailsSeo = ({ title, description, data }) => {
  const { t } = useTranslation();

  const metaTagsColumns = [
    {
      accessor: 'name',
      Header: 'Nom'
    },
    {
      accessor: 'charset',
      Header: 'Charset'
    },
    {
      accessor: 'property',
      Header: 'Propriété'
    },
    {
      accessor: 'content',
      Header: 'Contenu'
    }
  ];
  const metaTagsData = [];
  data?.seo?.meta_tags?.items?.map(item =>
    metaTagsData.push({
      name: item?.name,
      charset: item?.charset,
      property: item?.property,
      content: item?.content
    })
  );
  const metaTagRobotColumns = [
    {
      accessor: 'element',
      Header: 'Élément'
    }
  ];
  const metaTagRobotData = [];
  data?.seo?.meta_tag_robot?.items?.map(item =>
    metaTagRobotData.push({ element: item })
  );
  const multimediaColumns = [
    {
      accessor: 'element',
      Header: 'Élément'
    }
  ];
  const multimediaData = [];
  data?.seo?.multimedia?.items?.map(item =>
    multimediaData.push({ element: item })
  );
  const linkageColumns = [
    {
      accessor: 'url',
      Header: 'URL'
    }
  ];
  const linkageData = [];
  data?.seo?.internal_links?.items?.map(item =>
    linkageData.push({ url: item })
  );

  return (
    <>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <Flex alignItems={'center'} direction={'column'}>
              <Image
                className="img-fluid"
                src={data?.overview?.screenshotUrl}
              />
              <a
                href={data?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-center"
              >
                {data?.url}
              </a>
            </Flex>
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{title}</h2>
              <p>{description}</p>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Informations principales</h3>
        <CustomInfoCard
          content={data?.seo?.document_title?.title}
          state={data?.seo?.document_title?.status}
          description={data?.seo?.document_title?.message}
        >
          <div className="mt-3">
            <p className="mb-0">
              <strong>
                {data?.seo?.document_title?.items != null &&
                data?.seo?.document_description?.items[0].document_title !=
                  (null || '')
                  ? data?.seo?.document_title?.items[0].document_title
                  : t('No result found')}
              </strong>
            </p>
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.seo?.document_description?.title}
          state={data?.seo?.document_description?.status}
          description={data?.seo?.document_description?.message}
        >
          <div className="mt-3">
            <p className="mb-0">
              <strong>
                {data?.seo?.document_description?.items != null &&
                data?.seo?.document_description?.items[0].meta_description !=
                  (null || '')
                  ? data?.seo?.document_description?.items[0].meta_description
                  : t('No result found')}
              </strong>
            </p>
          </div>
        </CustomInfoCard>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Meta Tags</h3>
        <CustomInfoCard
          content={data?.seo?.meta_tags?.title}
          state={data?.seo?.meta_tags?.status}
          description={data?.seo?.meta_tags?.message}
        >
          <div className="mt-3">
            {metaTagsData?.length > 0 ? (
              <CustomAdvanceTable
                columns={metaTagsColumns}
                data={metaTagsData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.seo?.meta_tag_robot?.title}
          state={data?.seo?.meta_tag_robot?.status}
          description={data?.seo?.meta_tag_robot?.message}
        >
          <div className="mt-3">
            {metaTagRobotData?.length > 0 ? (
              <CustomAdvanceTable
                columns={metaTagRobotColumns}
                data={metaTagRobotData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Indexation</h3>
        <CustomInfoCard
          content={data?.seo?.robots?.title}
          state={data?.seo?.robots?.status}
          description={data?.seo?.robots?.message}
        />
        <CustomInfoCard
          content={data?.seo?.sitemap?.title}
          state={data?.seo?.sitemap?.status}
          description={data?.seo?.sitemap?.message}
        />
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Contenu dupliqué</h3>
        <CustomInfoCard
          content={data?.seo?.canonical?.title}
          state={data?.seo?.canonical?.status}
          description={data?.seo?.canonical?.message}
        />
      </div>
      <div className="p-3 my-3">
        <CustomInfoCard
          content={data?.seo?.multimedia?.title}
          state={data?.seo?.multimedia?.status}
          description={data?.seo?.multimedia?.message}
        >
          <div className="mt-3">
            {multimediaData.length > 0 ? (
              <CustomAdvanceTable
                columns={multimediaColumns}
                data={multimediaData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
      </div>
      <div className="p-3 my-3">
        <CustomInfoCard
          content={data?.seo?.internal_links?.title}
          state={data?.seo?.internal_links?.status}
          description={data?.seo?.internal_links?.message}
        >
          <div className="mt-3">
            {linkageData.length > 0 ? (
              <CustomAdvanceTable columns={linkageColumns} data={linkageData} />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
      </div>
    </>
  );
};

PageAnalysisDetailsSeo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default PageAnalysisDetailsSeo;
