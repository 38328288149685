import { useContext, useEffect } from 'react';

import AppContext from 'context/Context';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

const ToastInLayout = () => {
  const { t } = useTranslation();
  const { lastMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    shouldReconnect: () => true
  });
  const {
    config: { userId }
  } = useContext(AppContext);

  useEffect(() => {
    if (lastMessage == null) {
      return;
    }
    const data = JSON.parse(lastMessage.data);
    if (data != null) {
      if (data.userId == userId) {
        if (data.toastMessage != null) {
          data.status == 'success' &&
            toast.success(t(data.toastMessage), toastOptions);
          data.status == 'error' &&
            toast.error(t(data.toastMessage), toastOptions);
        }
      }
    }
  }, [lastMessage]);
};

export default ToastInLayout;
