import { Card, Tab, Tabs } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import ReportDetailsBacklink from './details/ReportDetailsBacklink';
import ReportDetailsBenchmark from './details/ReportDetailsBenchmark';
import ReportDetailsCrawler from './details/ReportDetailsCrawler';
import ReportDetailsOverview from './details/ReportDetailsOverview';
import ReportDetailsSocial from './details/ReportDetailsSocial';
import { useParams } from 'react-router-dom';

const ReportDetails = () => {
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    const backlinkResponse = await fetch(
      `${process.env.REACT_APP_API_URL}reports/${params.id}`,
      {
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    const json = await backlinkResponse.json();
    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Card className="p-3">
        <Tabs variant="pills" defaultActiveKey="overview">
          <Tab eventKey="overview" title="VUE D'ENSEMBLE">
            <ReportDetailsOverview data={data} />
          </Tab>
          {data?.isSiteAnalyzesEnabled && (
            <Tab eventKey="crawler" title="CRAWLER">
              <ReportDetailsCrawler data={data} />
            </Tab>
          )}
          {data?.isBacklinksEnabled && (
            <Tab eventKey="backlink" title="BACKLINKS">
              <ReportDetailsBacklink data={data} />
            </Tab>
          )}
          {data?.isBenchmarksEnabled && (
            <Tab eventKey="benchmark" title="BENCHMARK">
              <ReportDetailsBenchmark data={data} />
            </Tab>
          )}
          {data?.isSocialsEnabled && (
            <Tab eventKey="social" title="SOCIAL SIGNAL">
              <ReportDetailsSocial data={data} />
            </Tab>
          )}
        </Tabs>
      </Card>
    </>
  );
};

export default ReportDetails;
