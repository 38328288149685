import 'helpers/initFA';
import './i18n';

import App from './App';
import Main from './Main';
import React from 'react';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>
);
