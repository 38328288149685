import { Button, Form, Spinner, Table } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddLineIcon from 'remixicon-react/AddLineIcon';
import AppContext from 'context/Context';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import CustomModal from '../common/CustomModal';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

const Benchmark = ({ benchmark, isSummary = false }) => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [restartModalShow, setRestartModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const {
    id,
    createdAt,
    isOnError,
    errorMessages,
    isFinished,
    url,
    keywordsResultsCount
  } = benchmark;

  const createdAtDate = new Date(createdAt).toLocaleString();
  const createdAtSummaryDate = new Date(createdAt).toLocaleDateString();

  const restartAnalysis = async () => {
    setRestartModalShow(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}benchmarks`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ url: url })
    });
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  const deleteAnalysis = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}benchmarks/${id}`,
      {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
    setDeleteModalShow(false);
  };

  return (
    <>
      <tr
        className={
          isOnError
            ? 'app-in-error-analysis'
            : !isFinished
            ? 'app-not-finished-analysis'
            : ''
        }
      >
        <td className="align-middle">
          <Flex
            style={{ whiteSpace: 'nowrap' }}
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {isSummary ? createdAtSummaryDate : createdAtDate}
          </Flex>
        </td>
        <td className="align-middle">
          <Flex
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {url}
          </Flex>
        </td>
        {isOnError ? (
          <td className="align-middle">
            <Flex
              className="text-center h-100"
              direction={'column'}
              justifyContent={'start'}
              alignItems={'center'}
            >
              {errorMessages?.map((errorMessage, index) => (
                <p key={`analysis${id}errorMessage${index}`} className="mb-0">
                  {t(errorMessage)}
                </p>
              ))}
            </Flex>
          </td>
        ) : !isFinished ? (
          <>
            <td></td>
          </>
        ) : (
          <>
            <td className="align-middle">
              <Flex
                className="h-100"
                justifyContent={'center'}
                alignItems={'start'}
                direction={'column'}
              >
                {keywordsResultsCount}
              </Flex>
            </td>
          </>
        )}
        {!isSummary && (
          <td className="align-middle">
            <Flex
              className="h-100"
              justifyContent={'end'}
              alignItems={'center'}
              direction={'row'}
            >
              {isFinished && (
                <>
                  {!isOnError ? (
                    <a
                      href={`/benchmark/${id}/`}
                      title="Voir l'analyse benchmark"
                      className="mx-1"
                    >
                      <EyeLineIcon />
                    </a>
                  ) : null}
                  <a
                    onClick={() => setRestartModalShow(true)}
                    href={'#'}
                    title="Recommencer l'analyse benchmark"
                    className="mx-1"
                  >
                    <RefreshLineIcon />
                  </a>
                </>
              )}
              <a
                onClick={() => setDeleteModalShow(true)}
                href={'#'}
                title="Supprimer l'analyse benchmark"
                className="mx-1"
              >
                <CloseCircleLineIcon />
              </a>
            </Flex>
          </td>
        )}
      </tr>
      {!isSummary && (
        <>
          <CustomModal
            isShown={restartModalShow}
            setIsShown={setRestartModalShow}
            title="Recommencer l'analyse benchmark"
            description={
              <p>
                Êtes-vous sûr de vouloir recommencer l'analyse benchmark
                séléctionnée&nbsp;?
                <br />
                {t('Date')}&nbsp;: <strong>{createdAtDate}</strong>
                <br />
                {t('URL')}&nbsp;: <strong>{url}</strong>
              </p>
            }
            content={
              <Button
                className="app-modal-submit-button"
                onClick={() => restartAnalysis()}
              >
                {t('Restart')}
              </Button>
            }
          />
          <CustomModal
            isShown={deleteModalShow}
            setIsShown={setDeleteModalShow}
            title="Supprimer l'analyse benchmark"
            description={
              <p>
                Êtes-vous sûr de vouloir supprimer l'analyse benchmark
                séléctionnée&nbsp;?
                <br />
                {t('Date')}&nbsp;: <strong>{createdAtDate}</strong>
                <br />
                {t('URL')}&nbsp;: <strong>{url}</strong>
              </p>
            }
            content={
              <Button
                className="app-modal-submit-button"
                variant="danger"
                onClick={() => deleteAnalysis()}
              >
                {t('Delete')}
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

Benchmark.propTypes = {
  benchmark: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    isOnError: PropTypes.bool,
    errorMessages: PropTypes.arrayOf(PropTypes.string),
    isFinished: PropTypes.bool,
    url: PropTypes.string,
    keywordsResultsCount: PropTypes.number
  }),
  isSummary: PropTypes.bool
};

const Header = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);

  const handleFormSubmit = async e => {
    e.preventDefault();
    setModalShow(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}benchmarks`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: e.target.url.value
      })
    });
    const json = await response.json();
    e.target.reset();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  return (
    <>
      <div className="app-list-header">
        <h2 className="app-list-title">Seo Benchmark</h2>
        <Button className="app-list-button" onClick={() => setModalShow(true)}>
          <Flex className="gap-1" alignItems={'center'}>
            <AddLineIcon className="app-list-button-icon" />
            <span>Créer une analyse benchmark</span>
          </Flex>
        </Button>
        <div className="app-list-description">
          <p>
            Le Benchmark SEO vous permet de découvrir les mots clés les plus
            prometteurs afin de vous positionner efficacement par rapport à vos
            concurrents. De plus, vous pourrez découvrir sur quels mots clés vos
            concurrents ont choisi d'être positionnés, et donc choisir des mots
            clés de longue traîne plus efficacement pour votre propre stratégie.
          </p>
        </div>
      </div>
      <CustomModal
        isShown={modalShow}
        setIsShown={setModalShow}
        title="Analyse Benchmark"
        description="Ci-dessous vous pouvez entrer l'URL de votre page à analyser. Vérifier que votre page est accessible avant de lancer l'analyse de votre page. L'URL est de type&nbsp;: http(s)://www.domaine.extension ou http(s)://domaine.extension"
        content={
          <Form className="app-modal-form" onSubmit={handleFormSubmit}>
            <Form.Label htmlFor="modalUrl">{t('URL')}</Form.Label>
            <Form.Control
              id="modalUrl"
              name="url"
              type="text"
              required={true}
            />
            <Button className="app-modal-submit-button" type="submit">
              {t('Submit')}
            </Button>
          </Form>
        }
      />
    </>
  );
};

export const List = ({ maxResults = null, isSummary = false }) => {
  const { t } = useTranslation();
  const { lastMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    shouldReconnect: () => true
  });
  const {
    config: { userId, token }
  } = useContext(AppContext);
  const [benchmarks, setBenchmarks] = useState([]);
  const [isFetchFinished, setIsFetchFinished] = useState(false);

  const fetchBenchmarks = useCallback(async () => {
    const benchmarksResponse = await fetch(
      `${process.env.REACT_APP_API_URL}benchmarks`,
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    );
    const json = await benchmarksResponse.json();
    if (maxResults != null) {
      setBenchmarks(json.slice(0, maxResults));
    } else {
      setBenchmarks(json);
    }
    setIsFetchFinished(true);
  }, []);

  useEffect(() => {
    fetchBenchmarks();
  }, [fetchBenchmarks]);

  useEffect(() => {
    if (lastMessage == null) {
      return;
    }
    const data = JSON.parse(lastMessage.data);
    if (data != null) {
      if (data.userId == userId) {
        if (data.from == 'benchmarks') {
          fetchBenchmarks();
        }
      }
    }
  }, [fetchBenchmarks, lastMessage]);

  return (
    <>
      {isFetchFinished ? (
        benchmarks?.length == 0 ? (
          <Flex justifyContent={'center'}>
            <p className="text-center fw-bold my-3">
              Aucune analyse benchmark trouvée
            </p>
          </Flex>
        ) : (
          <Table className="app-list-table" responsive>
            <thead>
              <tr>
                <th scope="col">{t('Date')}</th>
                <th scope="col">{t('URL')}</th>
                <th scope="col">{t('Results count')}</th>
                {!isSummary && (
                  <th className="text-end" scope="col">
                    {t('Actions')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {benchmarks?.map(benchmark => (
                <Benchmark
                  benchmark={benchmark}
                  key={benchmark.id}
                  isSummary={isSummary}
                />
              ))}
            </tbody>
          </Table>
        )
      ) : (
        <Flex justifyContent={'center'}>
          <Spinner className="my-3" animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Flex>
      )}
    </>
  );
};

List.propTypes = {
  maxResults: PropTypes.number,
  isSummary: PropTypes.bool
};

const BenchmarkList = () => {
  return (
    <>
      <Header />
      <List />
    </>
  );
};

export default BenchmarkList;
