import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import User3FillIcon from 'remixicon-react/User3FillIcon';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

const SupportDetails = () => {
  const { t } = useTranslation();
  const { lastMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    shouldReconnect: () => true
  });
  const params = useParams();
  const {
    config: { userId, token }
  } = useContext(AppContext);
  const [support, setSupport] = useState({});

  const fetchSupport = useCallback(async () => {
    const supportResponse = await fetch(
      `${process.env.REACT_APP_API_URL}supports/${params.id}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await supportResponse.json();
    setSupport(json);
  }, []);

  const handleFormSubmit = async e => {
    e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}supports/messages`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: e.target.message.value,
          supportId: support.id
        })
      }
    );
    const json = await response.json();
    e.target.reset();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  useEffect(() => {
    fetchSupport();
  }, [fetchSupport]);

  useEffect(() => {
    if (lastMessage == null) {
      return;
    }
    const data = JSON.parse(lastMessage.data);
    if (data != null) {
      if (data.userId == userId) {
        if (data.from == 'supportsMessages') {
          fetchSupport();
        }
      }
    }
  }, [fetchSupport, lastMessage]);

  return (
    <>
      <Row className="my-3 p-3">
        <Col xxl={3}>
          <h2 className="app-text-primary app-font-bold">{support?.title}</h2>
          <Card className="my-5">
            <Flex
              className="p-3 border-bottom gap-2 fs--1"
              justifyContent={'between'}
              alignItems={'center'}
              wrap={'wrap'}
            >
              <span>Statut</span>
              {support.status == 'open' && (
                <SoftBadge pill bg={'info'}>
                  Ouvert
                </SoftBadge>
              )}
              {support.status == 'closed' && (
                <SoftBadge pill bg={'success'}>
                  Fermé
                </SoftBadge>
              )}
            </Flex>
            <Flex
              className="p-3 border-bottom gap-2 fs--1"
              justifyContent={'between'}
              alignItems={'center'}
              wrap={'wrap'}
            >
              <span>Ticket</span>
              <span>{support?.id}</span>
            </Flex>
            <Flex
              className="p-3 border-bottom gap-2 fs--1"
              justifyContent={'between'}
              alignItems={'center'}
              wrap={'wrap'}
            >
              <span>Créé</span>
              <span>{new Date(support?.createdAt).toLocaleString()}</span>
            </Flex>
            <Flex
              className="p-3 gap-2 fs--1"
              justifyContent={'between'}
              alignItems={'center'}
              wrap={'wrap'}
            >
              <span>Mis à jour</span>
              <span>{new Date(support?.updatedAt).toLocaleString()}</span>
            </Flex>
          </Card>
        </Col>
        <Col xxl={9}>
          <p className="app-text-primary app-font-bold text-uppercase fs-1">
            Description
          </p>
          <p style={{ maxWidth: '100%', width: '600px' }}>
            Veuillez détailler au maximum le problème que avez rencontrez afin
            qu'on puisse vous répondre de façon pertinente.
          </p>
          <Card
            style={{ maxHeight: '700px' }}
            className="d-flex flex-column-reverse my-3 px-4 py-3 overflow-auto"
          >
            {support?.messages?.map(message => (
              <Row className="my-3" key={message.id}>
                <Flex className="gap-4" alignItems={'center'}>
                  <div className="app-bg-body-color border rounded-circle p-2">
                    <User3FillIcon />
                  </div>
                  <Flex className="gap-1" direction={'column'}>
                    <span className="fw-bold">
                      {message.createdBy.firstname} {message.createdBy.lastname}
                    </span>
                    <span className="fs--1">{message.createdBy.role}</span>
                  </Flex>
                </Flex>
                <div className="mt-2">
                  <Card className="app-bg-body-color ms-5 p-3">
                    <p
                      className="mb-0"
                      style={{ whiteSpaceCollapse: 'preserve' }}
                    >
                      {message.message}
                    </p>
                  </Card>
                </div>
                <span className="w-auto ms-auto mt-1 fs--1 app-text-primary opacity-50">
                  {new Date(message.createdAt).toLocaleString()}
                </span>
              </Row>
            ))}
          </Card>
          <Form
            className="d-flex align-items-end flex-column gap-3 mb-3"
            onSubmit={handleFormSubmit}
          >
            <InputGroup>
              <Form.Control
                className="h-auto"
                as="textarea"
                placeholder="Ajouter un commentaire ici"
                name="message"
                rows="2"
                required={true}
              />
              <InputGroup.Text className="p-0 align-items-stretch">
                <Button variant="primary" type="submit">
                  <ArrowUpSLineIcon size={32} />
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default SupportDetails;
