import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { getColor } from 'helpers/utils';

const CustomInfoCard = ({ content, state, description, children }) => {
  if (!Array.isArray(description)) {
    description = [description];
  }
  let cardClasses = state && 'border-start border-5 border-' + state;
  let descriptionClasses = state && 'text-' + state;
  let icon = '';
  switch (state) {
    case 'success':
      icon = faCheckCircle;
      break;
    case 'warning':
      icon = faExclamationCircle;
      break;
    case 'danger':
      icon = faExclamationTriangle;
      break;
    case 'info':
      icon = faInfoCircle;
      break;
    default:
      break;
  }
  return (
    <>
      <Card className={'p-3 my-3 ' + cardClasses}>
        <p>{content}</p>
        {description.map((element, index) => (
          <Flex key={index} className="gap-3" alignItems={'center'}>
            {icon && <FontAwesomeIcon color={getColor(state)} icon={icon} />}
            <span className={'fs--1 fw-bold ' + descriptionClasses}>
              {element}
            </span>
          </Flex>
        ))}
        {children}
      </Card>
    </>
  );
};

CustomInfoCard.propTypes = {
  content: PropTypes.string,
  state: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  children: PropTypes.node
};

export default CustomInfoCard;
