import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddLineIcon from 'remixicon-react/AddLineIcon';
import AppContext from 'context/Context';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import CustomModal from '../common/CustomModal';
import Flex from 'components/common/Flex';
import { PropTypes } from 'prop-types';
import User3FillIcon from 'remixicon-react/User3FillIcon';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

const User = ({ user }) => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const { id, firstname, lastname, email, role, organization } = user;

  const deleteUser = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}users/${id}`,
      {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
    setDeleteModalShow(false);
  };

  return (
    <>
      <Col className="p-3" xl={6} xxl={3}>
        <Card className="h-100">
          <Card.Header className="border-bottom">
            <Row>
              <Col xs={3}>
                <Flex
                  className="w-100 h-100"
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <User3FillIcon size={50} />
                </Flex>
              </Col>
              <Col xs={7}>
                <Flex className="gap-2" direction={'column'}>
                  <span className="app-font-medium">
                    {lastname} {firstname}
                  </span>
                  <span>{role}</span>
                </Flex>
              </Col>
              <Col xs={2}>
                <a onClick={() => setDeleteModalShow(true)} href={'#'}>
                  <CloseCircleLineIcon />
                </a>
              </Col>
            </Row>
            <div className="mt-2">Organization&nbsp;: {organization?.name}</div>
          </Card.Header>
          <Card.Body>
            <div className="app-font-medium">{email}</div>
          </Card.Body>
        </Card>
      </Col>
      <CustomModal
        isShown={deleteModalShow}
        setIsShown={setDeleteModalShow}
        title="Supprimer l'utilisateur"
        description={
          <p>
            Êtes-vous sûr de vouloir supprimer l'utilisateur séléctionné&nbsp;?
            <br />
            Prénom&nbsp;: <strong>{firstname}</strong>
            <br />
            Nom&nbsp;: <strong>{lastname}</strong>
            <br />
            Email&nbsp;: <strong>{email}</strong>
          </p>
        }
        content={
          <Button
            className="app-modal-submit-button"
            variant="danger"
            onClick={() => deleteUser()}
          >
            Supprimer
          </Button>
        }
      />
    </>
  );
};

User.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    organization: PropTypes.object
  })
};

const Header = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);

  const handleFormSubmit = async e => {
    e.preventDefault();
    setModalShow(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}users`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        firstname: e.target.firstname.value,
        lastname: e.target.lastname.value,
        email: e.target.email.value
      })
    });
    const json = await response.json();
    e.target.reset();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  return (
    <>
      <div className="app-list-header">
        <h2 className="app-list-title">Liste des utilisateurs</h2>
        <Button className="app-list-button" onClick={() => setModalShow(true)}>
          <Flex className="gap-1" alignItems={'center'}>
            <AddLineIcon className="app-list-button-icon" />
            <span>Ajouter un nouvel utilisateur</span>
          </Flex>
        </Button>
      </div>
      <CustomModal
        isShown={modalShow}
        setIsShown={setModalShow}
        title="Ajouter un nouvel utilisateur"
        content={
          <Form className="app-modal-form" onSubmit={handleFormSubmit}>
            <Form.Label htmlFor="modalFirstname">Prénom</Form.Label>
            <Form.Control
              id="modalFirstname"
              name="firstname"
              type="text"
              required={true}
            />
            <Form.Label htmlFor="modalLastname">Nom</Form.Label>
            <Form.Control
              id="modalLastname"
              name="lastname"
              type="text"
              required={true}
            />
            <Form.Label htmlFor="modalEmail">Email</Form.Label>
            <Form.Control
              id="modalEmail"
              name="email"
              type="email"
              required={true}
            />
            <Button className="app-modal-submit-button" type="submit">
              Soumettre
            </Button>
          </Form>
        }
      />
    </>
  );
};

const List = () => {
  const { lastMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    shouldReconnect: () => true
  });
  const {
    config: { userId, token }
  } = useContext(AppContext);
  const [isFetchFinished, setIsFetchFinished] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchUsers = useCallback(async () => {
    const usersResponse = await fetch(`${process.env.REACT_APP_API_URL}users`, {
      headers: { Authorization: 'Bearer ' + token }
    });
    const json = await usersResponse.json();
    setUsers(json.users);
    setIsFetchFinished(true);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (lastMessage == null) {
      return;
    }
    const data = JSON.parse(lastMessage.data);
    if (data != null) {
      if (data.userId == userId) {
        if (data.from == 'users') {
          fetchUsers();
        }
      }
    }
  }, [fetchUsers, lastMessage]);

  return (
    <>
      <div className="my-3 p-3">
        {isFetchFinished ? (
          users?.length == 0 ? (
            <Flex justifyContent={'center'}>
              <p className="text-center fw-bold my-3">
                Aucun utilisateur trouvé
              </p>
            </Flex>
          ) : (
            <Row>
              {users?.map(
                user => user.id != userId && <User user={user} key={user.id} />
              )}
            </Row>
          )
        ) : (
          <Flex justifyContent={'center'}>
            <Spinner className="my-3" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Flex>
        )}
      </div>
    </>
  );
};

const UserList = () => {
  return (
    <>
      <Header />
      <List />
    </>
  );
};

export default UserList;
