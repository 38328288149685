import LanguageDropdown from './LanguageDropdown';
import { Nav } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import QuestionLineIcon from 'remixicon-react/QuestionLineIcon';
import React from 'react';

// import MoonFillIcon from 'remixicon-react/MoonFillIcon';
// import SunLineIcon from 'remixicon-react/SunLineIcon';
// import NotificationDropdown from 'components/navbar/top/NotificationDropdown';

const TopNavRightSideNavItem = () => {
  // const {
  //   config: { isDark, isRTL },
  //   setConfig
  // } = useContext(AppContext);
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/* <Nav.Item as={'li'}>
        <Nav.Link
          className="theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <div className="theme-control-toggle-label">
            {isDark ? <SunLineIcon /> : <MoonFillIcon />}
          </div>
        </Nav.Link>
      </Nav.Item> */}

      {/* <NotificationDropdown /> */}

      <Nav.Item as={'li'}>
        <Nav.Link>
          <QuestionLineIcon />
        </Nav.Link>
      </Nav.Item>

      <LanguageDropdown />

      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
