import React, { useCallback, useContext } from 'react';

import AppContext from 'context/Context';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const CustomLanguagesSingleSelect = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);

  let languagesOptions = [];
  const fetchLanguages = useCallback(async () => {
    const languagesResponse = await fetch(
      `${process.env.REACT_APP_API_URL}languages`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const data = await languagesResponse.json();
    data.map(item => {
      languagesOptions.push({
        value: item[1],
        label: item[0]
      });
    });
  });

  fetchLanguages();

  return (
    <>
      <Form.Label htmlFor="modalLanguage">{t('Language')}</Form.Label>
      <Select
        id="modalLanguage"
        name="language"
        className="mb-3"
        closeMenuOnSelect={true}
        options={languagesOptions}
        placeholder={t('Select')}
        classNamePrefix="react-select"
        defaultValue={{ value: 'fr', label: 'French' }}
      />
    </>
  );
};

export default CustomLanguagesSingleSelect;
