export const socialBuzzData = {
  title: 'Social Buzz',
  description:
    "Les backlinks sont importants pour le référencement web, cependant les moteurs de recherche prennent en compte de plus en plus l'impact du Social Buzz (mention, positif / négatif, réseaux sociaux, avis, etc.) dans le calcul de la notoriété d'un site web. Le Social Buzz vous permet d'avoir une analyse de votre impact sur le web de façon globale.",
  overview: {
    title: 'Analyse du Social Buzz',
    description:
      "Le Social Buzz vous permet d'avoir une analyse de votre impact sur le web de façon globale. Ci-dessous vous pouvez visualiser le sentiment et les mentions contenant votre nom ou votre URL. Vous pouvez visualiser en détail chaque mention ainsi que les avis Google ou encore les réseaux sociaux prédéfinis."
  },
  data: [
    {
      id: 1,
      date: '11-02-2022 16:02',
      keywords: 'AGN, AVOCATS, AGNAVOCATS'
    }
  ]
};
