import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import { PropTypes } from 'prop-types';
import React from 'react';

const BacklinkDetailsReferringDomain = ({ data }) => {
  const referringDomainColumns = [
    {
      accessor: 'domain',
      Header: 'Domaine'
    },
    {
      accessor: 'linkQuantity',
      Header: 'Nombre de Liens'
    },
    {
      accessor: 'spam',
      Header: 'Évaluation du Spam'
    },
    {
      accessor: 'di',
      Header: 'DI'
    },
    {
      accessor: 'pi',
      Header: 'PI'
    }
  ];
  const referringDomainData = [];
  data?.referringDomains?.map(referringDomain =>
    referringDomainData.push({
      domain: referringDomain?.domain,
      linkQuantity: referringDomain?.linksCount,
      spam: referringDomain?.spamScore,
      di: referringDomain?.domainInfluence,
      pi: referringDomain?.pageInfluence
    })
  );

  return (
    <>
      <div className="mt-3">
        <CustomAdvanceTable
          columns={referringDomainColumns}
          data={referringDomainData}
        />
      </div>
    </>
  );
};

BacklinkDetailsReferringDomain.propTypes = {
  data: PropTypes.object
};

export default BacklinkDetailsReferringDomain;
