import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import React, { useContext, useState } from 'react';

import AppContext from 'context/Context';
import { Buffer } from 'buffer';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Logo from 'components/common/Logo';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  const { setConfig } = useContext(AppContext);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
      method: 'POST',
      headers: {
        Authorization:
          'Basic ' +
          Buffer.from(
            `${e.target.email.value}:${e.target.password.value}`
          ).toString('base64')
      }
    });
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    }
    if (!json.enabled) {
      window.location.replace(
        `${json.organization.plan.url}?prefilled_email=${e.target.email.value}`
      );
    }
    if (json.enabled && json.accessToken) {
      setConfig('userId', json.id);
      setConfig('userRole', json.role);
      setConfig('token', json.accessToken);
      toast.success(t('Logged in'), toastOptions);
    }
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col xl={6}>
            <Flex
              justifyContent="center"
              direction="column"
              className="min-vh-100"
            >
              <Logo at={'login-page'} />
              <Form onSubmit={handleSubmit}>
                <Form.Label htmlFor="loginEmail">{t('Email')}</Form.Label>
                <Form.Control
                  id="loginEmail"
                  name="email"
                  type="email"
                  required={true}
                  className="mb-3"
                />

                <Form.Label htmlFor="loginPassword">{t('Password')}</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="loginPassword"
                    name="password"
                    type={isPasswordShown ? 'text' : 'password'}
                    required={true}
                  />
                  <InputGroup.Text
                    className="cursor-pointer"
                    onClick={() => setIsPasswordShown(!isPasswordShown)}
                  >
                    {isPasswordShown ? <EyeOffLineIcon /> : <EyeLineIcon />}
                  </InputGroup.Text>
                </InputGroup>

                <Form.Group>
                  <Button type="submit" color="primary" className="mt-3 w-100">
                    {t('Login')}
                  </Button>
                </Form.Group>
              </Form>
              <Link to={'/forgot-password'} className="mt-3 text-center">
                {t('Forgot password ?')}
              </Link>
            </Flex>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
