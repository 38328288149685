export const positionTrackingData = {
  title: 'Suivi de Position',
  description:
    "Grâce au suivi de positionnement de vos mots clés, vous serez alertés lorsque vos concurrents effectue un boost de référencement. Vous obtiendrez une idée sur l'avancement de votre référencement et serez capables de maîtriser votre visibilité organique sur les moteurs de recherche.",
  overview: {
    title: 'Détail du suivi de position',
    description:
      "L'analyse du suivi de position vous permet de comprendre la progression de vos concurrents et votre visibilité organique. Vous pouvez visualiser par mot clés votre progression et celle de vos concurrents."
  },
  data: [
    {
      id: 1,
      date: '11-02-2022 16:02',
      url: 'last test',
      competitor: 'https://www.montpellier-management.fr/',
      keywords: 2,
      average: 51
    }
  ]
};
