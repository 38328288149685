import { Button, Card } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import { List } from '../site-analysis/SiteAnalysisList';
import React from 'react';

const SiteAnalysis = () => {
  return (
    <>
      <Card className="app-dashboard-block h-100">
        <Card.Header>
          <h2 className="app-card-title">Analyse crawl</h2>
          <p className="app-card-description">
            Le crawl vous permet de faire une analyse de votre site web depuis
            une URL. Le Crawl va alors analyser un groupe de pages, situer les
            problèmes pertinents afin de vous faciliter la tâche de les
            regrouper et donc de les corriger plus rapidement. A vous de
            simplement préciser le nombre de pages à « crawler », et l'analyse
            vous permettra d'examiner l'architecture de votre site de plus près,
            afin de situer les failles éventuellement liées à vos URLs.
          </p>
        </Card.Header>
        <Card.Body>
          <List maxResults={5} isSummary={true} />
        </Card.Body>
        <Card.Footer>
          <Flex justifyContent={'end'} alignItems={'center'}>
            <Button href="site-analysis" variant="light">
              Voir tout
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </>
  );
};

export default SiteAnalysis;
