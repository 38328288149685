import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import logo from 'assets/img/anopseo/logo.svg';

const Logo = ({ at, className, textClass, ...rest }) => {
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' },
        { 'mb-6 mx-auto': at === 'login-page' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          { 'align-items-center': at === 'navbar-vertical' },
          { 'align-items-center': at === 'navbar-top' },
          { 'flex-center fw-bolder fs-5 mb-4': at === 'auth' },
          { 'flex-center': at === 'login-page' },
          className
        )}
      >
        {at === 'navbar-top' && (
          <>
            <img src={logo} alt="Logo Anopseo" />
            <span
              className={classNames('font-sans-serif', textClass)}
              style={{ display: 'none' }}
            >
              AnopSeo | Plateforme de prédiction de tendances SEO
            </span>
          </>
        )}
        {at === 'navbar-vertical' && (
          <>
            <h1>
              <div style={{ display: 'none' }}>
                AnopSeo | Plateforme de prédiction de tendances SEO
              </div>
              <img src={logo} alt="Logo Anopseo" />
            </h1>
          </>
        )}
        {at === 'login-page' && <img src={logo} alt="Logo Anopseo" />}
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth', 'login-page']),
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
