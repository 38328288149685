import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import { PropTypes } from 'prop-types';
import React from 'react';

const BacklinkDetailsTargetPage = ({ data }) => {
  const targetPageColumns = [
    {
      accessor: 'url',
      Header: 'URL'
    },
    {
      accessor: 'linkQuantity',
      Header: 'Nombre de Liens'
    }
  ];
  const targetPageData = [];
  data?.targets?.map(targetPage =>
    targetPageData.push({
      url: targetPage?.urlTo,
      linkQuantity: targetPage?.linksCount
    })
  );

  return (
    <>
      <div className="mt-3">
        <CustomAdvanceTable columns={targetPageColumns} data={targetPageData} />
      </div>
    </>
  );
};

BacklinkDetailsTargetPage.propTypes = {
  data: PropTypes.object
};

export default BacklinkDetailsTargetPage;
