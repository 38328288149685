import { Col, Image, Row } from 'react-bootstrap';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import CustomInfoCard from 'components/anopseo/common/CustomInfoCard';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PageAnalysisDetailsTechnique = ({ title, description, data }) => {
  const { t } = useTranslation();

  const framesAndIframesColumns = [
    {
      accessor: 'element',
      Header: 'Élément'
    }
  ];
  const framesAndIframesData = [];
  data?.technique?.frames_and_iframes?.items?.map(item =>
    framesAndIframesData.push({ element: item })
  );
  const flashContentColumns = [
    {
      accessor: 'element',
      Header: 'Élément'
    }
  ];
  const flashContentData = [];
  data?.technique?.flash_content?.items?.map(item =>
    flashContentData.push({ element: item })
  );
  const emailsColumns = [
    {
      accessor: 'element',
      Header: 'Élément'
    }
  ];
  const emailsData = [];
  data?.technique?.emails?.items?.map(item =>
    emailsData.push({ element: item })
  );

  return (
    <>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <Flex alignItems={'center'} direction={'column'}>
              <Image
                className="img-fluid"
                src={data?.overview?.screenshotUrl}
              />
              <a
                href={data?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-center"
              >
                {data?.url}
              </a>
            </Flex>
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{title}</h2>
              <p>{description}</p>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Normalisation</h3>
        <CustomInfoCard
          content={data?.technique?.doctype?.title}
          state={data?.technique?.doctype?.status}
          description={data?.technique?.doctype?.message}
        />
        <CustomInfoCard
          content={data?.technique?.encoding?.title}
          state={data?.technique?.encoding?.status}
          description={data?.technique?.encoding?.message}
        />
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Qualité de la mise en page</h3>
        <CustomInfoCard
          content={data?.technique?.html_in_comment?.title}
          state={data?.technique?.html_in_comment?.status}
          description={data?.technique?.html_in_comment?.message}
        >
          <div className="mt-3">
            {data?.technique?.html_in_comments?.items?.length > 0 ? (
              <p className="mb-0" style={{ whiteSpace: 'pre-line' }}>
                {data?.technique?.html_in_comment?.items}
              </p>
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.technique?.frames_and_iframes?.title}
          state={data?.technique?.frames_and_iframes?.status}
          description={data?.technique?.frames_and_iframes?.message}
        >
          <div className="mt-3">
            {framesAndIframesData?.length > 0 ? (
              <CustomAdvanceTable
                columns={framesAndIframesColumns}
                data={framesAndIframesData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.technique?.flash_content?.title}
          state={data?.technique?.flash_content?.status}
          description={data?.technique?.flash_content?.message}
        >
          <div className="mt-3">
            {flashContentData?.length > 0 ? (
              <CustomAdvanceTable
                columns={flashContentColumns}
                data={flashContentData}
              />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Configuration du Serveur</h3>
        <CustomInfoCard
          content={data?.technique?.web_server?.title}
          state={data?.technique?.web_server?.status}
          description={data?.technique?.web_server?.message}
        >
          <div className="mt-3">
            <p className="mb-0">
              <strong>Serveur&nbsp;: </strong>
              {data?.technique?.web_server?.display_value ?? '-'}
            </p>
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.technique?.ipv6?.title}
          state={data?.technique?.ipv6?.status}
          description={data?.technique?.ipv6?.message}
        />
        <CustomInfoCard
          content={data?.technique?.dns_infos?.title}
          state={data?.technique?.dns_infos?.status}
          description={data?.technique?.dns_infos?.message}
        >
          <div className="mt-3">
            <p>
              <strong>IP&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.ip ?? '-'}
            </p>
            <p>
              <strong>Nom de domaine&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.domain_name ?? '-'}
            </p>
            <p>
              <strong>DNSSEC&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.dnssec?.length > 0
                ? data?.technique?.dns_infos?.items?.dnssec?.map(
                    (item, index, array) => (
                      <span
                        key={`pageAnalysisDetailsTechniqueDnsInfosDnssec${index}`}
                      >
                        {item}
                        {index != array.length - 1 ? ', ' : ''}
                      </span>
                    )
                  )
                : '-'}
            </p>
            <p>
              <strong>Registraire&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.registrar ?? '-'}
            </p>
            <p>
              <strong>Date de création&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.creation_date != null
                ? new Date(
                    data?.technique?.dns_infos?.items?.creation_date
                  ).toLocaleString()
                : '-'}
            </p>
            <p>
              <strong>Date d'expiration&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.expiration_date != null
                ? new Date(
                    data?.technique?.dns_infos?.items?.expiration_date
                  ).toLocaleString()
                : '-'}
            </p>
            <p>
              <strong>Nom&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.name ?? '-'}
            </p>
            <p>
              <strong>Organisation&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.org ?? '-'}
            </p>
            <p>
              <strong>Adresse&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.address ?? '-'}
            </p>
            <p>
              <strong>Ville&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.city ?? '-'}
            </p>
            <p>
              <strong>État&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.state ?? '-'}
            </p>
            <p>
              <strong>Code postal du registrant&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.registrant_postal_code ?? '-'}
            </p>
            <p className="mb-0">
              <strong>Pays&nbsp;: </strong>
              {data?.technique?.dns_infos?.items?.country ?? '-'}
            </p>
          </div>
        </CustomInfoCard>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Sécurité</h3>
        <CustomInfoCard
          content={data?.technique?.emails?.title}
          state={data?.technique?.emails?.status}
          description={data?.technique?.emails?.message}
        >
          <div className="mt-3">
            {emailsData?.length > 0 ? (
              <CustomAdvanceTable columns={emailsColumns} data={emailsData} />
            ) : (
              <p className="mb-0">
                <strong>{t('No result found')}</strong>
              </p>
            )}
          </div>
        </CustomInfoCard>
        <CustomInfoCard
          content={data?.technique?.xss_protection?.title}
          state={data?.technique?.xss_protection?.status}
          description={data?.technique?.xss_protection?.message}
        />
        <CustomInfoCard
          content={data?.technique?.content_security_policy?.title}
          state={data?.technique?.content_security_policy?.status}
          description={data?.technique?.content_security_policy?.message}
        />
        <CustomInfoCard
          content={data?.technique?.certificate?.title}
          state={data?.technique?.certificate?.status}
          description={data?.technique?.certificate?.message}
        >
          <div className="mt-3">
            {data?.technique?.certificate?.items?.certificate_infos?.details !=
            null ? (
              <>
                <h4 className="fs-1 fw-normal">
                  Informations à propos du certificat
                </h4>
                <h5 className="fs-1 fw-light ms-2 mt-3">- Émetteur</h5>
                {data?.technique?.certificate?.items?.certificate_infos?.details?.issuer?.map(
                  (detail, index) => (
                    <p
                      key={`pageAnalysisDetailsTechniqueCertificateInfosIssuer${index}`}
                      className="ms-4"
                    >
                      <strong>{detail[0][0]} : </strong>
                      {detail[0][1]}
                    </p>
                  )
                )}
                <h5 className="fs-1 fw-light ms-2 mt-3">- Sujet</h5>
                {data?.technique?.certificate?.items?.certificate_infos?.details?.subject?.map(
                  (detail, index) => (
                    <p
                      key={`pageAnalysisDetailsTechniqueCertificateInfosSubject${index}`}
                      className="ms-4"
                    >
                      <strong>{detail[0][0]} : </strong>
                      {detail[0][1]}
                    </p>
                  )
                )}
                <p>
                  <strong>Version : </strong>
                  {data?.technique?.certificate?.items?.certificate_infos
                    ?.details?.version ?? t('No result found')}
                </p>
                <p className="mb-0">
                  <strong>Date d'expiration : </strong>
                  {data?.technique?.certificate?.items?.certificate_infos
                    ?.details?.expiration_date != null
                    ? new Date(
                        data?.technique?.certificate?.items?.certificate_infos?.details?.expiration_date
                      ).toLocaleString()
                    : t('No result found')}
                </p>
              </>
            ) : (
              t('No result found')
            )}
          </div>
        </CustomInfoCard>
      </div>
    </>
  );
};

PageAnalysisDetailsTechnique.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default PageAnalysisDetailsTechnique;
