import { Button, Card } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import { List } from '../social/SocialList';
import React from 'react';

const Social = () => {
  return (
    <>
      <Card className="app-dashboard-block h-100">
        <Card.Header>
          <h2 className="app-card-title">Social buzz</h2>
          <p className="app-card-description">
            Les backlinks sont importants pour le référencement web, cependant
            les moteurs de recherche prennent en compte de plus en plus l'impact
            du Social Buzz (mention, positif / négatif, réseaux sociaux, avis,
            etc.) dans le calcul de la notoriété d'un site web. Le Social Buzz
            vous permet d'avoir une analyse de votre impact sur le web de façon
            globale.
          </p>
        </Card.Header>
        <Card.Body>
          <List maxResults={5} isSummary={true} />
        </Card.Body>
        <Card.Footer>
          <Flex justifyContent={'end'} alignItems={'center'}>
            <Button href="social" variant="light">
              Voir tout
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </>
  );
};

export default Social;
