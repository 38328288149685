import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useParams } from 'react-router-dom';

const WriterDetails = () => {
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    const backlinkResponse = await fetch(
      `${process.env.REACT_APP_API_URL}writers/${params.id}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await backlinkResponse.json();
    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Card className="my-3 p-3">
        <Flex className="my-3 p-3 gap-2" direction={'column'}>
          <h2>Résultat généré par le writer</h2>
          <div>URL&nbsp;: {data?.url}</div>
          <div>Mots-clés&nbsp;: {data?.keywords?.join(', ')}</div>
        </Flex>
        <hr className="text-200 dark__text-900" />
        <div
          className="my-3 p-3 writer-content"
          dangerouslySetInnerHTML={{ __html: data?.content }}
        ></div>
      </Card>
    </>
  );
};

export default WriterDetails;
