import * as echarts from 'echarts';

import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import BacklinkDetailsAnchorText from './details/BacklinkDetailsAnchorText';
import BacklinkDetailsBacklink from './details/BacklinkDetailsBacklink';
import BacklinkDetailsOverview from './details/BacklinkDetailsOverview';
import BacklinkDetailsReferringDomain from './details/BacklinkDetailsReferringDomain';
import BacklinkDetailsReferringIp from './details/BacklinkDetailsReferringIp';
import BacklinkDetailsTargetPage from './details/BacklinkDetailsTargetPage';
import EChartsReactCore from 'echarts-for-react/lib/core';
import Flex from 'components/common/Flex';
import { backlinkCheckData } from 'data/anopseo/backlink';
import { getColor } from 'helpers/utils';
import { useParams } from 'react-router-dom';

const BacklinkDetails = () => {
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    const backlinkResponse = await fetch(
      `${process.env.REACT_APP_API_URL}backlinks/${params.id}`,
      {
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    const json = await backlinkResponse.json();
    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const followedLinkOption = {
    series: [
      {
        type: 'pie',
        radius: ['40%', '80%'],
        label: {
          show: false,
          position: 'center'
        },
        data: [
          {
            value: data?.followedLinksCount,
            name: 'Suivi',
            itemStyle: {
              color: getColor('success')
            }
          },
          {
            value: data?.notFollowedLinksCount,
            name: 'Non suivi',
            itemStyle: {
              color: getColor('danger')
            }
          }
        ]
      }
    ],
    legend: {
      bottom: 'bottom'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  return (
    <>
      <Card className="p-3">
        <div className="p-3 my-3">
          <Row>
            <Col xl={3}>
              <EChartsReactCore echarts={echarts} option={followedLinkOption} />
            </Col>
            <Col className="border-start" xl={9}>
              <Flex className="p-2 gap-2" direction={'column'}>
                <h2>{backlinkCheckData.overview.title}</h2>
                <p>{backlinkCheckData.overview.description}</p>
                <Card className="p-3">
                  <Row>
                    <Col className="text-center" xl={6}>
                      <p className="fw-bold">Liens suivis</p>
                      <p>{data?.followedLinksCount}</p>
                    </Col>
                    <Col className="text-center" xl={6}>
                      <p className="fw-bold">Liens Non Suivis</p>
                      <p>{data?.notFollowedLinksCount}</p>
                    </Col>
                    <Col className="text-center" xl={6}>
                      <p className="fw-bold">Ips Référents</p>
                      <p>{data?.referringIpsCount}</p>
                    </Col>
                    <Col className="text-center" xl={6}>
                      <p className="fw-bold">Domaines Référents</p>
                      <p>{data?.referringDomainsCount}</p>
                    </Col>
                  </Row>
                </Card>
              </Flex>
            </Col>
          </Row>
        </div>
        <div className="p-3 my-3">
          <Tabs variant="pills" defaultActiveKey="overview">
            <Tab eventKey="overview" title="VUE D'ENSEMBLE">
              <BacklinkDetailsOverview data={data} />
            </Tab>
            <Tab eventKey="backlink" title="BACKLINKS">
              <BacklinkDetailsBacklink data={data} />
            </Tab>
            <Tab eventKey="referringDomain" title="DOMAINES RÉFÉRENTS">
              <BacklinkDetailsReferringDomain data={data} />
            </Tab>
            <Tab eventKey="referringIp" title="IPS RÉFÉRENTS">
              <BacklinkDetailsReferringIp data={data} />
            </Tab>
            <Tab eventKey="anchorText" title="TEXTE D'ANCRAGE">
              <BacklinkDetailsAnchorText data={data} />
            </Tab>
            <Tab eventKey="targetPage" title="PAGES CIBLES">
              <BacklinkDetailsTargetPage data={data} />
            </Tab>
          </Tabs>
        </div>
      </Card>
    </>
  );
};

export default BacklinkDetails;
