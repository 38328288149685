import { Button, Card } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import { List } from '../tracking/TrackingList';
import React from 'react';

const Tracking = () => {
  return (
    <>
      <Card className="app-dashboard-block h-100">
        <Card.Header>
          <h2 className="app-card-title">Suivi de position</h2>
          <p className="app-card-description">
            Grâce au suivi de positionnement de vos mots clés, vous serez
            alertés lorsque vos concurrents effectue un boost de référencement.
            Vous obtiendrez une idée sur l'avancement de votre référencement et
            serez capables de maîtriser votre visibilité organique sur les
            moteurs de recherche.
          </p>
        </Card.Header>
        <Card.Body>
          <List maxResults={5} isSummary={true} />
        </Card.Body>
        <Card.Footer>
          <Flex justifyContent={'end'} alignItems={'center'}>
            <Button href="tracking" variant="light">
              Voir tout
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </>
  );
};

export default Tracking;
