import * as echarts from 'echarts';

import { Col, Image, Row } from 'react-bootstrap';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import CustomInfoCard from 'components/anopseo/common/CustomInfoCard';
import EChartsReactCore from 'echarts-for-react/lib/core';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { getColor } from 'helpers/utils';

const PageAnalysisDetailsContent = ({ title, description, data }) => {
  const contentRatioColor =
    parseFloat(data?.content?.content_ratio?.display_value) > 30
      ? parseFloat(data?.content?.content_ratio?.display_value) > 70
        ? 'danger'
        : 'warning'
      : 'success';
  const keywordQtyColor =
    parseInt(data?.content?.keyword_qty?.display_value) > 167
      ? parseInt(data?.content?.keyword_qty?.display_value) > 333
        ? 'danger'
        : 'warning'
      : 'success';
  const contentRatioOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        max: 100,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(contentRatioColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: parseFloat(data?.content?.content_ratio?.display_value),
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ]
      }
    ]
  };
  const keywordQtyOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        max: 500,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(keywordQtyColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: parseInt(data?.content?.keyword_qty?.display_value),
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ]
      }
    ]
  };

  const headingsOption = {
    series: [
      {
        type: 'bar',
        data: [
          data?.content?.headings?.items?.h6?.length,
          data?.content?.headings?.items?.h5?.length,
          data?.content?.headings?.items?.h4?.length,
          data?.content?.headings?.items?.h3?.length,
          data?.content?.headings?.items?.h2?.length,
          data?.content?.headings?.items?.h1?.length
        ]
      }
    ],
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: ['h6', 'h5', 'h4', 'h3', 'h2', 'h1']
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const headingsColumns = [
    {
      accessor: 'type',
      Header: 'Type'
    },
    {
      accessor: 'content',
      Header: 'Contenu'
    }
  ];
  const headingsData = [];
  data?.content?.headings?.items?.h1?.map(h1 => {
    headingsData.push({
      type: '<h1>',
      content: h1
    });
  });
  data?.content?.headings?.items?.h2?.map(h2 => {
    headingsData.push({
      type: '<h2>',
      content: h2
    });
  });
  data?.content?.headings?.items?.h3?.map(h3 => {
    headingsData.push({
      type: '<h3>',
      content: h3
    });
  });
  data?.content?.headings?.items?.h4?.map(h4 => {
    headingsData.push({
      type: '<h4>',
      content: h4
    });
  });
  data?.content?.headings?.items?.h5?.map(h5 => {
    headingsData.push({
      type: '<h5>',
      content: h5
    });
  });
  data?.content?.headings?.items?.h6?.map(h6 => {
    headingsData.push({
      type: '<h6>',
      content: h6
    });
  });

  return (
    <>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <Flex alignItems={'center'} direction={'column'}>
              <Image
                className="img-fluid"
                src={data?.overview?.screenshotUrl}
              />
              <a
                href={data?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-center"
              >
                {data?.url}
              </a>
            </Flex>
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{title}</h2>
              <p>{description}</p>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Contenu et Mots-Clés</h3>
        <Row>
          <Col xl={6}>
            <CustomInfoCard
              content={data?.content?.content_ratio?.title}
              state={data?.content?.content_ratio?.status}
              description={data?.content?.content_ratio?.message}
            >
              <EChartsReactCore echarts={echarts} option={contentRatioOption} />
            </CustomInfoCard>
          </Col>
          <Col xl={6}>
            <CustomInfoCard
              content={data?.content?.keyword_qty?.title}
              state={data?.content?.keyword_qty?.status}
              description={data?.content?.keyword_qty?.message}
            >
              <EChartsReactCore echarts={echarts} option={keywordQtyOption} />
            </CustomInfoCard>
          </Col>
        </Row>
      </div>
      <div className="p-3 my-3">
        <h3 className="fs-1">Sémantique</h3>
        <CustomInfoCard
          content={data?.content?.headings?.title}
          state={data?.content?.headings?.status}
          description={data?.content?.headings?.message}
        >
          <EChartsReactCore echarts={echarts} option={headingsOption} />
          <CustomAdvanceTable columns={headingsColumns} data={headingsData} />
        </CustomInfoCard>
      </div>
      <div className="p-3 my-3">
        <CustomInfoCard
          content={data?.content?.microdata?.title}
          state={data?.content?.microdata?.status}
          description={data?.content?.microdata?.message}
        />
      </div>
    </>
  );
};

PageAnalysisDetailsContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default PageAnalysisDetailsContent;
