import * as echarts from 'echarts';

import { Col, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import {
  faDailymotion,
  faFacebook,
  faFlickr,
  faInstagram,
  faReddit,
  faTumblr,
  faTwitter,
  faVimeo,
  faVk,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import EChartsReactCore from 'echarts-for-react/lib/core';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React from 'react';
import SoftBadge from 'components/common/SoftBadge';
import { aiReportData } from 'data/anopseo/report';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { getColor } from 'helpers/utils';

const ReportDetailsSocial = ({ data }) => {
  const lastCrawl = data?.siteAnalyzes?.slice(-1)[0];

  const socialSummaryDates = [];
  const socialSummaryResultsCount = [];
  const socialSummaryPositivesCount = [];
  const socialSummaryNeutralsCount = [];
  const socialSummaryNegativesCount = [];
  data?.socials?.map(item => {
    socialSummaryDates.push(new Date(item.createdAt).toLocaleDateString());
    socialSummaryResultsCount.push(
      item.positivesCount + item.neutralsCount + item.negativesCount
    );
    socialSummaryPositivesCount.push(item.positivesCount);
    socialSummaryNeutralsCount.push(item.neutralsCount);
    socialSummaryNegativesCount.push(item.negativesCount);
  });

  const issuesColor =
    lastCrawl?.issuesCount < 10
      ? lastCrawl?.issuesCount < 5
        ? 'primary'
        : 'warning'
      : 'danger';

  const socialSummaryChartOption = {
    series: [
      {
        name: 'Résultats',
        type: 'line',
        data: socialSummaryResultsCount,
        symbolSize: 10
      },
      {
        name: 'Neutre',
        type: 'line',
        data: socialSummaryNeutralsCount,
        symbolSize: 10
      },
      {
        name: 'Positif',
        type: 'line',
        data: socialSummaryPositivesCount,
        symbolSize: 10
      },
      {
        name: 'Négatif',
        type: 'line',
        data: socialSummaryNegativesCount,
        symbolSize: 10
      }
    ],
    legend: {},
    color: [
      getColor('primary'),
      getColor('secondary'),
      getColor('success'),
      getColor('danger')
    ],
    xAxis: {
      type: 'category',
      data: socialSummaryDates
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const socialColumns = [
    {
      accessor: 'date',
      Header: 'Date'
    },
    {
      accessor: 'networks',
      Header: 'Réseaux'
    },
    {
      accessor: 'results',
      Header: 'Résultats'
    },
    {
      accessor: 'neutrals',
      Header: 'Neutres'
    },
    {
      accessor: 'positives',
      Header: 'Positifs'
    },
    {
      accessor: 'negatives',
      Header: 'Négatifs'
    },
    {
      accessor: 'actions',
      Header: 'Actions'
    }
  ];
  const socialData = [];
  data?.socials?.map(item => {
    let networkIcons = [];
    item.networks.forEach(network => {
      let icon = null;
      switch (network) {
        case 'web':
          icon = faGlobe;
          break;
        case 'facebook':
          icon = faFacebook;
          break;
        case 'twitter':
          icon = faTwitter;
          break;
        case 'youtube':
          icon = faYoutube;
          break;
        case 'instagram':
          icon = faInstagram;
          break;
        case 'tumblr':
          icon = faTumblr;
          break;
        case 'reddit':
          icon = faReddit;
          break;
        case 'flickr':
          icon = faFlickr;
          break;
        case 'dailymotion':
          icon = faDailymotion;
          break;
        case 'vimeo':
          icon = faVimeo;
          break;
        case 'vkontakte':
          icon = faVk;
          break;

        default:
          break;
      }
      networkIcons.push(icon);
    });
    socialData.push({
      date: new Date(item.createdAt).toLocaleDateString(),
      networks: (
        <Flex style={{ whiteSpace: 'nowrap' }} className="h-100 gap-2">
          {networkIcons.map(
            (networkIcon, index) =>
              networkIcon && (
                <FontAwesomeIcon
                  key={`networkIcon${index}`}
                  icon={networkIcon}
                />
              )
          )}
        </Flex>
      ),
      results: (
        <SoftBadge pill bg={'primary'}>
          {item.positivesCount + item.neutralsCount + item.negativesCount}
        </SoftBadge>
      ),
      neutrals: (
        <SoftBadge pill bg={'secondary'}>
          {item.neutralsCount}
        </SoftBadge>
      ),
      positives: (
        <SoftBadge pill bg={'success'}>
          {item.positivesCount}
        </SoftBadge>
      ),
      negatives: (
        <SoftBadge pill bg={'danger'}>
          {item.negativesCount}
        </SoftBadge>
      ),
      actions: (
        <>
          <OverlayTrigger
            placement={'top'}
            overlay={<Tooltip>{"Voir l'analyse social buzz"}</Tooltip>}
          >
            <a href={`/social/${item.id}/`} className="mx-1">
              <EyeLineIcon />
            </a>
          </OverlayTrigger>
        </>
      )
    });
  });

  return (
    <>
      <div className="my-3 p-3">
        <Row>
          <Col xl={3}>
            {data?.isSiteAnalyzesEnabled && (
              <Flex alignItems={'center'} direction={'column'}>
                <Image
                  className="img-fluid"
                  src={
                    lastCrawl?.analysisData[0]?.data?.overview?.screenshotUrl
                  }
                />
                <SoftBadge className="my-2" pill bg={issuesColor}>
                  {lastCrawl?.issuesCount}&nbsp;Problème
                  {lastCrawl?.issuesCount != 1 && 's'}
                </SoftBadge>
              </Flex>
            )}
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{aiReportData.social.title}</h2>
              <p>{aiReportData.social.description}</p>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="my-3 p-3">
        <h3 className="fs-1 text-center">Résumé</h3>
        <EChartsReactCore echarts={echarts} option={socialSummaryChartOption} />
      </div>
      <div className="my-3 p-3">
        <CustomAdvanceTable columns={socialColumns} data={socialData} />
      </div>
    </>
  );
};

ReportDetailsSocial.propTypes = {
  data: PropTypes.object
};

export default ReportDetailsSocial;
