import { Col, Row } from 'react-bootstrap';

import React from 'react';

const Footer = () => (
  <footer className="footer">
    <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          {new Date().getFullYear()} &copy;{' '}
          <a
            href="https://anopseo.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            Anopseo
          </a>
        </p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
