import { Button, Card } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import { List } from '../page-analysis/PageAnalysisList';
import React from 'react';

const PageAnalysis = () => {
  return (
    <>
      <Card className="app-dashboard-block h-100">
        <Card.Header>
          <h2 className="app-card-title">Analyse de page</h2>
          <p className="app-card-description">
            L'analyse de page vous permet d'obtenir une analyse de votre page
            web en termes de qualité SEO, contenu et technique. Vous trouverez
            l'ensemble des problèmes prépondérants et les avertissements de
            votre page web.
          </p>
        </Card.Header>
        <Card.Body>
          <List maxResults={5} isSummary={true} />
        </Card.Body>
        <Card.Footer>
          <Flex justifyContent={'end'} alignItems={'center'}>
            <Button href="page-analysis" variant="light">
              Voir tout
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </>
  );
};

export default PageAnalysis;
