export const seoBenchmarkData = {
  title: 'Seo Benchmark',
  description:
    "Le Benchmark SEO vous permet de découvrir les mots clés les plus prometteurs afin de vous positionner efficacement par rapport à vos concurrents. De plus, vous pourrez découvrir sur quels mots clés vos concurrents ont choisi d'être positionnés, et donc choisir des mots clés de longue traîne plus efficacement pour votre propre stratégie.",
  overview: {
    title: 'Analyse du Benchmark',
    description:
      "Le Benchmark SEO vous permet de découvrir les mots clés les plus prometteurs afin de vous positionner efficacement par rapport à vos concurrents. Ici vous pourrez découvrir d'où provient le trafic ainsi que les mots clés qui ont le plus potentiel pour un stratégie SEO pertinente."
  },
  data: [
    {
      id: 1,
      date: '11-02-2022 16:02',
      url: 'https://www.etiseo.fr/',
      database:
        '<?xml version="1.0" encoding="UTF-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="30px" height="20px" viewBox="-10 -10 3020 2020"><g><rect fill="rgb(0%,14%,58%)" x="0" y="0" width="1010" height="2000" /><rect fill="rgb(97%,97%,97%)" x="1000" y="0" width="1010" height="2000" /><rect fill="rgb(93%,16%,22%)" x="2000" y="0" width="1000" height="2000" /><rect fill="none" stroke="rgb(55%,55%,55%)" stroke-width="10" x="0" y="0" width="3000" height="2000" /></g></svg>',
      results: 78
    }
  ]
};
