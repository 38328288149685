import * as ReactIconsSi from 'react-icons/si';

import Flex from 'components/common/Flex';
import { PropTypes } from 'prop-types';
import React from 'react';

const Technologies = ({ technologies }) => {
  const toSentenceCase = str => {
    const s =
      str &&
      str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .join(' ');
    return s.slice(0, 1).toUpperCase() + s.slice(1);
  };

  const retrieveIconName = name => {
    const s = name
      .replaceAll(' ', '')
      .replaceAll('-', '')
      .replaceAll(/\./g, 'dot');
    return s[0].toUpperCase() + s.slice(1).toLowerCase();
  };

  return (
    <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
      {technologies != null &&
        Object.keys(technologies).map(
          (technologyGroup, technologyGroupIndex) => (
            <React.Fragment key={`technologyGroup${technologyGroupIndex}`}>
              <div className="fs-2 fw-bold mb-3">
                {toSentenceCase(technologyGroup)}
              </div>
              {technologies[technologyGroup] != null &&
                Object.keys(technologies[technologyGroup]).map(
                  (technologyCategory, technologyCategoryIndex) => (
                    <React.Fragment
                      key={`technologyGroup${technologyGroupIndex}category${technologyCategoryIndex}`}
                    >
                      <div className="fw-bold mb-3">
                        {toSentenceCase(technologyCategory)}
                      </div>
                      {technologies[technologyGroup][technologyCategory]?.map(
                        (technology, technologyIndex) => {
                          const iconName = retrieveIconName(technology);
                          return (
                            <div
                              key={`technologyGroup${technologyGroupIndex}category${technologyCategoryIndex}technology${technologyIndex}`}
                              className="mb-3"
                            >
                              <Flex className="gap-1" alignItems={'center'}>
                                <span>-</span>
                                {ReactIconsSi['Si' + iconName] &&
                                  React.createElement(
                                    ReactIconsSi['Si' + iconName]
                                  )}
                                {technology}
                              </Flex>
                            </div>
                          );
                        }
                      )}
                    </React.Fragment>
                  )
                )}
              <hr />
            </React.Fragment>
          )
        )}
    </div>
  );
};

Technologies.propTypes = {
  technologies: PropTypes.object
};

export default Technologies;
