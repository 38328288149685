import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import { PropTypes } from 'prop-types';
import React from 'react';

const BacklinkDetailsReferringIp = ({ data }) => {
  const referringIpColumns = [
    {
      accessor: 'ip',
      Header: 'Ip'
    },
    {
      accessor: 'linkQuantity',
      Header: 'Nombre de Liens'
    },
    {
      accessor: 'spam',
      Header: 'Évaluation du Spam'
    },
    {
      accessor: 'di',
      Header: 'DI'
    },
    {
      accessor: 'pi',
      Header: 'PI'
    }
  ];
  const referringIpData = [];
  data?.referringIps?.map(referringIp =>
    referringIpData.push({
      ip: referringIp?.ip,
      linkQuantity: referringIp?.linksCount,
      spam: referringIp?.spamScore,
      di: referringIp?.domainInfluence,
      pi: referringIp?.pageInfluence
    })
  );

  return (
    <>
      <div className="mt-3">
        <CustomAdvanceTable
          columns={referringIpColumns}
          data={referringIpData}
        />
      </div>
    </>
  );
};

BacklinkDetailsReferringIp.propTypes = {
  data: PropTypes.object
};

export default BacklinkDetailsReferringIp;
