import { Button, Card } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import { List } from '../benchmark/BenchmarkList';
import React from 'react';

const Benchmark = () => {
  return (
    <>
      <Card className="app-dashboard-block h-100">
        <Card.Header>
          <h2 className="app-card-title">Seo Benchmark</h2>
          <p className="app-card-description">
            Le Benchmark SEO vous permet de découvrir les mots clés les plus
            prometteurs afin de vous positionner efficacement par rapport à vos
            concurrents. De plus, vous pourrez découvrir sur quels mots clés vos
            concurrents ont choisi d'être positionnés, et donc choisir des mots
            clés de longue traîne plus efficacement pour votre propre stratégie.
          </p>
        </Card.Header>
        <Card.Body>
          <List maxResults={5} isSummary={true} />
        </Card.Body>
        <Card.Footer>
          <Flex justifyContent={'end'} alignItems={'center'}>
            <Button href="benchmark" variant="light">
              Voir tout
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </>
  );
};

export default Benchmark;
