import { Button, Form, Spinner, Table } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddLineIcon from 'remixicon-react/AddLineIcon';
import AppContext from 'context/Context';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import CustomLanguagesSingleSelect from '../common/CustomLanguagesSingleSelect';
import CustomLocationsSingleSelect from '../common/CustomLocationsSingleSelect';
import CustomModal from '../common/CustomModal';
import { CustomTagsInput } from '../common/CustomTagsInput';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

const Report = ({ report, isSummary = false }) => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const { id, url, keywords, frequencyName, isFinished, nextReport } = report;

  const deleteAnalysis = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}reports/${id}`,
      {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
    setDeleteModalShow(false);
  };

  return (
    <>
      <tr className={!isFinished ? 'app-not-finished-analysis' : ''}>
        <td className="align-middle">
          <Flex
            style={{ whiteSpace: 'nowrap' }}
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {url}
          </Flex>
        </td>
        <td className="align-middle">
          <Flex
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {keywords.join(', ')}
          </Flex>
        </td>
        <td className="align-middle">
          <Flex
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {t(frequencyName)}
          </Flex>
        </td>
        {!isFinished ? (
          <>
            <td className="align-middle">
              <Flex
                className="h-100"
                justifyContent={'center'}
                alignItems={'start'}
                direction={'column'}
              >
                <SoftBadge pill bg="info">
                  En cours
                </SoftBadge>
              </Flex>
            </td>
            <td></td>
          </>
        ) : (
          <>
            <td className="align-middle">
              <Flex
                className="h-100"
                justifyContent={'center'}
                alignItems={'start'}
                direction={'column'}
              >
                <SoftBadge pill bg={'success'}>
                  Terminé
                </SoftBadge>
              </Flex>
            </td>
            <td className="align-middle">
              <Flex
                className="h-100"
                justifyContent={'center'}
                direction={'column'}
              >
                {new Date(nextReport).toLocaleDateString()}
              </Flex>
            </td>
          </>
        )}
        {!isSummary && (
          <td className="align-middle">
            <Flex
              className="h-100"
              justifyContent={'end'}
              alignItems={'center'}
              direction={'row'}
            >
              {isFinished && (
                <>
                  <a
                    href={`/report/${id}/`}
                    title="Voir le rapport AI"
                    className="mx-1"
                  >
                    <EyeLineIcon />
                  </a>
                </>
              )}
              <a
                onClick={() => setDeleteModalShow(true)}
                href={'#'}
                title="Supprimer le rapport AI"
                className="mx-1"
              >
                <CloseCircleLineIcon />
              </a>
            </Flex>
          </td>
        )}
      </tr>
      {!isSummary && (
        <>
          <CustomModal
            isShown={deleteModalShow}
            setIsShown={setDeleteModalShow}
            title="Supprimer le rapport AI"
            description={
              <p>
                Êtes-vous sûr de vouloir supprimer le rapport AI
                séléctionné&nbsp;?
                <br />
                {t('URL')}&nbsp;: <strong>{url}</strong>
                <br />
                {t('Keywords')}&nbsp;: <strong>{keywords.join(', ')}</strong>
                <br />
                {t('Frequency')}&nbsp; <strong>{t(frequencyName)}</strong>
              </p>
            }
            content={
              <Button
                className="app-modal-submit-button"
                variant="danger"
                onClick={() => deleteAnalysis()}
              >
                {t('Delete')}
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

Report.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    frequencyName: PropTypes.string,
    isFinished: PropTypes.bool,
    nextReport: PropTypes.number
  }),
  isSummary: PropTypes.bool
};

const Header = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [keywords, setKeywords] = useState([]);

  const handleFormSubmit = async e => {
    e.preventDefault();
    setModalShow(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}reports`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: e.target.url.value,
        keywords: keywords,
        locationCode: e.target.location.value,
        languageCode: e.target.language.value,
        frequency: e.target.frequency.value.substring(
          e.target.frequency.value.indexOf('(') + 1,
          e.target.frequency.value.indexOf(')')
        ),
        frequencyName: e.target.frequency.value.substring(
          e.target.frequency.value.indexOf('[') + 1,
          e.target.frequency.value.indexOf(']')
        ),
        isSiteAnalyzesEnabled: e.target.enableSiteAnalyzes.checked,
        isBacklinksEnabled: e.target.enableBacklinks.checked,
        isBenchmarksEnabled: e.target.enableBenchmarks.checked,
        isSocialsEnabled: e.target.enableSocials.checked
      })
    });
    const json = await response.json();
    e.target.reset();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  return (
    <>
      <div className="app-list-header">
        <h2 className="app-list-title">Rapport AI</h2>
        <Button className="app-list-button" onClick={() => setModalShow(true)}>
          <Flex className="gap-1" alignItems={'center'}>
            <AddLineIcon className="app-list-button-icon" />
            <span>Créer un rapport AI</span>
          </Flex>
        </Button>
        <div className="app-list-description">
          <p>
            Le rapport AI vous permet d'analyser de manière pertinente
            l'ensemble des information SEO, social et de e-réputation. Vous
            pourrez par la même occasion définir une planification pour le
            lancement automatique des analyses. Dans ce rapport vous recevez
            l'ensemble des conseils nécessaires pour accroître ou maitriser sa
            visibilité sur internet.
          </p>
        </div>
      </div>
      <CustomModal
        isShown={modalShow}
        setIsShown={setModalShow}
        title="Rapport AI"
        description="Ci-dessous vous pouvez entrer l'URL de votre page à analyser. Vérifier que votre page est accessible avant de lancer l'analyse de votre page. L'URL est de type&nbsp;: http(s)://www.domaine.extension ou http(s)://domaine.extension"
        content={
          <Form className="app-modal-form" onSubmit={handleFormSubmit}>
            <input
              type="submit"
              disabled
              style={{ display: 'none' }}
              aria-hidden="true"
            />
            <Form.Label htmlFor="modalUrl">{t('URL')}</Form.Label>
            <Form.Control
              id="modalUrl"
              name="url"
              type="text"
              required={true}
            />
            <Form.Label htmlFor="modalKeywords">
              {t('Keywords')} &#40;{t('Separated by a comma')}&#41;
            </Form.Label>
            <CustomTagsInput
              id="modalKeywords"
              name="keywords"
              values={keywords}
              onTags={value => setKeywords(value.values)}
            />
            <CustomLocationsSingleSelect />
            <CustomLanguagesSingleSelect />
            <Form.Label htmlFor="modalFrequency">{t('Frequency')}</Form.Label>
            <Select
              id="modalFrequency"
              name="frequency"
              className="mb-3"
              closeMenuOnSelect={true}
              options={[
                {
                  value: '(0 0 * * *)[Daily]',
                  label: t('Daily')
                },
                {
                  value: '(0 0 * * 1)[Weekly]',
                  label: t('Weekly')
                },
                {
                  value: '(0 0 1 * *)[Monthly]',
                  label: t('Monthly')
                }
              ]}
              placeholder={t('Select')}
              classNamePrefix="react-select"
              defaultValue={{
                value: '(0 0 1 * *)[Monthly]',
                label: t('Monthly')
              }}
            />
            <Flex className="gap-2" alignItems={'start'}>
              <Form.Label htmlFor="modalEnableSiteAnalyzes">Crawl</Form.Label>
              <Form.Check
                id="modalEnableSiteAnalyzes"
                name="enableSiteAnalyzes"
                type="checkbox"
              />
            </Flex>
            <Flex className="gap-2" alignItems={'start'}>
              <Form.Label htmlFor="modalEnableBacklinks">Backlinks</Form.Label>
              <Form.Check
                id="modalEnableBacklinks"
                name="enableBacklinks"
                type="checkbox"
              />
            </Flex>
            <Flex className="gap-2" alignItems={'start'}>
              <Form.Label htmlFor="modalEnableBenchmarks">
                Seo Benchmark
              </Form.Label>
              <Form.Check
                id="modalEnableBenchmarks"
                name="enableBenchmarks"
                type="checkbox"
              />
            </Flex>
            <Flex className="gap-2" alignItems={'start'}>
              <Form.Label htmlFor="modalEnableSocials">
                Social Signal
              </Form.Label>
              <Form.Check
                id="modalEnableSocials"
                name="enableSocials"
                type="checkbox"
              />
            </Flex>
            <Button className="app-modal-submit-button" type="submit">
              {t('Submit')}
            </Button>
          </Form>
        }
      />
    </>
  );
};

export const List = ({ maxResults = null, isSummary = false }) => {
  const { t } = useTranslation();
  const { lastMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    shouldReconnect: () => true
  });
  const {
    config: { userId, token }
  } = useContext(AppContext);
  const [reports, setReports] = useState([]);
  const [isFetchFinished, setIsFetchFinished] = useState(false);

  const fetchReports = useCallback(async () => {
    const reportsResponse = await fetch(
      `${process.env.REACT_APP_API_URL}reports`,
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    );
    const json = await reportsResponse.json();
    if (maxResults != null) {
      setReports(json.slice(0, maxResults));
    } else {
      setReports(json);
    }
    setIsFetchFinished(true);
  }, []);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  useEffect(() => {
    if (lastMessage == null) {
      return;
    }
    const data = JSON.parse(lastMessage.data);
    if (data != null) {
      if (data.userId == userId) {
        if (data.from == 'reports') {
          fetchReports();
        }
      }
    }
  }, [fetchReports, lastMessage]);

  return (
    <>
      {isFetchFinished ? (
        reports?.length == 0 ? (
          <Flex justifyContent={'center'}>
            <p className="text-center fw-bold my-3">Aucun rapport AI trouvé</p>
          </Flex>
        ) : (
          <Table className="app-list-table" responsive>
            <thead>
              <tr>
                <th scope="col">{t('Site')}</th>
                <th scope="col">{t('Keywords')}</th>
                <th scope="col">{t('Frequency')}</th>
                <th scope="col">{t('Progress')}</th>
                <th scope="col">{t('Next report')}</th>
                {!isSummary && (
                  <th className="text-end" scope="col">
                    {t('Actions')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {reports?.map(report => (
                <Report report={report} key={report.id} isSummary={isSummary} />
              ))}
            </tbody>
          </Table>
        )
      ) : (
        <Flex justifyContent={'center'}>
          <Spinner className="my-3" animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Flex>
      )}
    </>
  );
};

List.propTypes = {
  maxResults: PropTypes.number,
  isSummary: PropTypes.bool
};

const ReportList = () => {
  return (
    <>
      <Header />
      <List />
    </>
  );
};

export default ReportList;
