import { Button, Card, Col, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import {
  faDailymotion,
  faFacebook,
  faFlickr,
  faInstagram,
  faReddit,
  faTumblr,
  faTwitter,
  faVimeo,
  faVk,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

import CustomModal from 'components/anopseo/common/CustomModal';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const SocialMedia = ({ socialMedia }) => {
  const { t } = useTranslation();
  const { date, url, network, text, sentiment } = socialMedia;
  const [modalShow, setModalShow] = useState(false);

  let icon = null;
  switch (network) {
    case 'web':
      icon = faGlobe;
      break;
    case 'facebook':
      icon = faFacebook;
      break;
    case 'twitter':
      icon = faTwitter;
      break;
    case 'youtube':
      icon = faYoutube;
      break;
    case 'instagram':
      icon = faInstagram;
      break;
    case 'tumblr':
      icon = faTumblr;
      break;
    case 'reddit':
      icon = faReddit;
      break;
    case 'flickr':
      icon = faFlickr;
      break;
    case 'dailymotion':
      icon = faDailymotion;
      break;
    case 'vimeo':
      icon = faVimeo;
      break;
    case 'vkontakte':
      icon = faVk;
      break;

    default:
      break;
  }

  let color = null;
  let badgeText = null;
  switch (sentiment) {
    case 'neutral':
      color = 'secondary';
      badgeText = 'Neutral';
      break;
    case 'positive':
      color = 'success';
      badgeText = 'Positive';
      break;
    case 'negative':
      color = 'danger';
      badgeText = 'Negative';
      break;

    default:
      break;
  }

  return (
    <>
      <Col className="p-3" xl={3}>
        <Card className={`h-100 p-3 border-start border-${color} border-5`}>
          <Flex
            className="gap-3 mb-3"
            justifyContent={'between'}
            alignItems={'center'}
          >
            <p className="fs--1 mb-0">{date}</p>
            <SoftBadge pill bg={color}>
              {t(badgeText)}
            </SoftBadge>
          </Flex>
          <a
            style={{
              display: '-webkit-box',
              overflowWrap: 'anywhere',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden'
            }}
            className="fs--1 fw-bold"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {url}
          </a>
          <p className="my-4">{text}</p>
          <Button
            className="mt-auto"
            variant="light"
            onClick={() => setModalShow(true)}
          >
            Détails
          </Button>
        </Card>
      </Col>
      <CustomModal
        isShown={modalShow}
        setIsShown={setModalShow}
        title="Détails"
        description={
          <>
            <p>
              {t('Date')}&nbsp;: {date}
            </p>
            <p>
              {t('Sentiment')}&nbsp;:{' '}
              <SoftBadge pill bg={color}>
                {t(badgeText)}
              </SoftBadge>
            </p>
            <p>
              {t('URL')}&nbsp;:{' '}
              <a
                style={{
                  display: '-webkit-box',
                  overflowWrap: 'anywhere',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden'
                }}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {url}
              </a>
            </p>
            <p>
              <Flex className="gap-1" alignItems={'center'}>
                {t('Network')}&nbsp;:
                <FontAwesomeIcon icon={icon} size={'2x'} />
                {network}
              </Flex>
            </p>
            <p style={{ whiteSpace: 'pre-line' }}>
              {t('Text')}&nbsp;: <br />
              {text}
            </p>
          </>
        }
        content={
          <Button
            className="app-modal-submit-button"
            onClick={() => setModalShow(false)}
          >
            {t('Close')}
          </Button>
        }
      />
    </>
  );
};

SocialMedia.propTypes = {
  socialMedia: PropTypes.shape({
    date: PropTypes.string,
    url: PropTypes.string,
    network: PropTypes.string,
    text: PropTypes.string,
    sentiment: PropTypes.string
  })
};

const SocialDetailsSocialMedia = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="mt-3">
        {data?.socialMedias?.length > 0 ? (
          data?.socialMedias?.map((socialMedia, index) => (
            <SocialMedia
              socialMedia={socialMedia}
              key={`socialMedia${index}`}
            />
          ))
        ) : (
          <p className="text-center">
            <strong>{t('No result found')}</strong>
          </p>
        )}
      </Row>
    </>
  );
};

SocialDetailsSocialMedia.propTypes = {
  data: PropTypes.object
};

export default SocialDetailsSocialMedia;
