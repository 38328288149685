import { Button, Form, Spinner, Table } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  faDailymotion,
  faFacebook,
  faFlickr,
  faInstagram,
  faReddit,
  faTumblr,
  faTwitter,
  faVimeo,
  faVk,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

import AddLineIcon from 'remixicon-react/AddLineIcon';
import AppContext from 'context/Context';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import CustomLanguagesSingleSelect from '../common/CustomLanguagesSingleSelect';
import CustomLocationsSingleSelect from '../common/CustomLocationsSingleSelect';
import CustomModal from '../common/CustomModal';
import { CustomTagsInput } from '../common/CustomTagsInput';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import SoftBadge from 'components/common/SoftBadge';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

const Social = ({ social, isSummary }) => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [restartModalShow, setRestartModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const {
    id,
    createdAt,
    keywords,
    isOnError,
    errorMessages,
    isFinished,
    locationCode,
    languageCode,
    positivesCount,
    neutralsCount,
    negativesCount,
    contentTypes,
    networks
  } = social;

  const createdAtDate = new Date(createdAt).toLocaleString();
  const createdAtSummaryDate = new Date(createdAt).toLocaleDateString();

  let networkIcons = [];
  networks?.forEach(network => {
    let icon = null;
    switch (network) {
      case 'web':
        icon = faGlobe;
        break;
      case 'facebook':
        icon = faFacebook;
        break;
      case 'twitter':
        icon = faTwitter;
        break;
      case 'youtube':
        icon = faYoutube;
        break;
      case 'instagram':
        icon = faInstagram;
        break;
      case 'tumblr':
        icon = faTumblr;
        break;
      case 'reddit':
        icon = faReddit;
        break;
      case 'flickr':
        icon = faFlickr;
        break;
      case 'dailymotion':
        icon = faDailymotion;
        break;
      case 'vimeo':
        icon = faVimeo;
        break;
      case 'vkontakte':
        icon = faVk;
        break;

      default:
        break;
    }
    networkIcons.push(icon);
  });

  const restartAnalysis = async () => {
    setRestartModalShow(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}socials`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        keywords: keywords,
        locationCode: locationCode,
        languageCode: languageCode
      })
    });
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  const deleteAnalysis = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}socials/${id}`,
      {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
    setDeleteModalShow(false);
  };

  return (
    <>
      <tr
        className={
          isOnError
            ? 'app-in-error-analysis'
            : !isFinished
            ? 'app-not-finished-analysis'
            : ''
        }
      >
        <td className="align-middle">
          <Flex
            style={{ whiteSpace: 'nowrap' }}
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {isSummary ? createdAtSummaryDate : createdAtDate}
          </Flex>
        </td>
        <td className="align-middle">
          <Flex
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {keywords.join(', ')}
          </Flex>
        </td>
        {isOnError ? (
          <td className="align-middle" colSpan="3">
            <Flex
              className="text-center h-100"
              direction={'column'}
              justifyContent={'start'}
              alignItems={'center'}
            >
              {errorMessages?.map((errorMessage, index) => (
                <p key={`analysis${id}errorMessage${index}`} className="mb-0">
                  {t(errorMessage)}
                </p>
              ))}
            </Flex>
          </td>
        ) : !isFinished ? (
          <>
            <td></td>
            <td></td>
            <td></td>
          </>
        ) : (
          <>
            <td className="align-middle">
              <Flex style={{ whiteSpace: 'nowrap' }} className="h-100 gap-2">
                <SoftBadge pill bg={'secondary'}>
                  {neutralsCount}
                </SoftBadge>
                <SoftBadge pill bg={'success'}>
                  {positivesCount}
                </SoftBadge>
                <SoftBadge pill bg={'danger'}>
                  {negativesCount}
                </SoftBadge>
              </Flex>
            </td>
            <td className="align-middle">
              <Flex
                style={{ whiteSpace: 'nowrap' }}
                className="h-100"
                justifyContent={'center'}
                direction={'column'}
              >
                {contentTypes.toString()}
              </Flex>
            </td>
            <td className="align-middle">
              <Flex style={{ whiteSpace: 'nowrap' }} className="h-100 gap-2">
                {networkIcons.map(
                  (networkIcon, index) =>
                    networkIcon && (
                      <FontAwesomeIcon
                        key={`analysis${id}networkIcon${index}`}
                        icon={networkIcon}
                      />
                    )
                )}
              </Flex>
            </td>
          </>
        )}
        {!isSummary && (
          <td className="align-middle">
            <Flex
              className="h-100"
              justifyContent={'end'}
              alignItems={'center'}
              direction={'row'}
            >
              {isFinished && (
                <>
                  {!isOnError ? (
                    <a
                      href={`/social/${id}/`}
                      title="Voir l'analyse social buzz"
                      className="mx-1"
                    >
                      <EyeLineIcon />
                    </a>
                  ) : null}
                  <a
                    onClick={() => setRestartModalShow(true)}
                    href={'#'}
                    title="Recommencer l'analyse social buzz"
                    className="mx-1"
                  >
                    <RefreshLineIcon />
                  </a>
                </>
              )}
              <a
                onClick={() => setDeleteModalShow(true)}
                href={'#'}
                title="Supprimer l'analyse social buzz"
                className="mx-1"
              >
                <CloseCircleLineIcon />
              </a>
            </Flex>
          </td>
        )}
      </tr>
      {!isSummary && (
        <>
          <CustomModal
            isShown={restartModalShow}
            setIsShown={setRestartModalShow}
            title="Recommencer l'analyse social buzz"
            description={
              <p>
                Êtes-vous sûr de vouloir recommencer l'analyse social buzz
                séléctionnée&nbsp;?
                <br />
                {t('Date')}&nbsp;: <strong>{createdAtDate}</strong>
                <br />
                {t('Keywords')}&nbsp;: <strong>{keywords.join(', ')}</strong>
              </p>
            }
            content={
              <Button
                className="app-modal-submit-button"
                onClick={() => restartAnalysis()}
              >
                {t('Restart')}
              </Button>
            }
          />
          <CustomModal
            isShown={deleteModalShow}
            setIsShown={setDeleteModalShow}
            title="Supprimer l'analyse social buzz"
            description={
              <p>
                Êtes-vous sûr de vouloir supprimer l'analyse social buzz
                séléctionnée&nbsp;?
                <br />
                {t('Date')}&nbsp;: <strong>{createdAtDate}</strong>
                <br />
                {t('Keywords')}&nbsp;: <strong>{keywords.join(', ')}</strong>
              </p>
            }
            content={
              <Button
                className="app-modal-submit-button"
                variant="danger"
                onClick={() => deleteAnalysis()}
              >
                {t('Delete')}
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

Social.propTypes = {
  social: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    isOnError: PropTypes.bool,
    errorMessages: PropTypes.arrayOf(PropTypes.string),
    isFinished: PropTypes.bool,
    keywords: PropTypes.arrayOf(PropTypes.string),
    locationCode: PropTypes.number,
    languageCode: PropTypes.string,
    positivesCount: PropTypes.number,
    neutralsCount: PropTypes.number,
    negativesCount: PropTypes.number,
    contentTypes: PropTypes.arrayOf(PropTypes.string),
    networks: PropTypes.arrayOf(PropTypes.string)
  }),
  isSummary: PropTypes.bool
};

const Header = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [keywords, setKeywords] = useState([]);

  const handleFormSubmit = async e => {
    e.preventDefault();
    setModalShow(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}socials`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        keywords: keywords,
        locationCode: e.target.location.value,
        languageCode: e.target.language.value
      })
    });
    const json = await response.json();
    e.target.reset();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  return (
    <>
      <div className="app-list-header">
        <h2 className="app-list-title">Social Buzz</h2>
        <Button className="app-list-button" onClick={() => setModalShow(true)}>
          <Flex className="gap-1" alignItems={'center'}>
            <AddLineIcon className="app-list-button-icon" />
            <span>Créer une analyse social buzz</span>
          </Flex>
        </Button>
        <div className="app-list-description">
          <p>
            Les backlinks sont importants pour le référencement web, cependant
            les moteurs de recherche prennent en compte de plus en plus l'impact
            du Social Buzz (mention, positif / négatif, réseaux sociaux, avis,
            etc.) dans le calcul de la notoriété d'un site web. Le Social Buzz
            vous permet d'avoir une analyse de votre impact sur le web de façon
            globale.
          </p>
        </div>
      </div>
      <CustomModal
        isShown={modalShow}
        setIsShown={setModalShow}
        title="Analyse social buzz"
        description="Ci-dessous vous pouvez entrer l'URL de votre page à analyser. Vérifier que votre page est accessible avant de lancer l'analyse de votre page. L'URL est de type&nbsp;: http(s)://www.domaine.extension ou http(s)://domaine.extension"
        content={
          <Form className="app-modal-form" onSubmit={handleFormSubmit}>
            <input
              type="submit"
              disabled
              style={{ display: 'none' }}
              aria-hidden="true"
            />
            <Form.Label htmlFor="modalKeywords">
              {t('Keywords')} &#40;{t('Separated by a comma')}&#41;
            </Form.Label>
            <CustomTagsInput
              id="modalKeywords"
              name="keywords"
              values={keywords}
              onTags={value => setKeywords(value.values)}
            />
            <CustomLocationsSingleSelect />
            <CustomLanguagesSingleSelect />
            <Button className="app-modal-submit-button" type="submit">
              {t('Submit')}
            </Button>
          </Form>
        }
      />
    </>
  );
};

export const List = ({ maxResults = null, isSummary = false }) => {
  const { t } = useTranslation();
  const { lastMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    shouldReconnect: () => true
  });
  const {
    config: { userId, token }
  } = useContext(AppContext);
  const [socials, setSocials] = useState([]);
  const [isFetchFinished, setIsFetchFinished] = useState(false);

  const fetchSocials = useCallback(async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}socials`, {
      headers: { Authorization: 'Bearer ' + token }
    });
    const json = await response.json();
    if (maxResults != null) {
      setSocials(json.slice(0, maxResults));
    } else {
      setSocials(json);
    }
    setIsFetchFinished(true);
  }, []);

  useEffect(() => {
    fetchSocials();
  }, [fetchSocials]);

  useEffect(() => {
    if (lastMessage == null) {
      return;
    }
    const data = JSON.parse(lastMessage.data);
    if (data != null) {
      if (data.userId == userId) {
        if (data.from == 'socials') {
          fetchSocials();
        }
      }
    }
  }, [fetchSocials, lastMessage]);

  return (
    <>
      {isFetchFinished ? (
        socials?.length == 0 ? (
          <Flex justifyContent={'center'}>
            <p className="text-center fw-bold my-3">
              Aucune analyse social buzz trouvée
            </p>
          </Flex>
        ) : (
          <Table className="app-list-table" responsive>
            <thead>
              <tr>
                <th scope="col">{t('Date')}</th>
                <th scope="col">{t('Keywords')}</th>
                <th scope="col">{t('Sentiment')}</th>
                <th scope="col">{t('Content type')}</th>
                <th scope="col">{t('Network')}</th>
                {!isSummary && (
                  <th className="text-end" scope="col">
                    {t('Actions')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {socials?.map(social => (
                <Social social={social} key={social.id} isSummary={isSummary} />
              ))}
            </tbody>
          </Table>
        )
      ) : (
        <Flex justifyContent={'center'}>
          <Spinner className="my-3" animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Flex>
      )}
    </>
  );
};

List.propTypes = {
  maxResults: PropTypes.number,
  isSummary: PropTypes.bool
};

const SocialList = () => {
  return (
    <>
      <Header />
      <List />
    </>
  );
};

export default SocialList;
