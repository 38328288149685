import { Button, Card } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import { List } from '../backlink/BacklinkList';
import React from 'react';

const Backlink = () => {
  return (
    <>
      <Card className="app-dashboard-block h-100">
        <Card.Header>
          <h2 className="app-card-title">Vérifier backlink</h2>
          <p className="app-card-description">
            L'analyse du backlink vous permet de connaitre les liens externes
            vers votre site ou celui de votre concurrent ainsi que le type de
            lien suivis ou non-suivis. En analysant uniquement les liens à forte
            valeur ajoutée, vous allez gagner du temps et concentrer vos efforts
            d'amélioration sur les liens qui ont le plus d'impact sur le
            référencement.
          </p>
        </Card.Header>
        <Card.Body>
          <List maxResults={5} isSummary={true} />
        </Card.Body>
        <Card.Footer>
          <Flex justifyContent={'end'} alignItems={'center'}>
            <Button href="backlink" variant="light">
              Voir tout
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </>
  );
};

export default Backlink;
