import React, { useContext } from 'react';

import AppContext from 'context/Context';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import User3FillIcon from 'remixicon-react/User3FillIcon';

const ProfileDropdown = () => {
  const { setConfig } = useContext(AppContext);

  const logout = () => {
    setConfig('userId', '');
    setConfig('userRole', '');
    setConfig('token', '');
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="nav-link d-flex"
      >
        <User3FillIcon color="#142540" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item href="/my-profile">Mon profil</Dropdown.Item>
          <Dropdown.Item href="/support">Support</Dropdown.Item>
          <Dropdown.Item href="/invoice-list">Facturation</Dropdown.Item>
          <Dropdown.Item href="/user-list">Utilisateurs</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={logout}>Déconnexion</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
