import * as echarts from 'echarts';

import { Col, ProgressBar, Row } from 'react-bootstrap';

import EChartsReactCore from 'echarts-for-react/lib/core';
import Flex from 'components/common/Flex';
import { PropTypes } from 'prop-types';
import React from 'react';
import { getColor } from 'helpers/utils';

const BacklinkDetailsOverview = ({ data }) => {
  const referringDomainsPercentage =
    Math.round(
      ((data?.referringDomainsCount * 100) / data?.backlinksCount) * 100
    ) / 100;
  const referringIpsPercentage =
    Math.round(((data?.referringIpsCount * 100) / data?.backlinksCount) * 100) /
    100;
  const highSpamPercentage =
    Math.round(((data?.highSpams * 100) / data?.backlinksCount) * 100) / 100;
  const domainColor =
    data?.domainScore > 30
      ? data?.domainScore > 70
        ? 'success'
        : 'warning'
      : 'danger';
  const spamColor =
    data?.domainSpamScore >= 31
      ? data?.domainSpamScore >= 61
        ? 'danger'
        : 'warning'
      : 'success';
  const domainOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        max: 100,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(domainColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: data?.domainScore,
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ]
      }
    ]
  };
  const spamOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(spamColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: data?.domainSpamScore,
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ],
        detail: {
          formatter: '{value}'
        }
      }
    ]
  };

  const linkOption = {
    series: [
      {
        type: 'pie',
        height: '300px',
        radius: ['40%', '80%'],
        label: {
          show: false,
          position: 'center'
        },
        data: [
          {
            value: data?.anchorTypeCount,
            name: 'Ancres',
            itemStyle: {
              color: getColor('primary')
            }
          },
          {
            value: data?.imageTypeCount,
            name: 'Images',
            itemStyle: {
              color: getColor('secondary')
            }
          },
          {
            value: data?.linkTypeCount,
            name: 'Liens',
            itemStyle: {
              color: getColor('success')
            }
          },
          {
            value: data?.metaTypeCount,
            name: 'Metas',
            itemStyle: {
              color: getColor('dark')
            }
          },
          {
            value: data?.canonicalTypeCount,
            name: 'Canoniques',
            itemStyle: {
              color: getColor('danger')
            }
          },
          {
            value: data?.alternateTypeCount,
            name: 'Alternatifs',
            itemStyle: {
              color: getColor('warning')
            }
          },
          {
            value: data?.redirectTypeCount,
            name: 'Redirections',
            itemStyle: {
              color: getColor('info')
            }
          }
        ]
      }
    ],
    legend: {
      bottom: 'bottom'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };

  return (
    <>
      <Row className="mt-3">
        <Col xl={4}>
          <h3 className="fs-1">Influence du Domaine</h3>
          <EChartsReactCore echarts={echarts} option={domainOption} />
          <Flex
            className="border-bottom text-center p-2 gap-2 w-100"
            direction={'column'}
          >
            <span className="fw-bold">URLs Référentes</span>
            <span>{data?.backlinksCount}</span>
          </Flex>
          <Flex
            className="border-bottom text-center p-2 gap-2 w-100"
            direction={'column'}
          >
            <span className="fw-bold">Domaines Référents</span>
            <span>
              {data?.referringDomainsCount} &#40;
              {referringDomainsPercentage}&nbsp;&#37;&#41;
            </span>
            <ProgressBar now={referringDomainsPercentage} />
          </Flex>
          <Flex className="text-center p-2 gap-2 w-100" direction={'column'}>
            <span className="fw-bold">Ips Référents</span>
            <span>
              {data?.referringIpsCount} &#40;
              {referringIpsPercentage}&nbsp;&#37;&#41;
            </span>
            <ProgressBar now={referringIpsPercentage} />
          </Flex>
        </Col>
        <Col className="border-start border-end" xl={4}>
          <h3 className="fs-1">Évaluation du Spam</h3>
          <EChartsReactCore echarts={echarts} option={spamOption} />
          <Flex className="text-center p-2 gap-2 w-100" direction={'column'}>
            <span className="fw-bold">Liens d'Évaluation de Spam Élevé</span>
            <span>
              {data?.highSpams} &#40;
              {highSpamPercentage}&nbsp;&#37;&#41;
            </span>
            <ProgressBar now={highSpamPercentage} />
          </Flex>
        </Col>
        <Col xl={4}>
          <h3 className="fs-1">Type de Lien</h3>
          <EChartsReactCore
            echarts={echarts}
            option={linkOption}
            style={{ height: '400px' }}
          />
          <Flex
            className="border-bottom text-center p-2 gap-2 w-100"
            direction={'column'}
          >
            <span className="fw-bold">Ancres</span>
            <span>{data?.anchorTypeCount}</span>
          </Flex>
          <Flex
            className="border-bottom text-center p-2 gap-2 w-100"
            direction={'column'}
          >
            <span className="fw-bold">Images</span>
            <span>{data?.imageTypeCount}</span>
          </Flex>
          <Flex
            className="border-bottom text-center p-2 gap-2 w-100"
            direction={'column'}
          >
            <span className="fw-bold">Liens</span>
            <span>{data?.linkTypeCount}</span>
          </Flex>
          <Flex
            className="border-bottom text-center p-2 gap-2 w-100"
            direction={'column'}
          >
            <span className="fw-bold">Metas</span>
            <span>{data?.metaTypeCount}</span>
          </Flex>
          <Flex
            className="border-bottom text-center p-2 gap-2 w-100"
            direction={'column'}
          >
            <span className="fw-bold">Canoniques</span>
            <span>{data?.canonicalTypeCount}</span>
          </Flex>
          <Flex
            className="border-bottom text-center p-2 gap-2 w-100"
            direction={'column'}
          >
            <span className="fw-bold">Alternatifs</span>
            <span>{data?.alternateTypeCount}</span>
          </Flex>
          <Flex
            className="border-bottom text-center p-2 gap-2 w-100"
            direction={'column'}
          >
            <span className="fw-bold">Redirections</span>
            <span>{data?.redirectTypeCount}</span>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

BacklinkDetailsOverview.propTypes = {
  data: PropTypes.object
};

export default BacklinkDetailsOverview;
