import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import React, { useState } from 'react';

import Flex from 'components/common/Flex';
import { PropTypes } from 'prop-types';
import StarRating from 'components/common/StarRating';
import { useTranslation } from 'react-i18next';

const GoogleReview = ({ googleReview }) => {
  const { date, profileName, profileUrl, ratingValue, text } = googleReview;
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Col className="p-3" xl={3}>
        <Card className="h-100 p-3">
          <p>{date}</p>
          <Flex
            className="gap-3"
            justifyContent={'between'}
            alignItems={'center'}
          >
            <a href={profileUrl} target="_blank" rel="noopener noreferrer">
              {profileName}
            </a>
          </Flex>
          <StarRating className="my-3" rating={ratingValue} readonly={true} />
          <p
            style={{
              display: '-webkit-box',
              overflowWrap: 'anywhere',
              WebkitLineClamp: '4',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden'
            }}
            className="my-3"
          >
            {text}
          </p>
          <Button
            className="border-1 mt-auto"
            variant="light"
            onClick={() => setModalShow(true)}
          >
            Détails
          </Button>
        </Card>
      </Col>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Détails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Date : {date}</p>
          <p>
            Profile :{' '}
            <a href={profileUrl} target="_blank" rel="noopener noreferrer">
              {profileName}
            </a>
          </p>
          <p>
            Notation : <StarRating rating={ratingValue} readonly={true} />
          </p>
          <p style={{ whiteSpace: 'pre-line' }}>
            Texte : <br />
            {text}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="border-1"
            variant="light"
            onClick={() => setModalShow(false)}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

GoogleReview.propTypes = {
  googleReview: PropTypes.shape({
    date: PropTypes.string,
    profileName: PropTypes.string,
    profileUrl: PropTypes.string,
    ratingValue: PropTypes.number,
    text: PropTypes.string
  })
};

const SocialDetailsGoogleReviews = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="mt-3 p-3">
        <p>
          <strong>{t('URL')}&nbsp;:</strong>{' '}
          <a
            href={data.googleReviewsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.googleReviewsUrl}
          </a>
        </p>
      </Row>
      <Row className="mt-3">
        {data.googleReviews?.length > 0 ? (
          data.googleReviews?.map((googleReview, index) => (
            <GoogleReview
              googleReview={googleReview}
              key={`googleReview${index}`}
            />
          ))
        ) : (
          <p className="text-center">
            <strong>{t('No result found')}</strong>
          </p>
        )}
      </Row>
    </>
  );
};

SocialDetailsGoogleReviews.propTypes = {
  data: PropTypes.object
};

export default SocialDetailsGoogleReviews;
