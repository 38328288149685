import { Card, Tab, Tabs } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import SiteAnalysisDetailsBrokenUrl from './details/SiteAnalysisDetailsBrokenUrl';
import SiteAnalysisDetailsContent from './details/SiteAnalysisDetailsContent';
import SiteAnalysisDetailsDuplicatedUrl from './details/SiteAnalysisDetailsDuplicatedUrl';
import SiteAnalysisDetailsExploration from './details/SiteAnalysisDetailsExploration';
import SiteAnalysisDetailsIndexation from './details/SiteAnalysisDetailsIndexation';
import SiteAnalysisDetailsOverview from './details/SiteAnalysisDetailsOverview';
import SiteAnalysisDetailsPerformance from './details/SiteAnalysisDetailsPerformance';
import SiteAnalysisDetailsRedirect from './details/SiteAnalysisDetailsRedirect';
import { crawlWebsiteData } from 'data/anopseo/site-analysis';
import { useParams } from 'react-router-dom';

const SiteAnalysisDetails = () => {
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    const crawlsResponse = await fetch(
      `${process.env.REACT_APP_API_URL}analyzes/sites/${params.id}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await crawlsResponse.json();
    setData(json);
  }, []);

  const overviewData = crawlWebsiteData.overview;
  const explorationData = crawlWebsiteData.exploration;
  const indexationData = crawlWebsiteData.indexation;
  const duplicatedUrlData = crawlWebsiteData.duplicatedUrl;
  const contentData = crawlWebsiteData.content;
  const performanceData = crawlWebsiteData.performance;
  const brokenUrlData = crawlWebsiteData.brokenUrl;
  const redirectData = crawlWebsiteData.redirect;

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Card className="p-3">
        <Tabs variant="pills" defaultActiveKey="overview">
          <Tab eventKey="overview" title="VUE D'ENSEMBLE">
            <SiteAnalysisDetailsOverview
              title={overviewData.title}
              description={overviewData.description}
              data={data}
            />
          </Tab>
          <Tab eventKey="exploration" title="EXPLORATION">
            <SiteAnalysisDetailsExploration
              title={explorationData.title}
              description={explorationData.description}
              data={data}
            />
          </Tab>
          <Tab eventKey="indexation" title="INDEXATION">
            <SiteAnalysisDetailsIndexation
              title={indexationData.title}
              description={indexationData.description}
              data={data}
            />
          </Tab>
          <Tab eventKey="duplicatedUrl" title="URLS DUPLIQUÉES">
            <SiteAnalysisDetailsDuplicatedUrl
              title={duplicatedUrlData.title}
              description={duplicatedUrlData.description}
              data={data}
            />
          </Tab>
          <Tab eventKey="content" title="CONTENU">
            <SiteAnalysisDetailsContent
              title={contentData.title}
              description={contentData.description}
              data={data}
            />
          </Tab>
          <Tab eventKey="performance" title="PERFORMANCE">
            <SiteAnalysisDetailsPerformance
              title={performanceData.title}
              description={performanceData.description}
              data={data}
            />
          </Tab>
          <Tab eventKey="brokenUrl" title="URLS BRISÉES">
            <SiteAnalysisDetailsBrokenUrl
              title={brokenUrlData.title}
              description={brokenUrlData.description}
              data={data}
            />
          </Tab>
          <Tab eventKey="redirect" title="REDIRECTION">
            <SiteAnalysisDetailsRedirect
              title={redirectData.title}
              description={redirectData.description}
              data={data}
            />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};

export default SiteAnalysisDetails;
