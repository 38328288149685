import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import enFlag from 'assets/img/anopseo/flags/en.svg';
import frFlag from 'assets/img/anopseo/flags/fr.svg';
import { useTranslation } from 'react-i18next';

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const flagImports = {
    en: enFlag,
    fr: frFlag
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="px-0 nav-link"
      >
        <img
          src={flagImports[i18n.resolvedLanguage]}
          className="rounded-circle"
          width="24"
          height="24"
          alt={i18n.resolvedLanguage}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            <ListGroup.Item
              onClick={() => {
                i18n.changeLanguage('fr');
                handleToggle();
              }}
              className="text-center cursor-pointer"
            >
              <img
                src={frFlag}
                className="rounded-circle"
                width="24"
                height="24"
                alt={t('French')}
              />
              <div>{t('French')}</div>
            </ListGroup.Item>
            <ListGroup.Item
              onClick={() => {
                i18n.changeLanguage('en');
                handleToggle();
              }}
              className="text-center cursor-pointer"
            >
              <img
                src={enFlag}
                className="rounded-circle"
                width="24"
                height="24"
                alt={t('English')}
              />
              <div>{t('English')}</div>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
