import * as echarts from 'echarts';

import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import EChartsReactCore from 'echarts-for-react/lib/core';
import Flex from 'components/common/Flex';
import SocialDetailsGoogleReviews from './details/SocialDetailsGoogleReviews';
import SocialDetailsSocialMedia from './details/SocialDetailsSocialMedia';
import { getColor } from 'helpers/utils';
import { socialBuzzData } from 'data/anopseo/social';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SocialDetails = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    const socialBuzzResponse = await fetch(
      `${process.env.REACT_APP_API_URL}socials/${params.id}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await socialBuzzResponse.json();
    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const socialScoreOption = {
    series: [
      {
        type: 'pie',
        radius: ['40%', '80%'],
        label: {
          show: false,
          position: 'center'
        },
        data: [
          {
            value: data?.neutralsCount,
            name: t('Neutral'),
            itemStyle: {
              color: getColor('secondary')
            }
          },
          {
            value: data?.positivesCount,
            name: t('Positive'),
            itemStyle: {
              color: getColor('success')
            }
          },
          {
            value: data?.negativesCount,
            name: t('Negative'),
            itemStyle: {
              color: getColor('danger')
            }
          }
        ]
      }
    ],
    legend: {
      bottom: 'bottom'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };

  return (
    <>
      <Card className="p-3">
        <div className="p-3 my-3">
          <Row>
            <Col xl={3}>
              <EChartsReactCore echarts={echarts} option={socialScoreOption} />
            </Col>
            <Col className="border-start" xl={9}>
              <Flex className="p-2 gap-2" direction={'column'}>
                <h2>{socialBuzzData.overview.title}</h2>
                <p>{socialBuzzData.overview.description}</p>
                <Card className="p-3">
                  <Row>
                    <Col className="text-center" xl={6}>
                      <p className="fw-bold">{t('Users mentions')}</p>
                      <p>{data?.userMentionsCount}</p>
                    </Col>
                    <Col className="border-start text-center" xl={6}>
                      <p className="fw-bold">{t('Users')}</p>
                      <p>{data?.usersCount}</p>
                    </Col>
                    <Col className="text-center" xl={12}>
                      <p className="fw-bold">{t('Neutral')}</p>
                      <p>{data?.neutralsCount}</p>
                    </Col>
                    <Col className="text-center" xl={6}>
                      <p className="fw-bold">{t('Positive')}</p>
                      <p>{data?.positivesCount}</p>
                    </Col>
                    <Col className="border-start text-center" xl={6}>
                      <p className="fw-bold">{t('Negative')}</p>
                      <p>{data?.negativesCount}</p>
                    </Col>
                  </Row>
                </Card>
              </Flex>
            </Col>
          </Row>
        </div>
        <div className="my-3 p-3">
          <Tabs variant="pills" defaultActiveKey="socialMedia">
            <Tab eventKey="socialMedia" title="SOCIAL MEDIAS">
              <SocialDetailsSocialMedia data={data} />
            </Tab>
            <Tab eventKey="googleReviews" title="AVIS GOOGLE">
              <SocialDetailsGoogleReviews data={data} />
            </Tab>
          </Tabs>
        </div>
      </Card>
    </>
  );
};

export default SocialDetails;
