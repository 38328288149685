import { Button, Form, Spinner, Table } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddLineIcon from 'remixicon-react/AddLineIcon';
import AppContext from 'context/Context';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import CustomLanguagesSingleSelect from '../common/CustomLanguagesSingleSelect';
import CustomLocationsSingleSelect from '../common/CustomLocationsSingleSelect';
import CustomModal from '../common/CustomModal';
import { CustomTagsInput } from '../common/CustomTagsInput';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

const Tracking = ({ tracking, isSummary = false }) => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [restartModalShow, setRestartModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const {
    id,
    createdAt,
    isOnError,
    errorMessages,
    isFinished,
    url,
    competitorUrl,
    keywords,
    locationCode,
    languageCode,
    averagePosition
  } = tracking;

  const createdAtDate = new Date(createdAt).toLocaleString();
  const createdAtSummaryDate = new Date(createdAt).toLocaleDateString();

  const restartAnalysis = async () => {
    setRestartModalShow(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}trackings`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: url,
        competitorUrl: competitorUrl,
        keywords: keywords,
        locationCode: locationCode,
        languageCode: languageCode
      })
    });
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  const deleteAnalysis = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}trackings/${id}`,
      {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
    setDeleteModalShow(false);
  };

  const averagePositionColor =
    averagePosition < 70
      ? averagePosition < 30
        ? 'success'
        : 'warning'
      : 'danger';

  return (
    <>
      <tr
        className={
          isOnError
            ? 'app-in-error-analysis'
            : !isFinished
            ? 'app-not-finished-analysis'
            : ''
        }
      >
        <td className="align-middle">
          <Flex
            style={{ whiteSpace: 'nowrap' }}
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {isSummary ? createdAtSummaryDate : createdAtDate}
          </Flex>
        </td>
        <td className="align-middle">
          <Flex
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {url}
          </Flex>
        </td>
        <td className="align-middle">
          <Flex
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {competitorUrl}
          </Flex>
        </td>
        <td className="align-middle">
          <Flex
            className="h-100"
            justifyContent={'center'}
            alignItems={'start'}
            direction={'column'}
          >
            {keywords.length}
          </Flex>
        </td>
        {isOnError ? (
          <td className="align-middle">
            <Flex
              className="text-center h-100"
              direction={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {errorMessages?.map((errorMessage, index) => (
                <p key={`analysis${id}errorMessage${index}`} className="mb-0">
                  {t(errorMessage)}
                </p>
              ))}
            </Flex>
          </td>
        ) : !isFinished ? (
          <>
            <td></td>
          </>
        ) : (
          <>
            <td className="align-middle">
              <Flex
                className="h-100"
                justifyContent={'center'}
                alignItems={'start'}
                direction={'column'}
              >
                <SoftBadge pill bg={averagePositionColor}>
                  {averagePosition}
                </SoftBadge>
              </Flex>
            </td>
          </>
        )}
        {!isSummary && (
          <td className="align-middle">
            <Flex
              className="h-100"
              justifyContent={'end'}
              alignItems={'center'}
              direction={'row'}
            >
              {isFinished && (
                <>
                  {!isOnError ? (
                    <a
                      href={`/tracking/${id}/`}
                      title="Voir la campagne de suivi"
                      className="mx-1"
                    >
                      <EyeLineIcon />
                    </a>
                  ) : null}
                  <a
                    onClick={() => setRestartModalShow(true)}
                    href={'#'}
                    title="Recommencer la campagne de suivi"
                    className="mx-1"
                  >
                    <RefreshLineIcon />
                  </a>
                </>
              )}
              <a
                onClick={() => setDeleteModalShow(true)}
                href={'#'}
                title="Supprimer la campagne de suivi"
                className="mx-1"
              >
                <CloseCircleLineIcon />
              </a>
            </Flex>
          </td>
        )}
      </tr>
      {!isSummary && (
        <>
          <CustomModal
            isShown={restartModalShow}
            setIsShown={setRestartModalShow}
            title="Recommencer la campagne de suivi"
            description={
              <p>
                Êtes-vous sûr de vouloir recommencer la campagne de suivi
                séléctionnée&nbsp;?
                <br />
                {t('Date')}&nbsp;: <strong>{createdAtDate}</strong>
                <br />
                {t('URL')}&nbsp;: <strong>{url}</strong>
                <br />
                {t('Competitor')}&nbsp;: <strong>{competitorUrl}</strong>
                <br />
                {t('Keywords')}&nbsp;: <strong>{keywords.join(', ')}</strong>
              </p>
            }
            content={
              <Button
                className="app-modal-submit-button"
                onClick={() => restartAnalysis()}
              >
                {t('Restart')}
              </Button>
            }
          />
          <CustomModal
            isShown={deleteModalShow}
            setIsShown={setDeleteModalShow}
            title="Supprimer la campagne de suivi"
            description={
              <p>
                Êtes-vous sûr de vouloir supprimer la campagne de suivi
                séléctionnée&nbsp;?
                <br />
                {t('Date')}&nbsp;: <strong>{createdAtDate}</strong>
                <br />
                {t('URL')}&nbsp;: <strong>{url}</strong>
                <br />
                {t('Competitor')}&nbsp;: <strong>{competitorUrl}</strong>
                <br />
                {t('Keywords')}&nbsp;: <strong>{keywords.join(', ')}</strong>
              </p>
            }
            content={
              <Button
                className="app-modal-submit-button"
                variant="danger"
                onClick={() => deleteAnalysis()}
              >
                {t('Delete')}
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

Tracking.propTypes = {
  tracking: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    isOnError: PropTypes.bool,
    errorMessages: PropTypes.arrayOf(PropTypes.string),
    isFinished: PropTypes.bool,
    url: PropTypes.string,
    competitorUrl: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    locationCode: PropTypes.number,
    languageCode: PropTypes.string,
    averagePosition: PropTypes.number
  }),
  isSummary: PropTypes.bool
};

const Header = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [keywords, setKeywords] = useState([]);

  const handleFormSubmit = async e => {
    e.preventDefault();
    setModalShow(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}trackings`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: e.target.url.value,
        competitorUrl: e.target.competitor_url.value,
        keywords: keywords,
        locationCode: e.target.location.value,
        languageCode: e.target.language.value
      })
    });
    const json = await response.json();
    e.target.reset();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  return (
    <>
      <div className="app-list-header">
        <h2 className="app-list-title">Suivi de Position</h2>
        <Button className="app-list-button" onClick={() => setModalShow(true)}>
          <Flex className="gap-1" alignItems={'center'}>
            <AddLineIcon className="app-list-button-icon" />
            <span>Créer une campagne de suivi</span>
          </Flex>
        </Button>
        <div className="app-list-description">
          <p>
            Grâce au suivi de positionnement de vos mots clés, vous serez
            alertés lorsque vos concurrents effectue un boost de référencement.
            Vous obtiendrez une idée sur l'avancement de votre référencement et
            serez capables de maîtriser votre visibilité organique sur les
            moteurs de recherche.
          </p>
        </div>
      </div>
      <CustomModal
        isShown={modalShow}
        setIsShown={setModalShow}
        title="Campagne de suivi"
        description="Ci-dessous vous pouvez entrer l'URL de votre page à analyser. Vérifier que votre page est accessible avant de lancer l'analyse de votre page. L'URL est de type&nbsp;: http(s)://www.domaine.extension ou http(s)://domaine.extension"
        content={
          <Form className="app-modal-form" onSubmit={handleFormSubmit}>
            <input
              type="submit"
              disabled
              style={{ display: 'none' }}
              aria-hidden="true"
            />
            <Form.Label htmlFor="modalUrl">{t('URL')}</Form.Label>
            <Form.Control
              id="modalUrl"
              name="url"
              type="text"
              required={true}
            />
            <Form.Label htmlFor="modalCompetitorUrl">
              {t('Competitor URL')}
            </Form.Label>
            <Form.Control
              id="modalCompetitorUrl"
              name="competitor_url"
              type="text"
              required={true}
            />
            <Form.Label htmlFor="modalKeywords">
              {t('Keywords')} &#40;{t('Separated by a comma')}&#41;
            </Form.Label>
            <CustomTagsInput
              id="modalKeywords"
              name="keywords"
              values={keywords}
              onTags={value => setKeywords(value.values)}
            />
            <CustomLocationsSingleSelect />
            <CustomLanguagesSingleSelect />
            <Button className="app-modal-submit-button" type="submit">
              {t('Submit')}
            </Button>
          </Form>
        }
      />
    </>
  );
};

export const List = ({ maxResults = null, isSummary = false }) => {
  const { t } = useTranslation();
  const { lastMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    shouldReconnect: () => true
  });
  const {
    config: { userId, token }
  } = useContext(AppContext);
  const [trackings, setTrackings] = useState([]);
  const [isFetchFinished, setIsFetchFinished] = useState(false);

  const fetchTrackings = useCallback(async () => {
    const trackingsResponse = await fetch(
      `${process.env.REACT_APP_API_URL}trackings`,
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    );
    const json = await trackingsResponse.json();
    if (maxResults != null) {
      setTrackings(json.slice(0, maxResults));
    } else {
      setTrackings(json);
    }
    setIsFetchFinished(true);
  }, []);

  useEffect(() => {
    fetchTrackings();
  }, [fetchTrackings]);

  useEffect(() => {
    if (lastMessage == null) {
      return;
    }
    const data = JSON.parse(lastMessage.data);
    if (data != null) {
      if (data.userId == userId) {
        if (data.from == 'trackings') {
          fetchTrackings();
        }
      }
    }
  }, [fetchTrackings, lastMessage]);

  return (
    <>
      {isFetchFinished ? (
        trackings?.length == 0 ? (
          <Flex justifyContent={'center'}>
            <p className="text-center fw-bold my-3">
              Aucun suivi de position trouvé
            </p>
          </Flex>
        ) : (
          <Table className="app-list-table" responsive>
            <thead>
              <tr>
                <th scope="col">{t('Date')}</th>
                <th scope="col">{t('URL')}</th>
                <th scope="col">{t('Competitor')}</th>
                <th scope="col">{t('Keywords')}</th>
                <th scope="col">{t('Average')}</th>
                {!isSummary && (
                  <th className="text-end" scope="col">
                    {t('Actions')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {trackings?.map(tracking => (
                <Tracking
                  tracking={tracking}
                  key={tracking.id}
                  isSummary={isSummary}
                />
              ))}
            </tbody>
          </Table>
        )
      ) : (
        <Flex justifyContent={'center'}>
          <Spinner className="my-3" animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Flex>
      )}
    </>
  );
};

List.propTypes = {
  maxResults: PropTypes.number,
  isSummary: PropTypes.bool
};

const TrackingList = () => {
  return (
    <>
      <Header />
      <List />
    </>
  );
};

export default TrackingList;
