export const monitorListingData = {
  title: 'Moniteur',
  description:
    "Un site peut être indisponible, cependant les robots d'indexation ne prennent pas compte la panne temporaire, c'est la raison pour laquelle vous pouvez positionner une alerte sur un site web à surveiller afin d'être alerté en cas de panne (indisponibilité).",
  overview: {
    title: 'Monitoring de site',
    description:
      "Un site web n'est pas infaillible et les pannes sont assez fréquentes. L'objectif est d'être réactif en cas de panne afin de résoudre le problème avant qu'un robot d'indexation n'ai eu le temps de parcourir votre site web."
  },
  data: [
    {
      id: 1,
      date: '2019-11-01 19:05:58',
      name: 'ETISEO_ serversss',
      url: 'https://vimeo.com'
    }
  ]
};
