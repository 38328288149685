import { Button, Form, Spinner, Table } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddLineIcon from 'remixicon-react/AddLineIcon';
import AppContext from 'context/Context';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import CustomModal from '../common/CustomModal';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

const Monitor = ({ monitor, isSummary = false }) => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const {
    id,
    createdAt,
    isOnError,
    errorMessages,
    isFinished,
    url,
    frequencyName
  } = monitor;

  const createdAtDate = new Date(createdAt).toLocaleString();
  const createdAtSummaryDate = new Date(createdAt).toLocaleDateString();

  const deleteAnalysis = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}monitors/${id}`,
      {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
    setDeleteModalShow(false);
  };

  return (
    <>
      <tr
        className={
          isOnError
            ? 'app-in-error-analysis'
            : !isFinished
            ? 'app-not-finished-analysis'
            : ''
        }
      >
        <td className="align-middle">
          <Flex
            style={{ whiteSpace: 'nowrap' }}
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {isSummary ? createdAtSummaryDate : createdAtDate}
          </Flex>
        </td>
        <td className="align-middle">
          <Flex
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {url}
          </Flex>
        </td>
        <td className="align-middle">
          <Flex
            className="h-100"
            justifyContent={'center'}
            direction={'column'}
          >
            {t(frequencyName)}
          </Flex>
        </td>
        {isOnError ? (
          <td className="align-middle">
            <Flex
              className="text-center h-100"
              direction={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {errorMessages?.map((errorMessage, index) => (
                <p key={`analysis${id}errorMessage${index}`} className="mb-0">
                  {t(errorMessage)}
                </p>
              ))}
            </Flex>
          </td>
        ) : !isFinished ? (
          <>
            <td></td>
          </>
        ) : (
          <>
            <td></td>
          </>
        )}
        {!isSummary && (
          <td className="align-middle">
            <Flex
              className="h-100"
              justifyContent={'end'}
              alignItems={'center'}
              direction={'row'}
            >
              {isFinished && (
                <>
                  {!isOnError ? (
                    <a
                      href={`/monitor/${id}/`}
                      title="Voir le monitoring"
                      className="mx-1"
                    >
                      <EyeLineIcon />
                    </a>
                  ) : null}
                </>
              )}
              <a
                onClick={() => setDeleteModalShow(true)}
                href={'#'}
                title="Supprimer le monitoring"
                className="mx-1"
              >
                <CloseCircleLineIcon />
              </a>
            </Flex>
          </td>
        )}
      </tr>
      {!isSummary && (
        <>
          <CustomModal
            isShown={deleteModalShow}
            setIsShown={setDeleteModalShow}
            title="Supprimer le monitoring"
            description={
              <p>
                Êtes-vous sûr de vouloir supprimer le monitoring
                séléctionnée&nbsp;?
                <br />
                {t('Date')}&nbsp;: <strong>{createdAtDate}</strong>
                <br />
                {t('URL')}&nbsp;: <strong>{url}</strong>
                <br />
                {t('Frequency')}&nbsp;: <strong>{t(frequencyName)}</strong>
              </p>
            }
            content={
              <Button
                className="app-modal-submit-button"
                variant="danger"
                onClick={() => deleteAnalysis()}
              >
                {t('Delete')}
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

Monitor.propTypes = {
  monitor: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    isOnError: PropTypes.bool,
    errorMessages: PropTypes.arrayOf(PropTypes.string),
    isFinished: PropTypes.bool,
    url: PropTypes.string,
    frequencyName: PropTypes.string
  }),
  isSummary: PropTypes.bool
};

const Header = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);

  const handleFormSubmit = async e => {
    e.preventDefault();
    setModalShow(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}monitors`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: e.target.url.value,
        frequency: e.target.frequency.value.substring(
          e.target.frequency.value.indexOf('(') + 1,
          e.target.frequency.value.indexOf(')')
        ),
        frequencyName: e.target.frequency.value.substring(
          e.target.frequency.value.indexOf('[') + 1,
          e.target.frequency.value.indexOf(']')
        )
      })
    });
    const json = await response.json();
    e.target.reset();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  return (
    <>
      <div className="app-list-header">
        <h2 className="app-list-title">Moniteur</h2>
        <Button className="app-list-button" onClick={() => setModalShow(true)}>
          <Flex className="gap-1" alignItems={'center'}>
            <AddLineIcon className="app-list-button-icon" />
            <span>Ajouter un nouveau monitoring</span>
          </Flex>
        </Button>
        <div className="app-list-description">
          <p>
            Un site peut être indisponible, cependant les robots d'indexation ne
            prennent pas compte la panne temporaire, c'est la raison pour
            laquelle vous pouvez positionner une alerte sur un site web à
            surveiller afin d'être alerté en cas de panne (indisponibilité).
          </p>
        </div>
      </div>
      <CustomModal
        isShown={modalShow}
        setIsShown={setModalShow}
        title="Monitoring"
        description="Ci-dessous vous pouvez entrer l'URL de votre page à analyser. Vérifier que votre page est accessible avant de lancer l'analyse de votre page. L'URL est de type&nbsp;: http(s)://www.domaine.extension ou http(s)://domaine.extension"
        content={
          <Form className="app-modal-form" onSubmit={handleFormSubmit}>
            <Form.Label htmlFor="modalUrl">{t('URL')}</Form.Label>
            <Form.Control
              id="modalUrl"
              name="url"
              type="text"
              required={true}
            />
            <Form.Label htmlFor="modalFrequency">{t('Frequency')}</Form.Label>
            <Select
              id="modalFrequency"
              name="frequency"
              className="mb-3"
              closeMenuOnSelect={true}
              options={[
                {
                  value: '(*/5 * * * *)[Every 5 minutes]',
                  label: t('Every 5 minutes')
                },
                {
                  value: '(*/10 * * * *)[Every 10 minutes]',
                  label: t('Every 10 minutes')
                },
                {
                  value: '(0 * * * *)[Every hours]',
                  label: t('Every hours')
                },
                {
                  value: '(0 */6 * * *)[Every 6 hours]',
                  label: t('Every 6 hours')
                },
                {
                  value: '(0 */12 * * *)[Every 12 hours]',
                  label: t('Every 12 hours')
                },
                {
                  value: '(0 0 * * *)[Every 24 hours]',
                  label: t('Every 24 hours')
                }
              ]}
              placeholder={t('Select')}
              classNamePrefix="react-select"
              defaultValue={{
                value: '(0 0 * * *)[Every 24 hours]',
                label: t('Every 24 hours')
              }}
            />
            <Button className="app-modal-submit-button" type="submit">
              {t('Submit')}
            </Button>
          </Form>
        }
      />
    </>
  );
};

export const List = ({ maxResults = null, isSummary = false }) => {
  const { t } = useTranslation();
  const { lastMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    shouldReconnect: () => true
  });
  const {
    config: { userId, token }
  } = useContext(AppContext);
  const [monitors, setMonitors] = useState([]);
  const [isFetchFinished, setIsFetchFinished] = useState(false);

  const fetchMonitors = useCallback(async () => {
    const monitorsResponse = await fetch(
      `${process.env.REACT_APP_API_URL}monitors`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await monitorsResponse.json();
    if (maxResults != null) {
      setMonitors(json.slice(0, maxResults));
    } else {
      setMonitors(json);
    }
    setIsFetchFinished(true);
  }, []);

  useEffect(() => {
    fetchMonitors();
  }, [fetchMonitors, lastMessage]);

  useEffect(() => {
    console.log({ lastMessage });
    if (lastMessage == null) {
      return;
    }
    const data = JSON.parse(lastMessage.data);
    if (data != null) {
      if (data.userId == userId) {
        if (data.from == 'monitors') {
          fetchMonitors();
        }
      }
    }
  }, [fetchMonitors, lastMessage]);

  return (
    <>
      {isFetchFinished ? (
        monitors?.length == 0 ? (
          <Flex justifyContent={'center'}>
            <p className="text-center fw-bold my-3">Aucun monitoring trouvé</p>
          </Flex>
        ) : (
          <Table className="app-list-table" responsive>
            <thead>
              <tr>
                <th scope="col">{t('Date')}</th>
                <th scope="col">{t('URL')}</th>
                <th scope="col">{t('Frequency')}</th>
                <th scope="col"></th>
                {!isSummary && (
                  <th className="text-end" scope="col">
                    {t('Actions')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {monitors?.map(monitor => (
                <Monitor
                  monitor={monitor}
                  key={monitor.id}
                  isSummary={isSummary}
                />
              ))}
            </tbody>
          </Table>
        )
      ) : (
        <Flex justifyContent={'center'}>
          <Spinner className="my-3" animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Flex>
      )}
    </>
  );
};

List.propTypes = {
  maxResults: PropTypes.number,
  isSummary: PropTypes.bool
};

const MonitorList = () => {
  return (
    <>
      <Header />
      <List />
    </>
  );
};

export default MonitorList;
