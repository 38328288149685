import * as echarts from 'echarts';

import { Alert, Card, Col, ProgressBar, Row } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  faArrowDown,
  faArrowRight,
  faArrowUp
} from '@fortawesome/free-solid-svg-icons';
import { getColor, rgbaColor } from 'helpers/utils';

import AppContext from 'context/Context';
import CustomAdvanceTable from '../common/CustomAdvanceTable';
import EChartsReactCore from 'echarts-for-react/lib/core';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Technologies from '../common/Technologies';
import { seoBenchmarkData } from 'data/anopseo/benchmark';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import world from 'assets/json/world.json';

const BenchmarkDetails = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    const benchmarkResponse = await fetch(
      `${process.env.REACT_APP_API_URL}benchmarks/${params.id}`,
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    );
    const json = await benchmarkResponse.json();
    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  echarts.registerMap('world', { geoJSON: world });

  const mapData = [];
  data?.visitsLocations?.map(item => {
    mapData.push({
      name: item.location,
      value: item.value
    });
  });
  const mapOptions = {
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: params =>
        params.data?.name &&
        params.data?.value &&
        `<strong>${params.data?.name} :</strong> ${(
          params.data?.value * 100
        )?.toFixed(2)}%`
    },
    toolbox: {
      show: false,
      feature: {
        restore: {}
      }
    },
    visualMap: {
      show: false,
      min: 0,
      max: 1,
      inRange: {
        color: [
          rgbaColor(getColor('primary'), 0.8),
          rgbaColor(getColor('primary'), 0.7),
          rgbaColor(getColor('primary'), 0.6),
          rgbaColor(getColor('primary'), 0.5),
          rgbaColor(getColor('primary'), 0.4),
          rgbaColor(getColor('primary'), 0.3),
          rgbaColor(getColor('primary'), 0.2)
        ].reverse()
      }
    },
    series: [
      {
        type: 'map',
        map: 'world',
        data: mapData,
        roam: true,
        scaleLimit: {
          min: 0.75,
          max: 10
        },
        left: 0,
        right: 0,
        label: {
          show: true,
          formatter: params => {
            if (params.value) {
              const value = (params.value * 100)?.toFixed(2);
              if (value >= 66) {
                return `{markerSuccess|${value}%}`;
              }
              if (value >= 33) {
                return `{markerWarning|${value}%}`;
              }
              return `{markerDanger|${value}%}`;
            } else {
              return '';
            }
          },
          rich: {
            markerSuccess: {
              backgroundColor: getColor('success'),
              width: 50,
              height: 50,
              borderRadius: 999
            },
            markerWarning: {
              backgroundColor: getColor('warning'),
              width: 50,
              height: 50,
              borderRadius: 999
            },
            markerDanger: {
              backgroundColor: getColor('danger'),
              width: 50,
              height: 50,
              borderRadius: 999
            }
          }
        },
        itemStyle: {
          borderColor: getColor('gray-500')
        },
        emphasis: {
          itemStyle: {
            areaColor: rgbaColor(getColor('primary'), 0.8)
          }
        },
        select: {
          itemStyle: {
            areaColor: rgbaColor(getColor('primary'), 0.8)
          }
        }
      }
    ]
  };
  const trafficOriginCategories = [];
  data?.trafficOrigins?.map(item => {
    trafficOriginCategories.push(item.category);
  });
  const trafficOriginValues = [];
  data?.trafficOrigins?.map(item => {
    trafficOriginValues.push(item.value);
  });
  const trafficOriginOptions = {
    series: [
      {
        type: 'bar',
        data: trafficOriginValues
      }
    ],
    xAxis: {
      type: 'category',
      data: trafficOriginCategories,
      axisLabel: {
        interval: 0
      }
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      },
      valueFormatter: value => `${value} %`
    }
  };
  const trafficEstimationCategories = [];
  data?.trafficEstimations?.map(item => {
    trafficEstimationCategories.push(item.category);
  });
  const trafficEstimationValues = [];
  data?.trafficEstimations?.map(item => {
    trafficEstimationValues.push(item.value);
  });
  const trafficEstimationOptions = {
    series: [
      {
        type: 'bar',
        data: trafficEstimationValues
      }
    ],
    xAxis: {
      type: 'category',
      data: trafficEstimationCategories,
      axisLabel: {
        interval: 0
      }
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const keywordsTableColumns = [
    {
      accessor: 'keywords',
      Header: 'Mots-Clés'
    },
    {
      accessor: 'trends',
      Header: 'Tendances'
    },
    {
      accessor: 'competition',
      Header: 'Compétition'
    },
    {
      accessor: 'volume',
      Header: 'Volume'
    },
    {
      accessor: 'resultQuantity',
      Header: 'Résultats'
    },
    {
      accessor: 'position',
      Header: 'Position'
    },
    {
      accessor: 'url',
      Header: 'URL'
    }
  ];
  const keywordsTableData = [];
  data?.keywords?.map(item => {
    let trendState = {
      icon: faArrowRight,
      color: getColor('secondary')
    };
    if (item.trendsData?.at(-6) < item.trendsData?.at(-1)) {
      trendState.icon = faArrowUp;
      trendState.color = getColor('success');
    }
    if (item.trendsData?.at(-6) > item.trendsData?.at(-1)) {
      trendState.icon = faArrowDown;
      trendState.color = getColor('danger');
    }
    const competitionColor =
      item.competition > 30
        ? item.competition > 70
          ? 'danger'
          : 'warning'
        : 'success';
    keywordsTableData.push({
      keywords: item.keywords,
      trends: (
        <Flex alignItems={'center'}>
          <EChartsReactCore
            style={{
              width: '200px',
              height: '100px'
            }}
            echarts={echarts}
            option={{
              visualMap: {
                show: false,
                dimension: 0,
                pieces: [
                  {
                    min: 0,
                    max: 12,
                    color: getColor('info')
                  },
                  {
                    min: 12,
                    max: 999,
                    color: trendState.color
                  }
                ]
              },
              xAxis: {
                type: 'category',
                show: false
              },
              yAxis: {
                show: false
              },
              series: [
                {
                  data: item.trendsData,
                  type: 'line',
                  symbol: 'none',
                  lineStyle: {
                    width: 4,
                    cap: 'round',
                    join: 'round'
                  }
                }
              ]
            }}
          />
          <FontAwesomeIcon icon={trendState.icon} color={trendState.color} />
        </Flex>
      ),
      competition: (
        <ProgressBar now={item.competition} variant={competitionColor} />
      ),
      volume: item.volume,
      resultQuantity: item.resultQuantity,
      position: item.position,
      url: item.url
    });
  });

  return (
    <>
      <Card className="my-3 p-3">
        <Row>
          <Col xl={3}>
            <h3 className="fs-1 text-center mb-3">Technologies utilisées</h3>
            <Technologies technologies={data?.technologies} />
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{seoBenchmarkData.overview.title}</h2>
              <p>{seoBenchmarkData.overview.description}</p>
            </Flex>
            <Card className="my-3 p-3">
              <Row>
                <Col className="my-3" xl={6}>
                  <p className="text-center fs-1 fw-bold">Domaine</p>
                  <p className="text-center mb-0">{data?.domain}</p>
                </Col>
                <Col className="my-3 border-start" xl={6}>
                  <p className="text-center fs-1 fw-bold">
                    Moyenne de pages vues
                  </p>
                  <p className="text-center mb-0">
                    {Math.round(data?.averagePageViews * 100) / 100}
                  </p>
                </Col>
                <Col className="my-3" xl={6}>
                  <p className="text-center fs-1 fw-bold">
                    Nombre de résultats
                  </p>
                  <p className="text-center mb-0">
                    {data?.keywordsResultsCount}
                  </p>
                </Col>
                <Col className="my-3 border-start" xl={6}>
                  <p className="text-center fs-1 fw-bold">Taux de rebond</p>
                  <p className="text-center mb-0">
                    {Math.round(data?.bounceRate * 100) / 100}
                  </p>
                </Col>
                <Col className="my-3" xl={6}>
                  <p className="text-center fs-1 fw-bold">Nombre de visites</p>
                  <p className="text-center mb-0">{data?.numberOfVisits}</p>
                </Col>
                <Col className="my-3 border-start" xl={6}>
                  <p className="text-center fs-1 fw-bold">
                    Temps passé sur le site
                  </p>
                  <p className="text-center mb-0">
                    {Math.round(data?.timeSpentOnSite * 100) / 100} secondes
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>
      <Card className="my-3 p-3">
        <h3 className="fs-1 text-center mb-3">
          Localisation géographique des visites
        </h3>
        {data?.visitsLocations?.length > 0 ? (
          <EChartsReactCore
            style={{
              height: '600px'
            }}
            echarts={echarts}
            option={mapOptions}
          />
        ) : (
          <Alert variant={'warning'}>
            <p>{t('Due to low traffic, results cannot be displayed')}</p>
          </Alert>
        )}
      </Card>
      <Card className="my-3 p-3">
        <Row>
          <Col xl={6}>
            <h3 className="fs-1 text-center mb-3">Origine du trafic</h3>
            {data?.trafficOrigins?.every(value => value.value == 0) ? (
              <Alert variant={'warning'}>
                <p>{t('Due to low traffic, results cannot be displayed')}</p>
              </Alert>
            ) : (
              <EChartsReactCore
                echarts={echarts}
                option={trafficOriginOptions}
              />
            )}
          </Col>
          <Col xl={6}>
            <h3 className="fs-1 text-center mb-3">Trafic estimé</h3>
            {data?.trafficEstimations?.every(value => value.value == 0) ? (
              <Alert variant={'warning'}>
                <p>{t('Due to low traffic, results cannot be displayed')}</p>
              </Alert>
            ) : (
              <EChartsReactCore
                echarts={echarts}
                option={trafficEstimationOptions}
              />
            )}
          </Col>
        </Row>
      </Card>
      <Card className="my-3 p-3">
        <CustomAdvanceTable
          columns={keywordsTableColumns}
          data={keywordsTableData}
        />
      </Card>
    </>
  );
};

export default BenchmarkDetails;
