export const backlinkCheckData = {
  title: 'Vérifier Backlink',
  description:
    "L'analyse du backlink vous permet de connaitre les liens externes vers votre site ou celui de votre concurrent ainsi que le type de lien suivis ou non-suivis. En analysant uniquement les liens à forte valeur ajoutée, vous allez gagner du temps et concentrer vos efforts d'amélioration sur les liens qui ont le plus d'impact sur le référencement.",
  overview: {
    title: 'Analyse du Backlink',
    description:
      "L'analyse du backlink vous permet de connaitre les liens externes vers votre site ou celui de votre concurrent ainsi que le type de lien suivis ou non-suivis. Concentrez vos efforts sur les liens à forte valeur uniquement (spam faible, DI et PI élevé). Ci-dessous vous trouverez une vue d'ensemble de l'analyse du backlink."
  }
};
