import React, { useCallback, useContext } from 'react';

import AppContext from 'context/Context';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const CustomLocationsSingleSelect = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);

  let locationsOptions = [];
  const fetchLocations = useCallback(async () => {
    const locationsResponse = await fetch(
      `${process.env.REACT_APP_API_URL}locations`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const data = await locationsResponse.json();
    data.map(item => {
      locationsOptions.push({
        value: item[0],
        label: item[1]
      });
    });
  });

  fetchLocations();

  return (
    <>
      <Form.Label htmlFor="modalLocation">{t('Location')}</Form.Label>
      <Select
        id="modalLocation"
        name="location"
        className="mb-3"
        closeMenuOnSelect={true}
        options={locationsOptions}
        placeholder={t('Select')}
        classNamePrefix="react-select"
        defaultValue={{ value: '2250', label: 'France' }}
      />
    </>
  );
};

export default CustomLocationsSingleSelect;
