import * as echarts from 'echarts';

import { Col, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import EChartsReactCore from 'echarts-for-react/lib/core';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import { PropTypes } from 'prop-types';
import React from 'react';
import SoftBadge from 'components/common/SoftBadge';
import { aiReportData } from 'data/anopseo/report';
import { getColor } from 'helpers/utils';

const ReportDetailsBacklink = ({ data }) => {
  const lastCrawl = data?.siteAnalyzes?.slice(-1)[0];

  const backlinkSummaryDates = [];
  const backlinkSummaryBacklinksCount = [];
  const backlinkSummaryFollowedCount = [];
  const backlinkSummaryNotFollowedCount = [];
  data?.backlinks?.map(item => {
    backlinkSummaryDates.push(new Date(item.createdAt).toLocaleDateString());
    backlinkSummaryBacklinksCount.push(item.backlinksCount);
    backlinkSummaryFollowedCount.push(item.followedLinksCount);
    backlinkSummaryNotFollowedCount.push(item.notFollowedLinksCount);
  });

  const issuesColor =
    lastCrawl?.issuesCount < 10
      ? lastCrawl?.issuesCount < 5
        ? 'primary'
        : 'warning'
      : 'danger';

  const backlinkSummaryChartOption = {
    series: [
      {
        name: 'Backlinks',
        type: 'line',
        data: backlinkSummaryBacklinksCount,
        symbolSize: 10
      },
      {
        name: 'Suivi',
        type: 'line',
        data: backlinkSummaryFollowedCount,
        symbolSize: 10
      },
      {
        name: 'Non Suivi',
        type: 'line',
        data: backlinkSummaryNotFollowedCount,
        symbolSize: 10
      }
    ],
    legend: {},
    color: [getColor('primary'), getColor('success'), getColor('danger')],
    xAxis: {
      type: 'category',
      data: backlinkSummaryDates
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const backlinkColumns = [
    {
      accessor: 'date',
      Header: 'Date'
    },
    {
      accessor: 'backlinks',
      Header: 'Backlinks'
    },
    {
      accessor: 'followed',
      Header: 'Suivi'
    },
    {
      accessor: 'notFollowed',
      Header: 'Non Suivi'
    },
    {
      accessor: 'actions',
      Header: 'Actions'
    }
  ];

  const backlinkData = [];
  data?.backlinks?.map(item => {
    backlinkData.push({
      date: new Date(item.createdAt).toLocaleDateString(),
      backlinks: (
        <SoftBadge pill bg={'primary'}>
          {item.backlinksCount}
        </SoftBadge>
      ),
      followed: (
        <SoftBadge pill bg={'success'}>
          {item.followedLinksCount}
        </SoftBadge>
      ),
      notFollowed: (
        <SoftBadge pill bg={'danger'}>
          {item.notFollowedLinksCount}
        </SoftBadge>
      ),
      actions: (
        <>
          <OverlayTrigger
            placement={'top'}
            overlay={<Tooltip>{"Voir l'analyse backlink"}</Tooltip>}
          >
            <a href={`/backlink/${item.id}/`} className="mx-1">
              <EyeLineIcon />
            </a>
          </OverlayTrigger>
        </>
      )
    });
  });

  return (
    <>
      <div className="my-3 p-3">
        <Row>
          <Col xl={3}>
            {data?.isSiteAnalyzesEnabled && (
              <Flex alignItems={'center'} direction={'column'}>
                <Image
                  className="img-fluid"
                  src={
                    lastCrawl?.analysisData[0]?.data?.overview?.screenshotUrl
                  }
                />
                <SoftBadge className="my-2" pill bg={issuesColor}>
                  {lastCrawl?.issuesCount}&nbsp;Problème
                  {lastCrawl?.issuesCount != 1 && 's'}
                </SoftBadge>
              </Flex>
            )}
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{aiReportData.backlink.title}</h2>
              <p>{aiReportData.backlink.description}</p>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="my-3 p-3">
        <h3 className="fs-1 text-center">Résumé</h3>
        <EChartsReactCore
          echarts={echarts}
          option={backlinkSummaryChartOption}
        />
      </div>
      <div className="my-3 p-3">
        <CustomAdvanceTable columns={backlinkColumns} data={backlinkData} />
      </div>
    </>
  );
};

ReportDetailsBacklink.propTypes = {
  data: PropTypes.object
};

export default ReportDetailsBacklink;
