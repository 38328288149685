import { Button, Modal } from 'react-bootstrap';

import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import PropTypes from 'prop-types';
import React from 'react';

const CustomModal = ({ isShown, setIsShown, title, description, content }) => {
  return (
    <>
      <Modal show={isShown} onHide={() => setIsShown(false)} size="lg" centered>
        <div className="app-modal">
          <div className="app-modal-header">
            <span className="app-modal-header-title">{title}</span>
            <Button
              className="app-modal-header-button"
              onClick={() => setIsShown(false)}
            >
              <CloseLineIcon />
            </Button>
          </div>
          {description && (
            <div className="app-modal-description">{description}</div>
          )}
          {content}
        </div>
      </Modal>
    </>
  );
};

CustomModal.propTypes = {
  isShown: PropTypes.bool,
  setIsShown: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default CustomModal;
