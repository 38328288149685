import { Navigate, Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';

import AccountDetails from 'components/anopseo/settings/AccountDetails';
import Anopseo from 'components/anopseo/dashboard';
import BacklinkDetails from 'components/anopseo/backlink/BacklinkDetails';
import BacklinkList from 'components/anopseo/backlink/BacklinkList';
import BenchmarkDetails from 'components/anopseo/benchmark/BenchmarkDetails';
import BenchmarkList from 'components/anopseo/benchmark/BenchmarkList';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import ErrorLayout from './ErrorLayout';
import InvoiceList from 'components/anopseo/settings/InvoiceList';
import MainLayout from './MainLayout';
import MonitorDetails from 'components/anopseo/monitor/MonitorDetails';
import MonitorList from 'components/anopseo/monitor/MonitorList';
import PageAnalysisDetails from 'components/anopseo/page-analysis/PageAnalysisDetails';
import PageAnalysisList from 'components/anopseo/page-analysis/PageAnalysisList';
import ReportDetails from 'components/anopseo/report/ReportDetails';
import ReportList from 'components/anopseo/report/ReportList';
import ResetPassword from 'ResetPassword';
import SiteAnalysisDetails from 'components/anopseo/site-analysis/SiteAnalysisDetails';
import SiteAnalysisList from 'components/anopseo/site-analysis/SiteAnalysisList';
import SiteAnalysisPage from 'components/anopseo/site-analysis/SiteAnalysisPage';
import SocialDetails from 'components/anopseo/social/SocialDetails';
import SocialList from 'components/anopseo/social/SocialList';
import SupportDetails from 'components/anopseo/settings/SupportDetails';
import SupportList from 'components/anopseo/settings/SupportList';
import ToastInLayout from './ToastInLayout';
import TrackingDetails from 'components/anopseo/tracking/TrackingDetails';
import TrackingList from 'components/anopseo/tracking/TrackingList';
import UserList from 'components/anopseo/settings/UserList';
import WriterDetails from 'components/anopseo/writer/WriterDetails';
import WriterList from 'components/anopseo/writer/WriterList';
import is from 'is_js';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  return (
    <>
      <ToastInLayout />
      <Routes>
        <Route path="reset-password" element={<ResetPassword />} />

        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/* Dashboard */}
          <Route path="/" element={<Anopseo />} />
          <Route path="page-analysis" element={<PageAnalysisList />} />
          <Route path="page-analysis/:id" element={<PageAnalysisDetails />} />
          <Route path="site-analysis" element={<SiteAnalysisList />} />
          <Route path="site-analysis/:id" element={<SiteAnalysisDetails />} />
          <Route
            path="site-analysis/:id/page/:analysisId"
            element={<SiteAnalysisPage />}
          />
          <Route path="backlink" element={<BacklinkList />} />
          <Route path="backlink/:id" element={<BacklinkDetails />} />
          <Route path="benchmark" element={<BenchmarkList />} />
          <Route path="benchmark/:id" element={<BenchmarkDetails />} />
          <Route path="tracking" element={<TrackingList />} />
          <Route path="tracking/:id" element={<TrackingDetails />} />
          <Route path="social" element={<SocialList />} />
          <Route path="social/:id" element={<SocialDetails />} />
          <Route path="monitor" element={<MonitorList />} />
          <Route path="monitor/:id" element={<MonitorDetails />} />
          <Route path="writer" element={<WriterList />} />
          <Route path="writer/:id" element={<WriterDetails />} />
          <Route path="report" element={<ReportList />} />
          <Route path="report/:id" element={<ReportDetails />} />
          <Route path="my-profile" element={<AccountDetails />} />
          <Route path="support" element={<SupportList />} />
          <Route path="support/:id" element={<SupportDetails />} />
          <Route path="invoice-list" element={<InvoiceList />} />
          <Route path="user-list" element={<UserList />} />
        </Route>

        {/* //--- MainLayout end  */}

        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
    </>
  );
};

export default Layout;
