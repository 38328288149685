import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddLineIcon from 'remixicon-react/AddLineIcon';
import AppContext from 'context/Context';
import CustomModal from '../common/CustomModal';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

const Support = ({ support, index }) => {
  const { id, createdAt, updatedAt, title, status } = support;

  const createdAtDate = new Date(createdAt).toLocaleString();
  const updatedAtDate = new Date(updatedAt).toLocaleString();

  return (
    <div
      className={`timeline-item ${
        index % 2 == 0 ? 'timeline-item-start' : 'timeline-item-end'
      }`}
    >
      <div className="timeline-icon icon-item icon-item-lg text-primary border-300">
        <FontAwesomeIcon icon={'envelope'} className="fs-1" />
      </div>
      <Row
        className={`${
          index % 2 == 0 ? 'timeline-item-start' : 'timeline-item-end'
        }`}
      >
        <Col lg={6} className="timeline-item-time">
          <div>
            <h6 className="mb-0 text-700">Créé le :</h6>
            <p className="fs--2 text-500 font-sans-serif">{createdAtDate}</p>
          </div>
        </Col>
        <Col lg={6}>
          <div className="timeline-item-content arrow-bg-white">
            <div className="timeline-item-card bg-white dark__bg-1100">
              <Link to={`/support/${id}`}>
                <h5 className="mb-2 hover-primary">{title}</h5>
              </Link>
              <p className="border-bottom mb-3 pb-4 text-600 fs--1">{id}</p>
              <Flex wrap="wrap" className="pt-2">
                <h6 className="mb-0 text-600 lh-base">
                  Mis à jour le : {updatedAtDate}
                </h6>
                {status == 'open' && (
                  <SoftBadge className="ms-auto" pill bg={'info'}>
                    Ouvert
                  </SoftBadge>
                )}
                {status == 'closed' && (
                  <SoftBadge className="ms-auto" pill bg={'success'}>
                    Fermé
                  </SoftBadge>
                )}
              </Flex>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

Support.propTypes = {
  support: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string
  }),
  index: PropTypes.number
};

const Header = () => {
  const { t } = useTranslation();
  const {
    config: { token }
  } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);

  const handleFormSubmit = async e => {
    e.preventDefault();
    setModalShow(false);
    const response = await fetch(`${process.env.REACT_APP_API_URL}supports`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: e.target.title.value,
        message: e.target.message.value
      })
    });
    const json = await response.json();
    e.target.reset();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
    }
  };

  return (
    <>
      <div className="app-list-header">
        <h2 className="app-list-title">Assistance</h2>
        <Button className="app-list-button" onClick={() => setModalShow(true)}>
          <Flex className="gap-1" alignItems={'center'}>
            <AddLineIcon className="app-list-button-icon" />
            <span>Ajouter un nouveau ticket</span>
          </Flex>
        </Button>
        <div className="app-list-description">
          <p>
            Vous rencontrez un problème ou vous avez une question sur un rapport
            généré ? Vous devez créer un ticket de support afin qu'on puisse
            vous répondre par ticket et si nécessaire par mail. Veuillez
            détailler au maximum le problème que avez rencontrez afin qu'on
            puisse vous répondre de façon pertinente.
          </p>
        </div>
      </div>
      <CustomModal
        isShown={modalShow}
        setIsShown={setModalShow}
        title="Assistance"
        content={
          <Form className="app-modal-form" onSubmit={handleFormSubmit}>
            <Form.Label htmlFor="modalTitle">Titre</Form.Label>
            <Form.Control
              id="modalTitle"
              name="title"
              type="text"
              required={true}
            />
            <Form.Label htmlFor="modalMessage">Message</Form.Label>
            <Form.Control
              id="modalMessage"
              as="textarea"
              name="message"
              type="text"
              required={true}
              rows="5"
            />
            <Button className="app-modal-submit-button" type="submit">
              Soumettre
            </Button>
          </Form>
        }
      />
    </>
  );
};

export const List = ({ maxResults = null }) => {
  const { lastMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    shouldReconnect: () => true
  });
  const {
    config: { userId, token }
  } = useContext(AppContext);
  const [supports, setSupports] = useState([]);
  const [isFetchFinished, setIsFetchFinished] = useState(false);

  const fetchSupports = useCallback(async () => {
    const supportsResponse = await fetch(
      `${process.env.REACT_APP_API_URL}supports`,
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    );
    const json = await supportsResponse.json();
    if (maxResults != null) {
      setSupports(json.slice(0, maxResults));
    } else {
      setSupports(json);
    }
    setIsFetchFinished(true);
  }, []);

  useEffect(() => {
    fetchSupports();
  }, [fetchSupports]);

  useEffect(() => {
    if (lastMessage == null) {
      return;
    }
    const data = JSON.parse(lastMessage.data);
    if (data != null) {
      if (data.userId == userId) {
        if (data.from == 'supports') {
          fetchSupports();
        }
      }
    }
  }, [fetchSupports, lastMessage]);

  return (
    <>
      {isFetchFinished ? (
        supports?.length == 0 ? (
          <Flex justifyContent={'center'}>
            <p className="text-center fw-bold my-3">Aucun support trouvé</p>
          </Flex>
        ) : (
          <div className="timeline-vertical m-3">
            {supports?.map((support, index) => (
              <Support support={support} key={support.id} index={index} />
            ))}
          </div>
        )
      ) : (
        <Flex justifyContent={'center'}>
          <Spinner className="my-3" animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Flex>
      )}
    </>
  );
};

List.propTypes = {
  maxResults: PropTypes.number
};

const SupportList = () => {
  return (
    <>
      <Header />
      <List />
    </>
  );
};

export default SupportList;
