import * as echarts from 'echarts';

import { Alert, Col, Image, ProgressBar, Row } from 'react-bootstrap';

import EChartsReactCore from 'echarts-for-react/lib/core';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import { PropTypes } from 'prop-types';
import React from 'react';
import SoftBadge from 'components/common/SoftBadge';
import { aiReportData } from 'data/anopseo/report';
import { getColor } from 'helpers/utils';

const ReportDetailsOverview = ({ data }) => {
  const lastCrawl = data?.siteAnalyzes?.slice(-1)[0];
  const lastBacklink = data?.backlinks?.slice(-1)[0];
  const lastBenchmark = data?.benchmarks?.slice(-1)[0];
  const lastSocial = data?.socials?.slice(-1)[0];

  const followedLinksPercentage =
    (lastBacklink?.followedLinksCount * 100) / lastBacklink?.backlinksCount;
  const notFollowedLinksPercentage =
    (lastBacklink?.notFollowedLinksCount * 100) / lastBacklink?.backlinksCount;
  const referringDomainsPercentage =
    (lastBacklink?.referringDomainsCount * 100) / lastBacklink?.backlinksCount;
  const referringIpsPercentage =
    (lastBacklink?.referringIpsCount * 100) / lastBacklink?.backlinksCount;
  const highSpamPercentage =
    (lastBacklink?.highSpams * 100) / lastBacklink?.backlinksCount;
  const issuesColor =
    lastCrawl?.issuesCount < 10
      ? lastCrawl?.issuesCount < 5
        ? 'primary'
        : 'warning'
      : 'danger';
  const scoreColor =
    lastCrawl?.domainScore > 30
      ? lastCrawl?.domainScore > 70
        ? 'danger'
        : 'warning'
      : 'success';
  const domainColor =
    lastBacklink?.domainScore > 30
      ? lastBacklink?.domainScore > 70
        ? 'success'
        : 'warning'
      : 'danger';
  const spamColor =
    lastBacklink?.domainSpamScore >= 31
      ? lastBacklink?.domainSpamScore >= 61
        ? 'danger'
        : 'warning'
      : 'success';

  const crawlScoreChartOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(scoreColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: lastCrawl?.score,
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ],
        detail: {
          formatter: '{value}%'
        }
      }
    ]
  };
  const crawlSummaryOption = {
    series: [
      {
        name: 'Corrects',
        type: 'bar',
        stack: 'total',
        data: [
          lastCrawl?.overview?.searchablesCount,
          lastCrawl?.overview?.indexablesCount,
          lastCrawl?.overview?.notDuplicatedsCount,
          lastCrawl?.overview?.contentSufficientsCount,
          lastCrawl?.overview?.compliantsCount,
          lastCrawl?.overview?.notBrokensCount,
          lastCrawl?.overview?.notRedirectedsCount
        ]
      },
      {
        name: 'Problèmes',
        type: 'bar',
        stack: 'total',
        data: [
          lastCrawl?.pagesCount - lastCrawl?.overview?.searchablesCount,
          lastCrawl?.pagesCount - lastCrawl?.overview?.indexablesCount,
          lastCrawl?.pagesCount - lastCrawl?.overview?.notDuplicatedsCount,
          lastCrawl?.pagesCount - lastCrawl?.overview?.contentSufficientsCount,
          lastCrawl?.pagesCount - lastCrawl?.overview?.compliantsCount,
          lastCrawl?.pagesCount - lastCrawl?.overview?.notBrokensCount,
          lastCrawl?.pagesCount - lastCrawl?.overview?.notRedirectedsCount
        ]
      }
    ],
    color: [getColor('success'), getColor('danger')],
    xAxis: {
      type: 'category',
      data: [
        'Exploration',
        'Indexation',
        'URLs Dupliquées',
        'Contenu',
        'Performance',
        'URLs brisées',
        'Redirections'
      ]
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const backlinkFollowedLinkOption = {
    series: [
      {
        type: 'pie',
        radius: ['40%', '80%'],
        label: {
          show: false,
          position: 'center'
        },
        data: [
          {
            value: lastBacklink?.followedLinksCount,
            name: 'Suivi',
            itemStyle: {
              color: getColor('success')
            }
          },
          {
            value: lastBacklink?.notFollowedLinksCount,
            name: 'Non suivi',
            itemStyle: {
              color: getColor('danger')
            }
          }
        ]
      }
    ],
    legend: {
      bottom: 'bottom'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const backlinkDomainOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(domainColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: lastBacklink?.domainScore,
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ]
      }
    ]
  };
  const backlinkSpamOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(spamColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: lastBacklink?.domainSpamScore,
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ],
        detail: {
          formatter: '{value}'
        }
      }
    ]
  };
  const socialSummaryOption = {
    series: [
      {
        type: 'pie',
        radius: ['40%', '80%'],
        label: {
          show: false,
          position: 'center'
        },
        data: [
          {
            value: lastSocial?.positivesCount,
            name: 'Positif',
            itemStyle: {
              color: getColor('success')
            }
          },
          {
            value: lastSocial?.neutralsCount,
            name: 'Neutre',
            itemStyle: {
              color: getColor('secondary')
            }
          },
          {
            value: lastSocial?.negativesCount,
            name: 'Négatif',
            itemStyle: {
              color: getColor('danger')
            }
          }
        ]
      }
    ],
    legend: {
      bottom: 'bottom'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };

  return (
    <>
      <div className="my-3 p-3">
        <Row>
          <Col xl={3}>
            {data?.isSiteAnalyzesEnabled && (
              <Flex alignItems={'center'} direction={'column'}>
                <Image
                  className="img-fluid"
                  src={
                    lastCrawl?.analysisData[0]?.data?.overview?.screenshotUrl
                  }
                />
                <SoftBadge className="my-2" pill bg={issuesColor}>
                  {lastCrawl?.issuesCount}&nbsp;Problème
                  {lastCrawl?.issuesCount != 1 && 's'}
                </SoftBadge>
              </Flex>
            )}
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{aiReportData.overview.title}</h2>
              <p>{aiReportData.overview.description}</p>
            </Flex>
          </Col>
        </Row>
      </div>
      {data?.isSiteAnalyzesEnabled && (
        <div className="my-3 p-3">
          <Flex alignItems={'center'} direction={'column'}>
            <h3>Crawl</h3>
            {!data?.siteAnalyzesIsOnError && (
              <a href={`/site-analysis/${lastCrawl?.id}/`}>
                <Flex className="gap-2" alignItems={'center'}>
                  <EyeLineIcon />
                  Voir
                </Flex>
              </a>
            )}
          </Flex>
          <Row>
            <Col xl={3}>
              <h3 className="fs-1 text-center">Score total</h3>
              <EChartsReactCore
                echarts={echarts}
                option={crawlScoreChartOption}
              />
            </Col>
            <Col className="border-start" xl={9}>
              <h3 className="fs-1 text-center">Résumé</h3>
              <EChartsReactCore echarts={echarts} option={crawlSummaryOption} />
            </Col>
          </Row>
        </div>
      )}
      {data?.isBacklinksEnabled && (
        <div className="my-3 p-3">
          <Flex alignItems={'center'} direction={'column'}>
            <h3>Backlinks</h3>
            {!data?.backlinksIsOnError && (
              <a href={`/backlink/${lastBacklink?.id}/`}>
                <Flex className="gap-2" alignItems={'center'}>
                  <EyeLineIcon />
                  Voir
                </Flex>
              </a>
            )}
          </Flex>
          {data?.backlinksIsOnError ? (
            <Alert variant={'danger'}>
              {data?.backlinksErrorMessages?.map((errorMessage, index) => (
                <p key={`backlinkErrorMessage${index}`}>{errorMessage}</p>
              ))}
            </Alert>
          ) : (
            <Row>
              <Col xl={4}>
                <h3 className="fs-1">Résumé</h3>
                <EChartsReactCore
                  echarts={echarts}
                  option={backlinkFollowedLinkOption}
                />
                <Flex
                  className="border-bottom text-center p-2 gap-2 w-100"
                  direction={'column'}
                >
                  <span className="fw-bold">Backlinks</span>
                  <span>{lastBacklink?.backlinksCount}</span>
                </Flex>
                <Flex
                  className="border-bottom text-center p-2 gap-2 w-100"
                  direction={'column'}
                >
                  <span className="fw-bold">Suivi</span>
                  <span>
                    {lastBacklink?.followedLinksCount} &#40;
                    {followedLinksPercentage}&nbsp;&#37;&#41;
                  </span>
                  <ProgressBar now={followedLinksPercentage} />
                </Flex>
                <Flex
                  className="text-center p-2 gap-2 w-100"
                  direction={'column'}
                >
                  <span className="fw-bold">Non Suivi</span>
                  <span>
                    {lastBacklink?.notFollowedLinksCount} &#40;
                    {notFollowedLinksPercentage}&nbsp;&#37;&#41;
                  </span>
                  <ProgressBar now={notFollowedLinksPercentage} />
                </Flex>
              </Col>
              <Col className="border-start border-end" xl={4}>
                <h3 className="fs-1">Influence du Domaine</h3>
                <EChartsReactCore
                  echarts={echarts}
                  option={backlinkDomainOption}
                />
                <Flex
                  className="border-bottom text-center p-2 gap-2 w-100"
                  direction={'column'}
                >
                  <span className="fw-bold">URLs Référentes</span>
                  <span>{lastBacklink?.backlinksCount}</span>
                </Flex>
                <Flex
                  className="border-bottom text-center p-2 gap-2 w-100"
                  direction={'column'}
                >
                  <span className="fw-bold">Domaines Référents</span>
                  <span>
                    {lastBacklink?.referringDomainsCount} &#40;
                    {referringDomainsPercentage}&nbsp;&#37;&#41;
                  </span>
                  <ProgressBar now={referringDomainsPercentage} />
                </Flex>
                <Flex
                  className="text-center p-2 gap-2 w-100"
                  direction={'column'}
                >
                  <span className="fw-bold">Ips Référents</span>
                  <span>
                    {lastBacklink?.referringIpsCount} &#40;
                    {referringIpsPercentage}&nbsp;&#37;&#41;
                  </span>
                  <ProgressBar now={referringIpsPercentage} />
                </Flex>
              </Col>
              <Col xl={4}>
                <h3 className="fs-1">Évaluation du Spam</h3>
                <EChartsReactCore
                  echarts={echarts}
                  option={backlinkSpamOption}
                />
                <Flex
                  className="text-center p-2 gap-2 w-100"
                  direction={'column'}
                >
                  <span className="fw-bold">
                    Liens d'Évaluation de Spam Élevé
                  </span>
                  <span>
                    {lastBacklink?.highSpamsCount} &#40;
                    {highSpamPercentage}&nbsp;&#37;&#41;
                  </span>
                  <ProgressBar now={highSpamPercentage} />
                </Flex>
              </Col>
            </Row>
          )}
        </div>
      )}
      {data?.isBenchmarksEnabled && (
        <div className="my-3 p-3">
          <Flex alignItems={'center'} direction={'column'}>
            <h3>Seo Benchmark</h3>
            {!data?.benchmarksIsOnError && (
              <a href={`/benchmark/${lastBenchmark?.id}/`}>
                <Flex className="gap-2" alignItems={'center'}>
                  <EyeLineIcon />
                  Voir
                </Flex>
              </a>
            )}
          </Flex>
          {data?.benchmarksIsOnError ? (
            <Alert variant={'danger'}>
              {data?.benchmarksErrorMessages?.map((errorMessage, index) => (
                <p key={`benchmarkErrorMessage${index}`}>{errorMessage}</p>
              ))}
            </Alert>
          ) : (
            <>
              <Flex
                className="border-bottom text-center p-2 gap-2 w-100"
                alignItems={'center'}
                direction={'column'}
              >
                <span className="fw-bold">Domaine</span>
                <span>{lastBenchmark?.domain}</span>
              </Flex>
              <Flex
                className="border-bottom text-center p-2 gap-2 w-100"
                alignItems={'center'}
                direction={'column'}
              >
                <span className="fw-bold">Nombre de résultats</span>
                <span>{lastBenchmark?.keywordsResultsCount}</span>
              </Flex>
              <Flex
                className="border-bottom text-center p-2 gap-2 w-100"
                alignItems={'center'}
                direction={'column'}
              >
                <span className="fw-bold">Nombre de visites</span>
                <span>{lastBenchmark?.numberOfVisits}</span>
              </Flex>
              <Flex
                className="border-bottom text-center p-2 gap-2 w-100"
                alignItems={'center'}
                direction={'column'}
              >
                <span className="fw-bold">Moyenne de pages vues</span>
                <span>{lastBenchmark?.averagePageViews}</span>
              </Flex>
              <Flex
                className="border-bottom text-center p-2 gap-2 w-100"
                alignItems={'center'}
                direction={'column'}
              >
                <span className="fw-bold">Taux de rebond</span>
                <span>{lastBenchmark?.bounceRate}</span>
              </Flex>
              <Flex
                className="border-bottom text-center p-2 gap-2 w-100"
                alignItems={'center'}
                direction={'column'}
              >
                <span className="fw-bold">Temps passé sur le site</span>
                <span>{lastBenchmark?.timeSpentOnSite} secondes</span>
              </Flex>
            </>
          )}
        </div>
      )}
      {data?.isSocialsEnabled && (
        <div className="my-3 p-3">
          <Flex alignItems={'center'} direction={'column'}>
            <h3>Social Signal</h3>
            {!data?.socialsIsOnError && (
              <a href={`/social/${lastSocial?.id}/`}>
                <Flex className="gap-2" alignItems={'center'}>
                  <EyeLineIcon />
                  Voir
                </Flex>
              </a>
            )}
          </Flex>
          {data?.socialsIsOnError ? (
            <Alert variant={'danger'}>
              {data?.socialsErrorMessages?.map((errorMessage, index) => (
                <p key={`socialsErrorMessage${index}`}>{errorMessage}</p>
              ))}
            </Alert>
          ) : (
            <Row>
              <Col xl={3}>
                <h3 className="fs-1 text-center">Résumé</h3>
                <EChartsReactCore
                  echarts={echarts}
                  option={socialSummaryOption}
                />
              </Col>
              <Col className="border-start" xl={9}>
                <Flex
                  className="border-bottom text-center p-2 gap-2 w-100"
                  alignItems={'center'}
                  direction={'column'}
                >
                  <span className="fw-bold">Mentions Utilisateur</span>
                  <span>{lastSocial?.userMentionsCount}</span>
                </Flex>
                <Flex
                  className="border-bottom text-center p-2 gap-2 w-100"
                  alignItems={'center'}
                  direction={'column'}
                >
                  <span className="fw-bold">Utilisateurs</span>
                  <span>{lastSocial?.usersCount}</span>
                </Flex>
                <Flex
                  className="border-bottom text-center p-2 gap-2 w-100"
                  alignItems={'center'}
                  direction={'column'}
                >
                  <span className="fw-bold">Positif</span>
                  <span>{lastSocial?.positivesCount}</span>
                </Flex>
                <Flex
                  className="border-bottom text-center p-2 gap-2 w-100"
                  alignItems={'center'}
                  direction={'column'}
                >
                  <span className="fw-bold">Neutre</span>
                  <span>{lastSocial?.neutralsCount}</span>
                </Flex>
                <Flex
                  className="text-center p-2 gap-2 w-100"
                  alignItems={'center'}
                  direction={'column'}
                >
                  <span className="fw-bold">Négatif</span>
                  <span>{lastSocial?.negativesCount}</span>
                </Flex>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

ReportDetailsOverview.propTypes = {
  data: PropTypes.object
};

export default ReportDetailsOverview;
