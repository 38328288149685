import * as echarts from 'echarts';

import {
  Col,
  Image,
  OverlayTrigger,
  ProgressBar,
  Row,
  Tooltip
} from 'react-bootstrap';

import CustomAdvanceTable from 'components/anopseo/common/CustomAdvanceTable';
import EChartsReactCore from 'echarts-for-react/lib/core';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import Flex from 'components/common/Flex';
import { PropTypes } from 'prop-types';
import React from 'react';
import SoftBadge from 'components/common/SoftBadge';
import { aiReportData } from 'data/anopseo/report';
import { getColor } from 'helpers/utils';

const ReportDetailsCrawler = ({ data }) => {
  const lastCrawl = data?.siteAnalyzes?.slice(-1)[0];

  const crawlSummaryDates = [];
  const crawlSummaryScores = [];
  const crawlSummaryIssues = [];
  const crawlSummaryPagesCount = [];
  const crawlSummarySearchablesCount = [];
  const crawlSummaryNotSearchablesCount = [];
  const crawlSummaryIndexablesCount = [];
  const crawlSummaryNotIndexablesCount = [];
  const crawlSummaryNotDuplicatedsCount = [];
  const crawlSummaryDuplicatedsCount = [];
  const crawlSummaryContentSufficientsCount = [];
  const crawlSummaryNotContentSufficientsCount = [];
  const crawlSummaryCompliantsCount = [];
  const crawlSummaryNotCompliantsCount = [];
  const crawlSummaryNotBrokensCount = [];
  const crawlSummaryBrokensCount = [];
  const crawlSummaryNotRedirectedsCount = [];
  const crawlSummaryRedirectedsCount = [];
  data?.siteAnalyzes?.map(item => {
    crawlSummaryDates.push(new Date(item.createdAt).toLocaleDateString());
    crawlSummaryScores.push(item.score);
    crawlSummaryIssues.push(item.issuesCount);
    crawlSummaryPagesCount.push(item.pagesCount);
    crawlSummarySearchablesCount.push(item.overview.searchablesCount);
    crawlSummaryNotSearchablesCount.push(
      item.pagesCount - item.overview.searchablesCount
    );
    crawlSummaryIndexablesCount.push(item.overview.indexablesCount);
    crawlSummaryNotIndexablesCount.push(
      item.pagesCount - item.overview.indexablesCount
    );
    crawlSummaryNotDuplicatedsCount.push(item.overview.notDuplicatedsCount);
    crawlSummaryDuplicatedsCount.push(
      item.pagesCount - item.overview.notDuplicatedsCount
    );
    crawlSummaryContentSufficientsCount.push(
      item.overview.contentSufficientCount
    );
    crawlSummaryNotContentSufficientsCount.push(
      item.pagesCount - item.overview.contentSufficientCount
    );
    crawlSummaryCompliantsCount.push(item.overview.compliantsCount);
    crawlSummaryNotCompliantsCount.push(
      item.pagesCount - item.overview.compliantsCount
    );
    crawlSummaryNotBrokensCount.push(item.overview.notBrokensCount);
    crawlSummaryBrokensCount.push(
      item.pagesCount - item.overview.notBrokensCount
    );
    crawlSummaryNotRedirectedsCount.push(item.overview.notRedirectedsCount);
    crawlSummaryRedirectedsCount.push(
      item.pagesCount - item.overview.notRedirectedsCount
    );
  });

  const issuesColor =
    lastCrawl?.issuesCount < 10
      ? lastCrawl?.issuesCount < 5
        ? 'primary'
        : 'warning'
      : 'danger';

  const crawlScoreChartOption = {
    series: [
      {
        name: 'Score',
        type: 'bar',
        data: crawlSummaryScores
      }
    ],
    color: getColor('success'),
    xAxis: {
      type: 'category',
      data: crawlSummaryDates
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const crawlIssueChartOption = {
    series: [
      {
        name: 'Problèmes',
        type: 'bar',
        data: crawlSummaryIssues
      }
    ],
    color: getColor('danger'),
    xAxis: {
      type: 'category',
      data: crawlSummaryDates
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const crawlSummaryChartOption = {
    series: [
      {
        name: 'Découverts',
        type: 'line',
        data: crawlSummaryPagesCount,
        symbolSize: 10
      },
      {
        name: 'Explorables',
        type: 'line',
        data: crawlSummarySearchablesCount,
        symbolSize: 10
      },
      {
        name: 'Non explorables',
        type: 'line',
        data: crawlSummaryNotSearchablesCount,
        symbolSize: 10
      },
      {
        name: 'Indexés',
        type: 'line',
        data: crawlSummaryIndexablesCount,
        symbolSize: 10
      },
      {
        name: 'Non indexés',
        type: 'line',
        data: crawlSummaryNotIndexablesCount,
        symbolSize: 10
      },
      {
        name: 'Dupliqués',
        type: 'line',
        data: crawlSummaryDuplicatedsCount,
        symbolSize: 10
      },
      {
        name: 'Non dupliqués',
        type: 'line',
        data: crawlSummaryNotDuplicatedsCount,
        symbolSize: 10
      },
      {
        name: 'Contenus suffisants',
        type: 'line',
        data: crawlSummaryContentSufficientsCount,
        symbolSize: 10
      },
      {
        name: 'Contenu insuffisants',
        type: 'line',
        data: crawlSummaryNotContentSufficientsCount,
        symbolSize: 10
      },
      {
        name: 'Conformes',
        type: 'line',
        data: crawlSummaryCompliantsCount,
        symbolSize: 10
      },
      {
        name: 'Lents',
        type: 'line',
        data: crawlSummaryNotCompliantsCount,
        symbolSize: 10
      },
      {
        name: 'Valides',
        type: 'line',
        data: crawlSummaryNotBrokensCount,
        symbolSize: 10
      },
      {
        name: 'Brisés',
        type: 'line',
        data: crawlSummaryBrokensCount,
        symbolSize: 10
      },
      {
        name: 'Redirigés',
        type: 'line',
        data: crawlSummaryRedirectedsCount,
        symbolSize: 10
      },
      {
        name: 'Non redirigés',
        type: 'line',
        data: crawlSummaryNotRedirectedsCount,
        symbolSize: 10
      }
    ],
    legend: {},
    color: [
      '#000000',
      '#44bd32',
      '#0097a7',
      '#0c12bf',
      '#891233',
      '#a55a60',
      '#27aebf',
      '#ff863d',
      '#e70289',
      '#52a0ca',
      '#eec62f',
      '#1c8ff5',
      '#e84118',
      '#e07480',
      '#ca9f2d'
    ],
    xAxis: {
      type: 'category',
      data: crawlSummaryDates
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };
  const crawlColumns = [
    {
      accessor: 'date',
      Header: 'Date'
    },
    {
      accessor: 'found',
      Header: 'Découverts'
    },
    {
      accessor: 'score',
      Header: 'Score'
    },
    {
      accessor: 'issues',
      Header: 'Problèmes'
    },
    {
      accessor: 'actions',
      Header: 'Actions'
    }
  ];
  const crawlData = [];
  data?.siteAnalyzes?.map(item => {
    const scoreColor =
      item.score > 30 ? (item.score > 70 ? 'success' : 'warning') : 'danger';
    const issuesColor =
      item?.issuesCount < 10
        ? item?.issuesCount < 5
          ? 'primary'
          : 'warning'
        : 'danger';
    crawlData.push({
      date: new Date(item.createdAt).toLocaleDateString(),
      found: (
        <>
          <SoftBadge pill bg={'primary'}>
            {item.pagesCount}
          </SoftBadge>
        </>
      ),
      score: (
        <>
          <Flex
            className="h-100 gap-2"
            justifyContent={'start'}
            alignItems={'center'}
          >
            <ProgressBar
              now={item.score}
              style={{ width: '80px', height: '8px' }}
              variant={scoreColor}
            />
            <p className="mb-0">{item.score}&nbsp;%</p>
          </Flex>
        </>
      ),
      issues: (
        <>
          <SoftBadge className="my-2" pill bg={issuesColor}>
            {item.issuesCount}&nbsp;Problème
            {item.issuesCount != 1 && 's'}
          </SoftBadge>
        </>
      ),
      actions: (
        <>
          <OverlayTrigger
            placement={'top'}
            overlay={<Tooltip>{"Voir l'analyse crawl"}</Tooltip>}
          >
            <a href={`/site-analysis/${item.id}/`} className="mx-1">
              <EyeLineIcon />
            </a>
          </OverlayTrigger>
        </>
      )
    });
  });

  return (
    <>
      <div className="my-3 p-3">
        <Row>
          <Col xl={3}>
            {data?.isSiteAnalyzesEnabled && (
              <Flex alignItems={'center'} direction={'column'}>
                <Image
                  className="img-fluid"
                  src={
                    lastCrawl?.analysisData[0]?.data?.overview?.screenshotUrl
                  }
                />
                <SoftBadge className="my-2" pill bg={issuesColor}>
                  {lastCrawl?.issuesCount}&nbsp;Problème
                  {lastCrawl?.issuesCount != 1 && 's'}
                </SoftBadge>
              </Flex>
            )}
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{aiReportData.crawl.title}</h2>
              <p>{aiReportData.crawl.description}</p>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="my-3 p-3">
        <Row>
          <Col xl={6}>
            <h3 className="fs-1 text-center">Score</h3>
            <EChartsReactCore
              echarts={echarts}
              option={crawlScoreChartOption}
            />
          </Col>
          <Col className="border-start" xl={6}>
            <h3 className="fs-1 text-center">Problèmes</h3>
            <EChartsReactCore
              echarts={echarts}
              option={crawlIssueChartOption}
            />
          </Col>
        </Row>
      </div>
      <div className="my-3 p-3">
        <h3 className="fs-1 text-center">Résumé</h3>
        <EChartsReactCore echarts={echarts} option={crawlSummaryChartOption} />
      </div>
      <div className="my-3 p-3">
        <CustomAdvanceTable columns={crawlColumns} data={crawlData} />
      </div>
    </>
  );
};

ReportDetailsCrawler.propTypes = {
  data: PropTypes.object
};

export default ReportDetailsCrawler;
