import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import { toast } from 'react-toastify';
import { toastOptions } from 'data/anopseo/toast-options';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isPasswordConfirmationShown, setIsPasswordConfirmationShown] =
    useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    if (e.target.password.value != e.target.passwordConfirmation.value) {
      toast.error(
        'The password and its confirmation must be identical',
        toastOptions
      );
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}users/reset-password`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          passwordResetToken: searchParams.get('token'),
          password: e.target.password.value,
          passwordConfirmation: e.target.passwordConfirmation.value
        })
      }
    );
    const json = await response.json();
    if (!response.ok) {
      toast.error(t(json?.message), toastOptions);
    } else {
      toast.success(t(json?.message), toastOptions);
      navigate('/');
    }
  };

  const verifyToken = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}users/reset-password/validate`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          passwordResetToken: searchParams.get('token')
        })
      }
    );
    if (!response.ok) {
      toast.error(t('Invalid password reset token'), toastOptions);
      navigate('/');
    }
  }, [searchParams]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col xl={6}>
            <Flex
              justifyContent="center"
              direction="column"
              className="min-vh-100"
            >
              <Logo at={'login-page'} />
              <Form onSubmit={handleSubmit}>
                <Form.Label htmlFor="resetPassword">{t('Password')}</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="resetPassword"
                    name="password"
                    type={isPasswordShown ? 'text' : 'password'}
                    required={true}
                  />
                  <InputGroup.Text
                    className="cursor-pointer"
                    onClick={() => setIsPasswordShown(!isPasswordShown)}
                  >
                    {isPasswordShown ? <EyeOffLineIcon /> : <EyeLineIcon />}
                  </InputGroup.Text>
                </InputGroup>

                <Form.Label htmlFor="resetPasswordConfirmation">
                  {t('Password confirmation')}
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="resetPasswordConfirmation"
                    name="passwordConfirmation"
                    type={isPasswordConfirmationShown ? 'text' : 'password'}
                    required={true}
                  />
                  <InputGroup.Text
                    className="cursor-pointer"
                    onClick={() =>
                      setIsPasswordConfirmationShown(
                        !isPasswordConfirmationShown
                      )
                    }
                  >
                    {isPasswordConfirmationShown ? (
                      <EyeOffLineIcon />
                    ) : (
                      <EyeLineIcon />
                    )}
                  </InputGroup.Text>
                </InputGroup>

                <Form.Group>
                  <Button type="submit" color="primary" className="mt-3 w-100">
                    {t('Submit')}
                  </Button>
                </Form.Group>
              </Form>
            </Flex>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
