import { Card, Tab, Tabs } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import Flex from 'components/common/Flex';
import PageAnalysisDetailsAccessibility from '../page-analysis/details/PageAnalysisDetailsAccessibility';
import PageAnalysisDetailsContent from '../page-analysis/details/PageAnalysisDetailsContent';
import PageAnalysisDetailsOverview from '../page-analysis/details/PageAnalysisDetailsOverview';
import PageAnalysisDetailsPerformance from '../page-analysis/details/PageAnalysisDetailsPerformance';
import PageAnalysisDetailsSeo from '../page-analysis/details/PageAnalysisDetailsSeo';
import PageAnalysisDetailsTechnique from '../page-analysis/details/PageAnalysisDetailsTechnique';
import { pageAnalysisData } from 'data/anopseo/page-analysis';
import { useParams } from 'react-router-dom';

const SiteAnalysisPage = () => {
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const [data, setData] = useState({});

  const fetchPage = useCallback(async () => {
    const pagesResponse = await fetch(
      `${process.env.REACT_APP_API_URL}analyzes/sites/${params.id}/page/${params.analysisId}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await pagesResponse.json();
    setData(json);
  }, []);

  const overviewData = pageAnalysisData.overview;
  const seoData = pageAnalysisData.seo;
  const contentData = pageAnalysisData.content;
  const technicData = pageAnalysisData.technic;
  const performanceData = pageAnalysisData.performance;
  const accessibilityData = pageAnalysisData.accessibility;

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  return (
    <>
      <a className="d-block mb-3" href={`/site-analysis/${params.id}`}>
        <Flex className="gap-2" alignItems={'center'}>
          <ArrowLeftLineIcon />
          Retour
        </Flex>
      </a>
      <Card className="p-3">
        <Tabs variant="pills" defaultActiveKey="overview">
          <Tab eventKey="overview" title="VUE D'ENSEMBLE">
            <PageAnalysisDetailsOverview
              title={overviewData.title}
              description={overviewData.description}
              data={data?.data}
            />
          </Tab>
          <Tab eventKey="seo" title="SEO">
            <PageAnalysisDetailsSeo
              title={seoData.title}
              description={seoData.description}
              data={data?.data}
            />
          </Tab>
          <Tab eventKey="content" title="CONTENU">
            <PageAnalysisDetailsContent
              title={contentData.title}
              description={contentData.description}
              data={data?.data}
            />
          </Tab>
          <Tab eventKey="technic" title="TECHNIQUE">
            <PageAnalysisDetailsTechnique
              title={technicData.title}
              description={technicData.description}
              data={data?.data}
            />
          </Tab>
          <Tab eventKey="performance" title="PERFORMANCE">
            <PageAnalysisDetailsPerformance
              title={performanceData.title}
              description={performanceData.description}
              data={data?.data}
            />
          </Tab>
          <Tab eventKey="accessibility" title="ACCESSIBILITÉ">
            <PageAnalysisDetailsAccessibility
              title={accessibilityData.title}
              description={accessibilityData.description}
              data={data?.data}
            />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};

export default SiteAnalysisPage;
